import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import WaypointTrigger from 'components/Animation/WaypointTrigger';
import FadeUp from 'components/Animation/FadeUp';

import media from 'src/styles/media';
import { vwTablet } from 'src/styles/utils';

const H = ({ src }) => (
  <WaypointTrigger>
    {animate => (
      <FadeUp animate={animate}>
        <Root>
          <iframe
            width="100%"
            height="100%"
            src={src}
            frameBorder="0"
            allowFullScreen
            allow="vr"
            title="3D Tour"
          />
        </Root>
      </FadeUp>
    )}
  </WaypointTrigger>
);

H.propTypes = {
  src: PropTypes.string,
};

export default H;

const Root = styled.div`
  height: ${vwTablet(512)};
  @media (min-width: ${media.tablet}) {
    height: 100vh;
  }
`;
