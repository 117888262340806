import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const CTA = props => {
  const { color, menu, fixed } = props;
  return (
    <Root
      color={color}
      href="mailto:info@bamdigital.com"
      menu={menu}
      fixed={fixed}
    >
      Let's Talk
    </Root>
  );
};

CTA.propTypes = {
  color: PropTypes.string,
  menu: PropTypes.bool,
  fixed: PropTypes.bool,
};

export default CTA;

const Root = styled.a`
  padding: ${vw(8)} ${vw(24)};
  background-color: ${props => props.color};
  border-radius: ${vw(40)};
  color: white;
  font-family: ${({ theme }) => theme.font.main};
  font-size: ${vw(14)};
  font-weight: 500;
  line-height: 1.29;
  letter-spacing: ${vw(0.5)};
  display: ${props => (props.menu ? 'none' : 'block')};
  position: ${props => (props.fixed ? 'fixed' : 'relative')};
  top: ${props => (props.fixed ? vw(18) : 'auto')};
  right: ${props => (props.fixed ? vw(16) : 'auto')};
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(8)} ${vwTablet(32)};
    border-radius: ${vwTablet(40)};
    font-size: ${vwTablet(16)};
    line-height: 1.5;
    letter-spacing: ${vwTablet(1)};
    display: block;
    top: ${props => (props.menu ? vwTablet(40) : vwTablet(45))};
    right: ${props => {
      if (props.menu && props.fixed) {
        return vwTablet(94);
      } else if (props.fixed) {
        return vwTablet(30);
      } else {
        return 'auto';
      }
    }};
  }
  @media (min-width: ${media.desktop}) {
    padding: ${vwDesktop(8)} ${vwDesktop(32)};
    border-radius: ${vwDesktop(40)};
    font-size: ${vwDesktop(16)};
    line-height: 1.5;
    letter-spacing: ${vwDesktop(1)};
    top: ${vwDesktop(40)};
    right: ${vwDesktop(80)};
    transition: background-color 0.4s ease;
    &:hover {
      background-color: ${props => {
        switch (props.color) {
          case '#f04868':
            return props.theme.color.redHover;
          case '#f28900':
            return props.theme.color.orangeHover;
          case '#7578ff':
            return props.theme.color.purpleHover;
          case '#09bc8a':
            return props.theme.color.greenHover;
          default:
            return props.theme.color.redHover;
        }
      }};
    }
  }
`;
