import { createGlobalStyle } from 'styled-components';
import theme from './theme';
import { vw, vwTablet, vwDesktop } from './utils';
import media from './media';

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html,
  body,
  #app {
    width: 100%;
    height: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-stroke: 0;
    
  }
  
  body {
    font-family: ${theme.font.main};
  }

  a,
  button {
    outline: none;
    border: none;
    background: transparent;
    text-decoration: none;
    color: ${theme.color.primary};
  }

  main {
    text-align: center;
    position: absolute;
    top: 120px;
    left: 0;
    width: 100%;
    height: 100%;
  }

/* CUT OUT TEXT ELEMENTS */
  .display-outline{
    opacity: 0.6;
    -webkit-text-stroke: 1px #f04868;
    font-family:${theme.font.main};
    font-size: ${vw(44)};
    font-weight: bold;
    line-height: 1.09;
    letter-spacing: ${vw(2.2)};
    color: rgba(77, 77, 77, 0);
    text-transform: uppercase;
    @media(min-width:${media.tablet}){
      font-size: ${vwTablet(80)};
      line-height: 0.75;
      letter-spacing: ${vwTablet(4)};
    }
    @media(min-width:${media.desktop}){
      font-size: ${vwDesktop(80)};
      letter-spacing: ${vwDesktop(4)};
    }
  }

  h1{
    font-family: ${theme.font.main};
    font-size: ${vw(28)};
    font-weight: bold;
    line-height: 1.21;
    letter-spacing: ${vw(0.35)};
    @media(min-width:${media.tablet}){
      font-size: ${vwTablet(60)};
      line-height: 1.17;
      letter-spacing: ${vwTablet(0.75)};
    }
    @media(min-width:${media.desktop}){
      font-size: ${vwDesktop(60)};
      letter-spacing: ${vwDesktop(0.75)};

    }
  }

/* TODO: ADD TO H1 on HOME PAGE ? Styling only for mobile-size  */
  .mobile-hero{
    font-size: ${vw(26)};
    line-height: 1.23;
    letter-spacing: ${vw(0.33)};
    @media (min-width: ${media.tablet}) {
      font-size: ${vwTablet(60)};
      line-height: 1.17;
      letter-spacing: ${vwTablet(0.75)};
    }
    @media (min-width: ${media.desktop}) {
      font-size: ${vwDesktop(60)};
      line-height: 1.17;
      letter-spacing: ${vwDesktop(0.75)};
    }
  }

  .closeMobile{
   
    position: absolute;
    top: 10px;
    right:15px;
    width: auto;
    padding: 5px 10px;
    @media (min-width: ${media.tablet}) {
     opacity:0;
    }
    @media (min-width: ${media.desktop}) {
      opacity:0;
    }
  }

  h2{
    font-family: ${theme.font.main};
    font-size: ${vw(22)};
    font-weight: bold;
    line-height: 1.27;
    letter-spacing: ${vw(0.28)};
    @media(min-width:${media.tablet}){
      font-size: ${vwTablet(40)};
      line-height: 1.25;
      letter-spacing: ${vwTablet(0.5)};
    }
    @media(min-width:${media.desktop}){
      font-size: ${vwDesktop(40)};
      letter-spacing: ${vwDesktop(0.5)};
    }
  }

/* FOR PROJECT-LABEL */
  h3{
    font-family: ${theme.font.main};
    font-size: ${vw(25)};
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: ${vw(0.25)};
    @media(min-width:${media.tablet}){
      font-size: ${vwTablet(32)};
      line-height: 1.19;
      letter-spacing: ${vwTablet(0.32)};
    }
    @media(min-width:${media.desktop}){
      font-size: ${vwDesktop(50)};
      line-height: 1.2;
      letter-spacing: ${vwDesktop(0.5)};

    }
  }

  h4{
    font-family:${theme.font.main};
    font-size:${vw(18)};
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: ${vw(0.82)};
    @media(min-width:${media.tablet}){
      font-size:${vwTablet(22)};
      line-height: 1.09;
      letter-spacing:${vwTablet(1)};
    }
    @media(min-width:${media.desktop}){
      font-size:${vwDesktop(22)};
      letter-spacing:${vwDesktop(1)};
    }
  }

/* FOR SUBHEADER */
  h6{
    font-family: ${theme.font.main};
    font-size: ${vw(11)};
    font-weight: 500;
    line-height: 1.27;
    letter-spacing: ${vw(2)};
    text-transform: uppercase;
    @media(min-width:${media.tablet}){
      font-size: ${vwTablet(14)};
      line-height: 1.43;
      letter-spacing:${vwTablet(2)};
    }
    @media(min-width:${media.desktop}){
      font-size: ${vwDesktop(14)};
      letter-spacing:${vwDesktop(2)};
    }
  }

/* FOR BODY1 */
  p{
    font-family: ${theme.font.main};
    font-size: ${vw(14)};
    font-weight: 300;
    line-height: ${vw(22)};
    letter-spacing:${vw(0.5)};
    @media(min-width:${media.tablet}){
      font-size: ${vwTablet(18)};
      line-height: 1.67;
      letter-spacing: ${vwTablet(0.5)};
    }
    @media(min-width:${media.desktop}){
      font-size: ${vwDesktop(18)};
      letter-spacing: ${vwDesktop(0.5)};
    }
  }

  .body2{
    font-size: ${vw(12)};
    line-height: 1.5;
    letter-spacing: ${vw(0.43)};
    @media(min-width:${media.tablet}){
      font-size: ${vwTablet(16)};
      line-height: 1.5;
      letter-spacing: ${vwTablet(0.5)};
    }
    @media(min-width:${media.desktop}){
      font-size: ${vwDesktop(16)};
      letter-spacing: ${vwDesktop(0.5)};
    }
  }

  .bold{
    font-weight: 500;
    @media(min-width:${media.tablet}){
      letter-spacing: ${vwTablet(1)};
    }
    @media(min-width:${media.desktop}){
      letter-spacing: ${vwDesktop(1)}
    }
  }

/* TODO: line-height on body1 vs body 2 on responsive footer elements */
  .bold-body1{
    line-height: 1.29;
    @media (min-width: ${media.tablet}) {
      line-height: 1.5;
    }
  }

  .tablet-only{
    display: none;
  }

  .tablet-desktop-only{
    display:none;
  }

  .desktop-only{
    display:none;
  }
@media(min-width:${media.tablet}){
  .mobile-only{
      display:none;
    }
    .tablet-desktop-only{
      display:block;
    }

    .tablet-only{
    display:block;
  }
  }

  @media(min-width:${media.desktop}){
    .tablet-only{
      display: none;
    }
    .desktop-only{
      display: block;
    }
  }




  .footnote{
    font-size: ${vw(11)};
    font-weight: 500;
    line-height: 1.64;
    letter-spacing: normal;
    @media(min-width:${media.tablet}){
      font-size: ${vwTablet(11)};
    }
    @media(min-width:${media.desktop}){
      font-size: ${vwDesktop(11)};
    }
  }

  @font-face {
    font-family: 'HelveticaNeue';
    src: url('/assets/fonts/HelveticaNeue-Light.woff2') format('woff2'),
        url('/assets/fonts/HelveticaNeue-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('/assets/fonts/HelveticaNeue-Regular.woff2') format('woff2'),
        url('/assets/fonts/HelveticaNeue-Regular.woff') format('woff');
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('/assets/fonts/HelveticaNeue-Bold.woff2') format('woff2'),
        url('/assets/fonts/HelveticaNeue-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('/assets/fonts/HelveticaNeue-Medium.woff2') format('woff2'),
        url('/assets/fonts/HelveticaNeue-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
`;
