import React from 'react';
import styled from 'styled-components';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const CloseButton = props => {
  const { handleModal } = props;
  return (
    <Root
      src={require('src/assets/images/icons/close-black.svg')}
      alt="Close Button"
      onClick={() => handleModal(false)}
    ></Root>
  );
};

export default CloseButton;

const Root = styled.img`
  position: absolute;
  top: ${vw(16)};
  right: ${vw(16)};
  height: ${vw(20)};
  width: ${vw(20)};
  object-fit: contain;
  z-index: 99;
  @media (min-width: ${media.tablet}) {
    top: ${vwTablet(30)};
    right: ${vwTablet(30)};
    height: ${vwTablet(24)};
    width: ${vwTablet(24)};
  }
  @media (min-width: ${media.desktop}) {
    top: ${vwDesktop(40)};
    right: ${vwDesktop(80)};
    height: ${vwDesktop(24)};
    width: ${vwDesktop(24)};
    cursor: pointer;
  }
`;
