import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { transitionOut } from 'src/redux/project';
import { enableScroll } from 'src/redux/app';
import projects from 'src/data/projects';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Navigation from 'components/Panels/Navigation';

class Single extends Component {
  state = {
    project: null,
  };

  componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;

    const project = projects.find((project) => project.id === id);
    this.setState({ project });
    setTimeout(() => {
      this.props.enableScroll();
      this.props.transitionOut();
    }, 500);
  }

  render() {
    const { project } = this.state;
    const history = this.props.location.state;
    return (
      <Root>
        <Header />
        {project && project.component}
        <Navigation history={history} />
        <Footer />
      </Root>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      transitionOut,
      enableScroll,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Single));

const Root = styled.div``;
