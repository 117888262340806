import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Container from 'components/Container';
import UnderlineText from 'components/Typography/UnderlineText';
import Form from './elements/Form';
import Social from './elements/Social';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';
import theme from 'src/styles/theme';

const Footer = props => {
  const { color } = props;
  return (
    <Root color={color}>
      <Container>
        <h6>Contact</h6>
        <h1>Get in touch.</h1>
        <Wrapper>
          <Flex>
            <div>
              <p className="body1 bold">Head Office:</p>
              <p className="grey body2">
                2060 Pine Street
                <br />
                Vancouver, British Columbia
                <br />
                V6J 4P8 Canada
              </p>
            </div>
            <div>
              <Phone>
                <p className="body1 bold">Vancouver:</p>
                <p className="body1 margin grey">
                  <a href="tel:604-729-5787">+1 (604) 729-5787</a>
                </p>
              </Phone>
              <Phone margin>
                <p className="body1 bold">Toronto:</p>
                <p className="body1 margin grey">
                  <a href="tel:604-632-2772">+1 (647) 632-2772</a>
                </p>
              </Phone>
              <UnderlineText
                color={color}
                text="info@bamdigital.com"
                href="mailto:info@bamdigital.com"
              />
            </div>
          </Flex>
          <h2> </h2>
          {/* <h2>Join our email newsletter for updates.</h2> */}
          {/* <Form color={color} /> */}
        </Wrapper>
        <Social />
      </Container>
    </Root>
  );
};

Footer.propTypes = {
  color: PropTypes.string,
};

Footer.defaultProps = {
  color: theme.color.red,
};

export default Footer;

const Root = styled.div`
  background-color: black;
  padding: ${vw(80)} 0 ${vw(40)};
  color: white;
  h6 {
    text-transform: uppercase;
    color: ${props => props.color};
  }
  h1 {
    padding: ${vw(10)} 0 ${vw(40)};
  }
  h2 {
    font-size: ${vw(14)};
    font-weight: 500;
    line-height: 1.29;
    letter-spacing: ${vw(0.5)};
    margin: ${vw(80)} 0 ${vw(20)};
  }
  .grey {
    color: ${({ theme }) => theme.color.grey3};
    margin: ${vw(4)} 0 ${vw(40)};
  }
  a {
    color: white;
  }
  .margin {
    font-size: ${vw(14)};
    margin: 0;
  }
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(160)} 0 ${vwTablet(80)};
    h1 {
      padding: ${vwTablet(20)} 0 ${vwTablet(40)};
    }
    h2 {
      max-width: ${vwTablet(427)};
      font-size: ${vwTablet(18)};
      font-weight: bold;
      line-height: 1.67;
      letter-spacing: ${vwTablet(0.5)};
      margin: ${vwTablet(160)} 0 ${vwTablet(40)};
    }
    .grey {
      margin-top: ${vwTablet(8)};
      margin-bottom: 0;
    }
    .body2 {
      margin-right: ${vwTablet(62)};
    }
    .margin {
      margin: 0;
      font-size: ${vwTablet(16)};
    }
    .bold {
      font-size: ${vwTablet(16)};
      line-height: 1.5;
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: ${vwDesktop(160)} 0 ${vwDesktop(40)};
    h1 {
      padding: ${vwDesktop(20)} 0 0;
    }
    h2 {
      max-width: ${vwDesktop(427)};
      font-size: ${vwDesktop(18)};
      line-height: 1.67;
      letter-spacing: ${vwDesktop(0.5)};
      margin: ${vwDesktop(160)} 0 ${vwDesktop(40)};
    }
    .grey {
      margin-bottom: 0;
      margin-right: ${vwDesktop(62)};
      margin-top: ${vwDesktop(8)};
    }
    .margin {
      margin: 0;
      font-size: ${vwDesktop(16)};
    }
    .bold {
      font-size: ${vwDesktop(16)};
    }
  }
`;

const Wrapper = styled.div`
  margin-left: ${vw(24)};
  @media (min-width: ${media.tablet}) {
    margin-left: ${vwTablet(122)};
  }
  @media (min-width: ${media.desktop}) {
    margin-left: ${vwDesktop(545)};
  }
`;

const Flex = styled.div`
  @media (min-width: ${media.tablet}) {
    display: flex;
  }
`;

const Phone = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${props => (props.margin ? vw(20) : vw(4))};
  width: ${vw(212)};
  @media (min-width: ${media.tablet}) {
    margin-bottom: ${props => (props.margin ? vwTablet(18) : 0)};
    width: ${vwTablet(243)};
  }
  @media (min-width: ${media.desktop}) {
    margin-bottom: ${props => (props.margin ? vwDesktop(18) : 0)};
    width: ${vwDesktop(243)};
  }
`;
