import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

class ProjectHero extends Component {
  state = {
    scale: 0,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = e =>
    this.setState({ scale: e.srcElement.scrollingElement.scrollTop });

  render() {
    const { image, title, right, veryRight } = this.props;
    return (
      <Root>
        <Image
          src={image}
          alt={title}
          transform={`scale(${1.1 - this.state.scale * 0.0001})`}
          right={right}
          veryRight={veryRight}
        />
        <h1>{title}</h1>
      </Root>
    );
  }
}

ProjectHero.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  right: PropTypes.bool,
};

export default ProjectHero;

const Root = styled.div`
  height: 100vh;
  width: 100%;
  padding: ${vw(13)} ${vw(13)} ${vw(120)};
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  position: relative;
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(110)} ${vwTablet(30)};
  }
  @media (min-width: ${media.desktop}) {
    padding: ${vwDesktop(80)};
  }
  h1 {
    font-size: ${vw(30)};
    font-weight: bold;
    line-height: ${vw(30)};
    letter-spacing: ${vw(0.5)};
    text-transform: uppercase;
    color: white;
    max-width: ${vw(200)};
    position: relative;
    @media (min-width: ${media.tablet}) {
      font-size: ${vwTablet(56)};
      line-height: ${vwTablet(60)};
      letter-spacing: ${vwTablet(0)};
      max-width: ${vwTablet(350)};
    }
    @media (min-width: ${media.desktop}) {
      font-size: ${vwDesktop(50)};
      line-height: ${vwDesktop(60)};
      letter-spacing: ${vwDesktop(0.5)};
      max-width: ${vwDesktop(450)};
    }
    @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      font-size: ${vwDesktop(70)};
      line-height: ${vwDesktop(75)};
      letter-spacing: ${vwDesktop(0)};
      max-width: ${vwDesktop(350)};
    }
  }
`;

const Image = styled.img.attrs(props => ({
  style: { transform: props.transform },
}))`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: ${props => {
    if (props.right) {
      return '75%';
    } else if (props.veryRight) {
      return '80%';
    } else {
      return 'center';
    }
  }};
  position: absolute;
  top: 0;
  left: 0;
  @media (min-width: ${media.desktop}) {
    object-position: center;
  }
`;
