import React, { useState } from 'react';
import styled from 'styled-components';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Hero from './sections/Hero';
import Intro from './sections/Intro';
import Features from './sections/Features';
import CaseStudies from './sections/CaseStudies';
import Modal from './elements/Modal/Modal';

function PageTemplate(props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const $slider = React.createRef();

  const {
    hero,
    intro,
    features,
    featureColor,
    caseStudies,
    clients,
    webSolutions,
    pageTitle,
    data,
    tagline,
    longerTagline,
  } = props;

  const handleModal = index => {
    document.body.style.overflow = 'hidden';
    $slider.current.slickGoTo(index, true);
    setModalOpen(true);
  };

  const handleModalWithoutCarousel = index => {
    setModalOpen(true);
    setActiveIndex(index);
  };

  const closeModal = () => {
    document.body.style.overflow = 'auto';
    setModalOpen(false);
  };

  return (
    <Root>
      <Header color={featureColor} />
      <Hero
        title={hero.title}
        body={hero.body}
        featureColor={featureColor}
        imageBack={hero.imageBack}
        imageFront={hero.imageFront}
        imagePosition={hero.imagePosition}
        imageSize={hero.imageSize}
        backgroundImage={hero.backgroundImage}
        short={hero.short}
      />
      <Intro
        subheader={intro.subheader}
        title={intro.title}
        body={intro.body}
        featureColor={featureColor}
        buttonLabel={intro.buttonLabel}
        largeText={intro.largeText}
      />
      {features && (
        <Features
          features={features}
          featureColor={featureColor}
          handleModal={handleModal}
          tagline={tagline}
          longerTagline={longerTagline}
        />
      )}
      {webSolutions && (
        <Features
          data={data}
          webSolutions={webSolutions}
          featureColor={featureColor}
          handleModal={handleModalWithoutCarousel}
          tagline={tagline}
          longerTagline={longerTagline}
        />
      )}
      <CaseStudies
        caseStudies={caseStudies.caseStudies}
        subheader={caseStudies.subheader}
        title={caseStudies.title}
        body={caseStudies.body}
        featureColor={featureColor}
        featureImage={caseStudies.featureImage}
        clients={clients}
        imageSize={caseStudies.imageSize}
        buttonLink={caseStudies.buttonLink}
        pageTitle={pageTitle}
        buttonLabel={caseStudies.buttonLabel}
        subject={caseStudies.subject}
      />
      <Footer color={featureColor} />

      <Modal
        isOpen={modalOpen}
        handleModal={closeModal}
        content={features ? features : webSolutions}
        carousel={features ? true : false}
        carouselRef={$slider}
        featureColor={featureColor}
        activeIndex={activeIndex}
        pageTitle={pageTitle}
      />
    </Root>
  );
}

export default PageTemplate;

const Root = styled.div``;
