import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Container from 'components/Container';
import UnderlineText from 'components/Typography/UnderlineText';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const WebSolutionCard = props => {
  const { featureColor, webSolution } = props;
  const {
    title,
    body,
    thumbnail,
    path,
    caseStudies,
    noMobileWrap,
  } = webSolution;

  return (
    <Root as={path && Link} to={path && path} target={path && '_blank'}>
      <ImageWrapper>
        <img src={thumbnail} alt={title} />
      </ImageWrapper>

      <TextWrapper featureColor={featureColor}>
        <h2>{title}</h2>
        <p className="body">{body}</p>
        {path && (
          <UnderlineText
            as="div"
            handleClick={f => f}
            className="bold"
            text="Find Out More"
            color={featureColor}
            black
          />
        )}
        {caseStudies && (
          <>
            <h6>Case Studies</h6>
            <Studies noMobileWrap={noMobileWrap}>
              {caseStudies.map((item, index) => (
                <UnderlineText
                  to={item.path}
                  className="bold"
                  text={item.title}
                  color={featureColor}
                  black
                  key={index}
                />
              ))}
            </Studies>
          </>
        )}
      </TextWrapper>
    </Root>
  );
};

export default WebSolutionCard;

const Root = styled.div`
  display: block;
  color: #000000;
  &:not(:first-child) {
    margin-top: ${vw(47)};
  }
  @media (min-width: ${media.tablet}) {
    &:not(:first-child) {
      margin-top: ${vwTablet(80)};
    }
  }
  @media (min-width: ${media.desktop}) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    &:nth-child(even) {
      flex-direction: row-reverse;
    }
    &:not(:first-child) {
      margin-top: ${vwDesktop(80)};
    }
  }
`;

const ImageWrapper = styled.div`
  height: ${vw(170)};
  width: ${vw(170)};
  position: relative;
  margin: 0 ${vw(16)} ${vw(10)};
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  @media (min-width: ${media.tablet}) {
    height: ${vwTablet(400)};
    width: ${vwTablet(400)};
    margin: 0 0 ${vwTablet(20)};
    &::after {
      height: ${vwTablet(120)};
    }
  }
  @media (min-width: ${media.desktop}) {
    height: ${vwDesktop(400)};
    width: ${vwDesktop(400)};
    margin: 0;
    &::after {
      content: unset;
    }
  }
`;

const TextWrapper = styled(Container)`
  /* margin-top: ${vw(-28)}; */
  position: relative;
  z-index: 2;
  h2 {
    margin-bottom: ${vw(10)};
  }
  .body {
    margin-bottom: ${vw(20)};
  }
  h6 {
    color: ${({ theme }) => theme.color.grey2};
    margin-bottom: ${vw(10)};
  }
  @media (min-width: ${media.tablet}) {
    padding-left: 0;
    padding-right: unset;
    /* margin-top: ${vwTablet(-60)}; */
    h2 {
      margin-bottom: ${vwTablet(20)};
    }
    .body {
      margin-bottom: ${vwTablet(20)};
    }
    h6 {
      margin-bottom: ${vwTablet(10)};
    }
  }
  @media (min-width: ${media.desktop}) {
    margin-top: unset;
    width: ${vwDesktop(452)};
    padding-left: unset;
    h2 {
      margin-bottom: ${vwDesktop(20)};
    }
    .body {
      margin-bottom: ${vwDesktop(20)};
    }
    h6 {
      margin-bottom: ${vwDesktop(10)};
    }
  }
`;

const Studies = styled.div`
  display: flex;
  flex-direction: ${props => (props.noMobileWrap ? 'column' : 'row')};
  > div {
    margin-right: ${props => (props.noMobileWrap ? 0 : vw(20))};
    margin-bottom: ${props => (props.noMobileWrap ? vw(10) : 0)};
  }
  @media (min-width: ${media.tablet}) {
    flex-direction: row;
    > div {
      margin-right: ${vwTablet(40)};
      margin-bottom: 0;
    }
  }
  @media (min-width: ${media.desktop}) {
    > div {
      margin-right: ${vwDesktop(40)};
    }
  }
`;
