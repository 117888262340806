export default [
  [
    require('src/assets/images/logos/clients/concord-pacific.png'),
    require('src/assets/images/logos/clients/colliers-international.png'),
    require('src/assets/images/logos/clients/anthem-properties.png'),
    require('src/assets/images/logos/clients/beedie-living.png'),
    require('src/assets/images/logos/clients/onni-group.png'),
    require('src/assets/images/logos/clients/laconia-development.png'),
  ],
  [
    require('src/assets/images/logos/clients/polygon.png'),
    require('src/assets/images/logos/clients/gryphon-development.png'),
    require('src/assets/images/logos/clients/aoyuan.png'),
    require('src/assets/images/logos/clients/aquilini-development.png'),
    require('src/assets/images/logos/clients/realogics-sothebys.png'),
    require('src/assets/images/logos/clients/townline.png'),
  ],
];
