export default {
  color: {
    primary: '#00d8ff',
    black: '#000000',
    grey4: '#f0f2f2',
    grey1: '#4d4d4d',
    grey2: '#7b7c7f',
    grey3: '#cccfd1',
    offWhite: '#fafafa',
    red: '#f04868',
    redHover: '#d6405d',
    orange: '#f28900',
    orangeHover: '#e08104',
    purple: '#7578ff',
    purpleHover: '#6769e0',
    green: '#09bc8a',
    greenHover: '#089e74',
    white: '#ffffff',
  },
  font: {
    main: 'HelveticaNeue',
  },
};
