import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Button = props => {
  const { color, mailLink, label, subject } = props;

  const handleMailLink = () => {
    if (mailLink && subject) {
      return `mailto:info@bamdigital.com?&subject=${subject}`;
    } else if (mailLink) {
      return 'mailto:info@bamdigital.com';
    }
  };

  return (
    <Root color={color} href={handleMailLink()} {...props}>
      {label}
    </Root>
  );
};

export default Button;

Button.propTypes = {
  color: PropTypes.string,
  mailLink: PropTypes.bool,
  label: PropTypes.string,
  subject: PropTypes.string,
};

Button.defaultProps = {
  color: props => props.theme.color.red,
  mailLink: false,
  label: "Let's Talk",
};

const Root = styled.a`
  padding: ${vw(8)} ${vw(24)};
  border-radius: ${vw(40)};
  background-color: ${props => props.color};
  font-family: ${({ theme }) => theme.font.main};
  display: ${props => (props.menu ? 'none' : 'block')};
  height: fit-content;
  width: fit-content;
  color: white;
  font-family: ${({ theme }) => theme.font.main};
  font-weight: 500;
  font-size: ${vw(14)};
  line-height: 1.29;
  letter-spacing: ${vw(0.5)};
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(8)} ${vwTablet(32)};
    border-radius: ${vwTablet(40)};
    font-size: ${vwTablet(16)};
    line-height: 1.5;
    letter-spacing: ${vwTablet(1)};
  }
  @media (min-width: ${media.desktop}) {
    padding: ${vwDesktop(8)} ${vwDesktop(32)};
    border-radius: ${vwDesktop(40)};
    transition: background-color 0.4s ease;
    font-size: ${vwDesktop(16)};
    line-height: 1.5;
    letter-spacing: ${vwDesktop(1)};
    cursor: pointer;
    &:hover {
      background-color: ${props => {
        switch (props.color) {
          case '#f04868':
            return props.theme.color.redHover;
          case '#f28900':
            return props.theme.color.orangeHover;
          case '#7578ff':
            return props.theme.color.purpleHover;
          case '#09bc8a':
            return props.theme.color.greenHover;
          default:
            return props.theme.color.redHover;
        }
      }};
    }
  }
`;
