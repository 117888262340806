import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';

import WaypointTrigger from 'components/Animation/WaypointTrigger';
import FadeUp from 'components/Animation/FadeUp';
import Gallery from './elements/ClickGallery';
// import CustomCursor from 'components/CustomCursor';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import media from 'src/styles/media';
import { vwTablet, vw } from 'src/styles/utils';

class A extends Component {
  constructor(props) {
    super(props);
    this.$gallery = React.createRef();
  }

  state = {
    hasClicked: false,
  };

  handleNext = () => {
    this.setState({ hasClicked: true });
    if (this.props.mediaType === 'gallery') {
      this.$gallery.current.slickNext();
    }
  };

  handleLeaveWaypoint = () => this.setState({ hasClicked: false });

  render() {
    const { mediaType, media } = this.props;
    return (
      <Waypoint onLeave={this.handleLeaveWaypoint}>
        <div>
          <WaypointTrigger>
            {animate => (
              <FadeUp animate={animate}>
                <Root
                  media={media}
                  mediaType={mediaType}
                  backgroundImage={mediaType === 'image'}
                  onClick={this.handleNext}
                >
                  {mediaType === 'video' && (
                    <>
                      <video
                        src={media}
                        muted
                        autoPlay
                        loop
                        className="desktop-video"
                      />
                      <video
                        src={media}
                        controls
                        muted
                        loop
                        className="mobile-video"
                        playsInline
                      />
                    </>
                  )}
                  {mediaType === 'gallery' && (
                    // <CustomCursor gallery>
                    <Gallery
                      media={media}
                      galleryRef={this.$gallery}
                      number={1}
                    />
                    // </CustomCursor>
                  )}
                  {mediaType === 'gallery' && (
                    <Icon
                      src={require('src/assets/images/cursors/cursor_gallery.svg')}
                      active={true}
                    />
                  )}
                </Root>
              </FadeUp>
            )}
          </WaypointTrigger>
        </div>
      </Waypoint>
    );
  }
}

A.propTypes = {
  mediaType: PropTypes.string,
  media: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default A;

const Root = styled.div`
  width: 100%;
  height: ${vwTablet(512)};
  background-image: ${props =>
    props.backgroundImage ? `url(${props.media})` : ''};
  background-size: cover;
  background-position: center;
  overflow: hidden;
  position: relative;
  /* cursor: ${props =>
    props.mediaType === 'gallery'
      ? 'url(/assets/images/click_icon.svg) 20 20, auto'
      : 'inherit'}; */
  @media (min-width: ${media.desktop}) {
    height: 100vh;
  }
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    height: 50vh;
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  .mobile-video {
    background-color: black;
    @media (min-width: ${media.desktop}) {
      display: none;
    }
  }
  .desktop-video {
    display: none;
    @media (min-width: ${media.desktop}) {
      display: block;
    }
  }
`;

const Icon = styled.img`
  position: absolute;
  bottom: ${vwTablet(30)};
  left: 50%;
  transform: translateX(-50%);
  opacity: ${props => (props.active ? 1 : 0)};
  transition: 0.5s ease;
  width: ${vw(30)};
  height: ${vw(30)};
  @media (min-width: ${media.tablet}) {
    width: ${vwTablet(40)};
    height: ${vwTablet(40)};
  }
  @media (min-width: ${media.desktop}) {
    display: none;
  }
`;
