import React from 'react';
import styled from 'styled-components';

import Header from 'components/Header/Header';
import Hero from './sections/Hero';
import Mission from './sections/Mission';
import Services from './sections/Services';
import Approach from './sections/Approach';
import Work from './sections/Work';
import Clients from './sections/Clients';
import Footer from 'components/Footer/Footer';


import theme from 'src/styles/theme';

const Home = () => {
  return (
    <Root>
      
      <Header menu />
      <Hero />
      <Mission />
      <Services />
      <Approach />
      <Work />
      <Clients />
      <Footer color={theme.color.red} />
    </Root>
  );
};

export default Home;

const Root = styled.div``;
