import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import Container from 'components/Container';
import UnderlineText from 'components/Typography/UnderlineText';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const TextContainer = props => {
  const {
    title,
    body,
    caseStudies,
    featureColor,
    caseStudyMobile,
    navSpace,
    pageTitle,
  } = props;
  return (
    <Root className="text-container">
      <StyledContainer navSpace={navSpace}>
        <Title featureColor={featureColor} className="desktop-only">
          <h2>{title}</h2>
        </Title>
        {caseStudies && (
          <CaseStudies mobileOrientation={caseStudyMobile === 'row'}>
            <h5>Case Studies:</h5>

            {caseStudies.map((caseStudy, index) => (
              <UnderlineText
                text={caseStudy.title}
                // to={`work${caseStudy.path}`}
                handleClick={() =>
                  props.history.push(`work${caseStudy.path}`, {
                    from: pageTitle,
                    path: props.location.pathname,
                  })
                }
                key={index}
                black
                color={featureColor}
              />
            ))}
          </CaseStudies>
        )}
        <Content>
          <p>{body}</p>
        </Content>
      </StyledContainer>
    </Root>
  );
};

export default withRouter(TextContainer);

const Root = styled.div`
  @media (min-width: ${media.desktop}) {
    height: 100vh;
  }
`;

const StyledContainer = styled(Container)`
  height: calc(100vh - ${vw(320)});
  display: flex;
  flex-direction: column;
  padding-bottom: ${props => (props.navSpace ? vw(92) : vw(40))};
  overflow: hidden;
  @media (min-width: ${media.tablet}) {
    height: calc(100vh - ${vwTablet(560)} - ${vwTablet(152)});
    padding-left: ${vwTablet(152)};
    padding-bottom: ${vwTablet(80)};
  }
  @media (min-width: ${media.desktop}) {
    height: 100vh;
    padding-left: ${vwDesktop(76)};
    padding-bottom: ${vwDesktop(80)};
    padding-right: ${vwDesktop(190)};
    padding-top: ${vwDesktop(182)};
  }
`;

const Title = styled.div`
  @media (min-width: ${media.desktop}) {
    h2 {
      position: relative;
      margin-bottom: ${vwDesktop(40)};
    }
    h2::after {
      content: '';
      height: ${vwDesktop(2)};
      width: ${vwDesktop(40)};
      top: ${vwDesktop(-20)};
      left: 0%;
      position: absolute;
      background-color: ${props => props.featureColor};
    }
  }
`;

const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: ${vw(4)};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.color.grey4};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.color.grey2};
  }

  ul {
    list-style-type: none;
    padding-left: ${vw(26)};
    li p::before {
      content: '- ';
    }
  }

  @media (min-width: ${media.tablet}) {
    margin-top: 0;
    max-height: ${vwTablet(120)};
    margin-bottom: ${vwTablet(40)};
    padding-right: ${vwTablet(10)};
    ::-webkit-scrollbar {
      width: ${vwTablet(4)};
    }
    ul {
      padding-left: ${vwTablet(26)};
    }
  }
  @media (min-width: ${media.desktop}) {
    margin-bottom: ${vwDesktop(40)};
    min-height: ${vwDesktop(50)};
    max-height: ${vwDesktop(480)};
    ::-webkit-scrollbar {
      width: ${vwDesktop(4)};
    }
    ul {
      padding-left: ${vwDesktop(26)};
    }
  }
`;

const CaseStudies = styled.div`
  display: flex;
  flex-direction: ${props => (props.mobileOrientation ? 'row' : 'column')};
  flex-wrap: wrap;
  margin-bottom: ${vw(20)};
  h5 {
    font-family: ${({ theme }) => theme.font.main};
    font-size: ${vw(11)};
    font-weight: 500;
    line-height: 1.27;
    letter-spacing: ${vw(2)};
    color: #86878a;
    text-transform: uppercase;
    margin-bottom: ${vw(10)};
    width: 100%;
  }
  > div:not(:first-of-type) {
    margin-top: ${props => (!props.mobileOrientation ? vw(10) : 0)};
  }
  > div {
    margin-right: ${props => props.mobileOrientation && vw(40)};
  }
  @media (min-width: ${media.tablet}) {
    order: 3;
    flex-direction: row;
    > div:not(:first-of-type) {
      margin-top: unset;
    }
    > div {
      margin-right: ${vwTablet(40)};
    }
    h5 {
      font-size: ${vwTablet(14)};
      font-weight: 500;
      line-height: 1.43;
      letter-spacing: ${vwTablet(2)};
      margin-bottom: ${vwTablet(20)};
    }
  }
  @media (min-width: ${media.desktop}) {
    flex-direction: column;
    margin-bottom: 0;
    h5 {
      font-size: ${vwDesktop(14)};
      letter-spacing: ${vwDesktop(2)};
      margin-bottom: ${vwDesktop(20)};
    }
    > div:not(:first-of-type) {
      margin-top: ${vwDesktop(20)};
    }
  }
`;
