import React from 'react';

export default [
  {
    title: (
      <>
        New Development <br />
        Pre-Sales
      </>
    ),
    body: `Attract affluent home buyers with an enriched brand and storytelling experience. Create desire with powerful visuals and information your customers need to take action now.`,
    thumbnail: require('src/assets/images/webSolutions/new-development.jpg'),
    image: require('src/assets/images/webSolutions/new-development-mock.jpg'),
    content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie. Mauris malesuada nisi sit amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie. Mauris malesuada nisi sit amet.`,
    caseStudyMobile: 'row',
    caseStudies: [
      {
        title: 'Centra',
        path: '/work/centra',
      },
      {
        title: 'Spire',
        path: '/work/spire',
      },
      {
        title: 'The Holland',
        path: '/work/the-holland',
      },
    ],
  },
  {
    title: (
      <>
        Integrated
        <br /> Sales Apps
      </>
    ),
    body: `An integrated sales app that delivers an immersive customer experience and boosts sales. Our intuitive touchscreen and iPad designs and technology equip your team to connect and sell anytime, anywhere.`,
    thumbnail: require('src/assets/images/touchscreen/ipad-sales-app.jpg'),
    image: require('src/assets/images/touchscreen/ipad-sales-app.jpg'),
    path: '/salesapp',
  },
  {
    title: 'Rental Properties',
    body: `Stand out in the rental market with an optimized home-finding experience. Our custom-designed websites support the entire rental inquiry process and deliver a smooth, impactful experience.`,
    thumbnail: require('src/assets/images/webSolutions/rental.jpg'),
    image: require('src/assets/images/webSolutions/rental.jpg'),
    path: '/rentals',
  },
  {
    title: 'Corporate and Commercial Properties',
    body: `First impressions are important. Establish credibility for your brand and set your business up for success with modern, responsive web design and usability. Increase leads and see the difference first-hand.`,
    thumbnail: require('src/assets/images/webSolutions/corporate.jpg'),
    image: require('src/assets/images/webSolutions/corporate-mock.jpg'),
    content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie. Mauris malesuada nisi sit amet.`,
    noMobileWrap: true,
    caseStudies: [
      {
        title: 'Gryphon Development',
        path: '/work/gryphon-development',
      },
      {
        title: 'VPAC Construction',
        path: '/work/vpac-construction',
      },
    ],
  },
];
