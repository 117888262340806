import React from 'react';

import Centra from './Centra';
import GryphonDevelopment from './GryphonDevelopment';
import Holland from './Holland';
import Spire from './Spire';
import WaltonLofts from './WaltonLofts';
import VPAC from './VPAC';

export default [
  {
    id: 'walton-lofts',
    component: <WaltonLofts />,
    title: 'Walton Lofts',
    subtitle: 'Sophisticated living on the Seattle waterfront.',
    img: require('src/assets/images/projects/waltonLofts/walton_herobanner.jpg'),
    right: true,
    category: [],
  },
  {
    id: 'gryphon-development',
    component: <GryphonDevelopment />,
    title: 'Gryphon Development',
    subtitle: 'Art lives here.',
    img: require('src/assets/images/projects/gryphon/gryphon_herobanner2.jpg'),
    category: [],
  },
  {
    id: 'centra',
    component: <Centra />,
    title: 'Centra',
    subtitle: 'Urban energy, suburban serenity',
    img: require('src/assets/images/projects/centra/centra_herobanner.jpg'),
  },
  {
    id: 'spire',
    component: <Spire />,
    title: 'Spire',
    subtitle: 'Laconia LLC & Vanke Corporation',
    img: require('src/assets/images/projects/spire/spire_herobanner.jpg'),
    category: ['digital', '3d'],
  },
  {
    id: 'the-holland',
    component: <Holland />,
    title: 'The Holland',
    subtitle: 'Parkside living in Surrey City Centre.',
    img: require('src/assets/images/projects/holland/holland_herobanner.jpg'),
  },
  {
    id: 'vpac-construction',
    component: <VPAC />,
    title: 'VPAC Construction',
    subtitle: 'People, passion, progress, trust.',
    img: require('src/assets/images/projects/vpac/vpac_herobanner.jpg'),
  },
];
