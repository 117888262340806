import React from 'react';
import styled from 'styled-components';
import { Parallax } from 'react-scroll-parallax';
import { connect } from 'react-redux';
import { withController } from 'react-scroll-parallax';

import Container from 'components/Container';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

function Hero(props) {
  const {
    title,
    body,
    featureColor,
    imageFront,
    imageBack,
    imagePosition,
    imageSize,
    backgroundImage,
    short,
  } = props;

  const handleLoad = () => {
    // updates cached values after image dimensions have loaded
    props.parallaxController.update();
  };

  const calculateParallax = image => {
    if (props.browser.is.mobile) {
      if (image === 'front-image') {
        return ['0px', '20px'];
      } else if (image === 'back-image') {
        return ['0px', '30px'];
      }
    } else if (props.browser.is.tablet) {
      if (image === 'front-image') {
        return ['20px', '60px'];
      } else if (image === 'back-image') {
        return ['10px', '50px'];
      }
    } else {
      if (image === 'front-image') {
        return [`-50px`, `220px`];
      } else if (image === 'back-image') {
        return [`-50px`, `70px`];
      }
    }
  };

  const calculateSingleParallax = image => {
    if (props.browser.is.mobile) {
      return [`-10px`, `10px`];
    } else if (props.browser.is.tablet) {
      return [`-20px`, `20px`];
    } else {
      return [`-200px`, `0px`];
    }
  };

  return (
    <Root onLoad={handleLoad} short={short} backgroundImage={backgroundImage}>
      <StyledContainer className="hero-container" short={short}>
        <h1 className="hero-h1">{title}</h1>
        <p>{body}</p>

        {/* FOR PAGES THAT FEATURE TWO IMAGES*/}
        {imageBack && (
          <>
            <Parallax
              className="back-image"
              y={calculateParallax('back-image')}
              tagOuter="figure"
            >
              <img src={imageBack.mobile} className="mobile-only" alt="" />
              <img
                src={imageBack.desktop}
                className="tablet-desktop-only"
                alt=""
              />
            </Parallax>

            <Parallax
              className={`front-image`}
              y={calculateParallax('front-image')}
              tagOuter="figure"
              imagePosition={imagePosition}
              imageSize={imageSize}
            >
              <img src={imageFront.mobile} className="mobile-only" alt="" />
              <img
                src={imageFront.desktop}
                className="tablet-desktop-only"
                alt=""
              />
            </Parallax>
          </>
        )}
        {!imageBack && imageFront && (
          <SingleParallax
            className={`front-image`}
            y={calculateSingleParallax()}
            tagOuter="figure"
            imagePosition={imagePosition}
            imageSize={imageSize}
          >
            <img src={imageFront.mobile} className="mobile-only" alt="" />
            <img
              src={imageFront.desktop}
              className="tablet-desktop-only"
              alt=""
            />
          </SingleParallax>
        )}
        {/* {imageBack ? (
          <>
            <Parallax
              className="back-image"
              y={calculateParallax('back-image')}
              tagOuter="figure"
            >
              <img src={imageBack.mobile} className="mobile-only" alt="" />
              <img
                src={imageBack.desktop}
                className="tablet-desktop-only"
                alt=""
              />
            </Parallax>

            <Parallax
              className={`front-image`}
              y={calculateParallax('front-image')}
              tagOuter="figure"
              imagePosition={imagePosition}
              imageSize={imageSize}
            >
              <img src={imageFront.mobile} className="mobile-only" alt="" />
              <img
                src={imageFront.desktop}
                className="tablet-desktop-only"
                alt=""
              />
            </Parallax>
          </>
        ) : (
          // FOR PAGES THAT FEATURE A SINGLE IMAGE
          <SingleParallax
            className={`front-image`}
            y={calculateSingleParallax()}
            tagOuter="figure"
            imagePosition={imagePosition}
            imageSize={imageSize}
          >
            <img src={imageFront.mobile} className="mobile-only" alt="" />
            <img
              src={imageFront.desktop}
              className="tablet-desktop-only"
              alt=""
            />
          </SingleParallax>
        )} */}

        <Explore featureColor={featureColor} short={short}>
          <h6>Explore</h6>
          <div className="line" />
        </Explore>
      </StyledContainer>
    </Root>
  );
}

const mapStateToProps = state => ({
  browser: state.browser,
});

export default withController(connect(mapStateToProps)(Hero));

const Root = styled.div`
  background-color: #000000;
  background-image: ${props =>
    props.backgroundImage ? `url(${props.backgroundImage})` : 'none'};
  background-size: cover;
  background-position: center;
  height: 95vh;
  h1,
  p {
    color: #ffffff;
  }
  @media (min-width: ${media.tablet}) {
    height: 110vh;
    }
  @media (min-width: ${media.desktop}) {
    overflow: unset;
    height: ${props => (props.short ? '100vh' : '117vh')};
  }
`;

const StyledContainer = styled(Container)`
  position: relative;
  padding-top: ${props => (props.short ? vw(150) : vw(120))};
  height: 100%;
  h1 {
    margin-bottom: ${vw(20)};
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
  }
  .back-image {
    height: ${vw(280)};
    width: 100%;
    position: absolute;
    bottom: ${vw(32)};
    right: 0;
  }
  .front-image {
    height: ${vw(280)};
    width: 100%;
    position: absolute;
    bottom: ${vw(-28)};
    left: ${vw(20)};
  }
  @media (min-width: ${media.tablet}) {
    padding-top: ${props => (props.short ? vwTablet(380) : vwTablet(300))};
    h1 {
      margin-bottom: ${vwTablet(40)};
    }
    p {
      width: ${vwTablet(515)};
    }
    img {
      height: unset;
    }
    .back-image {
      height: ${vwTablet(487.3)};
      bottom: ${vwTablet(98.7)};
      left: unset;
      right: 0;
    }
    .front-image {
      height: ${vwTablet(361.8)};
      width: ${vwTablet(460)};
      bottom: ${vwTablet(64.2)};
      left: unset;
      right: 0;
    }
  }
  @media (min-width: ${media.desktop}) {
    padding-top: ${vwDesktop(220)};
    height: ${props => (props.short ? '100vh' : '117vh')};
    h1 {
      margin-bottom: ${vwDesktop(40)};
      width: ${vwDesktop(778)};
    }
    p {
      width: ${vwDesktop(515)};
    }
    img {
      height: unset;
    }
    .back-image {
      height: ${vwDesktop(788)};
      width: ${vwDesktop(1242)};
      /* bottom: ${vwDesktop(-248)}; */
      bottom: auto;
      top: ${vwDesktop(420)};
    }
    .front-image {
      height: ${vwDesktop(545)};
      width: ${vwDesktop(693)};
      /* bottom: ${vwDesktop(-175)}; */
      bottom: auto;
      top: ${vwDesktop(530)};
      right: ${vwDesktop(10)};
    }
  }
`;

const Explore = styled.div`
  position: absolute;
  bottom: ${vw(0)};
  left: ${vw(23)};
  h6 {
    color: ${props => props.featureColor};
    text-transform: uppercase;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -100%) rotate(-90deg);
  }
  > div {
    height: ${vw(60)};
    width: ${vw(1)};
    min-width: 1px;
    background-color: ${props => props.featureColor};
    margin-top: ${vw(38)};
  }
  @media (min-width: ${media.tablet}) {
    bottom: ${vwTablet(0)};
    left: ${vwTablet(40)};
    > div {
      height: ${vwTablet(120)};
      width: ${vwTablet(2)};
      min-width: 2px;
      background-color: ${props => props.featureColor};
      margin-top: ${vwTablet(46)};
    }
  }
  @media (min-width: ${media.desktop}) {
    bottom: ${props => (props.short ? 0 : vwDesktop(180))};
    left: ${vwDesktop(90)};
    > div {
      height: ${vwDesktop(120)};
      width: ${vwDesktop(2)};
      margin-top: ${vwDesktop(46)};
    }
  }
`;

const SingleParallax = styled(Parallax)`
  /* FOR TOUCHSCREENS */
  &.front-image {
    left: unset;
    bottom: ${props =>
      props.imagePosition && vw(props.imagePosition.mobile.bottom)};
    right: ${props =>
      props.imagePosition && vw(props.imagePosition.mobile.right)};
    height: ${props => props.imageSize && vw(props.imageSize.mobile.height)};
    width: ${props => props.imageSize && vw(props.imageSize.mobile.width)};
  }
  @media (min-width: ${media.tablet}) {
    &.front-image {
      bottom: ${props =>
        props.imagePosition && vwTablet(props.imagePosition.tablet.bottom)};
      right: ${props =>
        props.imagePosition && vwTablet(props.imagePosition.tablet.right)};
      height: ${props =>
        props.imageSize && vwTablet(props.imageSize.tablet.height)};
      width: ${props =>
        props.imageSize && vwTablet(props.imageSize.tablet.width)};
    }
  }
  @media (min-width: ${media.desktop}) {
    &.front-image {
      bottom: ${props =>
        props.imagePosition && vwDesktop(props.imagePosition.desktop.bottom)};
      right: ${props =>
        props.imagePosition && vwDesktop(props.imagePosition.desktop.right)};
      height: ${props =>
        props.imageSize && vwDesktop(props.imageSize.desktop.height)};
      width: ${props =>
        props.imageSize && vwDesktop(props.imageSize.desktop.width)};
    }
  }
`;
