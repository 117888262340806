import React from 'react';
import styled from 'styled-components';

import PageTemplate from 'components/PageTemplate/PageTemplate';

import theme from 'src/styles/theme';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

import webSolutions from 'src/data/webSolutions';
import clients from 'src/data/webSolutionsClients';

function WebSolutions() {
  return (
    <Root>
      <PageTemplate
        featureColor={theme.color.orange}
        pageTitle="Digital Solutions"
        hero={{
          title: `Improve your online engagement and make the best first impression.`,
          body: `Increase customer interest and action through an engaging digital presence and proven strategy.`,
          imageBack: {
            mobile: require('src/assets/images/webSolutions/web-solutions-hero-mobile.png'),
            desktop: require('src/assets/images/webSolutions/web-solutions-hero-desktop.png'),
          },
          imageFront: {
            mobile: require('src/assets/images/webSolutions/web-solutions-hero2-mobile.png'),
            desktop: require('src/assets/images/webSolutions/web-solutions-hero-mobile.png'),
          },
        }}
        intro={{
          subheader: 'Our Expertise',
          title: (
            <>
              Build a creative and
              <br /> engaging web presence.
            </>
          ),
          body: `We work closely with you to understand your challenges and needs, then we develop effective and engaging, strategy-based solutions. But we don’t just design pretty websites. (Any digital agency can do that.) Instead, our designers prototype and deliver a robust and responsive brand experience that drives customer engagement and achieves results.`,
          buttonLabel: 'Learn More',
          largeText: true,
        }}
        webSolutions={webSolutions}
        tagline="Digital Solutions"
        caseStudies={{
          subheader: 'Get Results',
          title: (
            <>
              Designed to
              <br />
              deliver results.
            </>
          ),
          body: `Everything we do at Bam Digital is designed to deliver innovative results. We are your trusted partner, using data and strategy to inform our creative decisions. We help real estate brands stand out and tell their stories in impactful ways.`,
          buttonLink: true,
          buttonLabel: 'Book a Demo',
          // featureImage: require('src/assets/images/webSolutions/spire-versante-mock.jpg'),
          imageSize: {
            mobile: {
              height: 200,
            },
            tablet: {
              height: 354,
            },
            desktop: {
              width: 1000,
              height: 500,
            },
          },
        }}
        clients={clients}
      ></PageTemplate>
    </Root>
  );
}

export default WebSolutions;

const Root = styled.div`
  .hero-container {
    overflow: hidden;
  }
  .front-image {
    bottom: ${vw(-54)};
  }
  .back-image {
    bottom: ${vw(6)};
  }
  @media (min-width: ${media.tablet}) {
    overflow: visible;
    .hero-container {
      padding-top: ${vwTablet(240)};
      overflow: visible;
    }
    .front-image {
      bottom: ${vwTablet(-16)};
    }
    .back-image {
      bottom: ${vwTablet(20)};
    }
  }
  @media (min-width: ${media.desktop}) {
    .hero-container {
      padding-top: ${vwDesktop(220)};
      h1 {
        width: ${vwDesktop(1035)};
      }
    }
    .front-image {
      bottom: ${vwDesktop(-215)};
    }
    .back-image {
      bottom: ${vwDesktop(-228)};
    }
  }
`;
