import { sitemapBuilder as buildSitemap } from 'react-router-sitemap';
import { routesParser as parseRoutes } from 'react-router-sitemap';
import { paramsApplier as applyParams } from 'react-router-sitemap';
import routes from './routes';
import path from 'path';
import fs from 'fs';

const projects = [
  'walton-lofts',
  'gryphon-development',
  'centra',
  'spire',
  'the-holland',
  'vpac-construction',
];

const config = {
  '/work/:id': [
    // in our case slug is the parameter
    { id: projects }, // so we will use id for the pattern replace here
  ],
};

const parsedRoutes = parseRoutes(routes);

const hostname = process.env.npm_package_host;

// define our destination folder and sitemap file name
const dest = path.resolve('./public', 'sitemap.xml');

// Merge our route paths with config pattern
const paths = applyParams(parsedRoutes, config);

const sitemap = buildSitemap(hostname, paths);

// write sitemap.xml file in /public folder
// Access the sitemap content by converting it with .toString() method
fs.writeFileSync(dest, sitemap.toString());
