import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import Button from 'components/Button';
import Container from 'components/Container';
import UnderlineText from 'components/Typography/UnderlineText';
import Clients from './../elements/Clients';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const CaseStudies = props => {
  const {
    subheader,
    title,
    body,
    caseStudies,
    clients,
    featureImage,
    featureColor,
    imageSize,
    buttonLink,
    pageTitle,
    buttonLabel,
    subject,
  } = props;
  return (
    <Root>
      <StyledContainer
        featureColor={featureColor}
        className="case-studies-container"
      >
        <div>
          <h6 className="subheader">{subheader}</h6>
          <h1>{title}</h1>
          <p>{body}</p>
          {caseStudies && (
            <>
              <span>Case {caseStudies.length > 1 ? 'Studies' : 'Study'}:</span>
              <Studies>
                {caseStudies.map((caseStudy, index) => (
                  <UnderlineText
                    key={index}
                    text={caseStudy.title}
                    black
                    color={featureColor}
                    className="case-study-link"
                    handleClick={() =>
                      props.history.push(`/work${caseStudy.path}`, {
                        from: pageTitle,
                        path: props.location.pathname,
                      })
                    }
                  ></UnderlineText>
                ))}
              </Studies>
            </>
          )}
          {buttonLink && (
            <Button
              color={featureColor}
              mailLink
              label={buttonLabel}
              subject={subject}
            />
          )}
        </div>

        {featureImage && (
          <FeatureImage
            src={featureImage}
            imageSize={imageSize}
            className="case-study-image"
          />
        )}

        {clients && <Clients clients={clients} featureColor={featureColor} />}
      </StyledContainer>
    </Root>
  );
};

export default withRouter(CaseStudies);

const Root = styled.div``;

const StyledContainer = styled(Container)`
  padding-bottom: ${vw(80)};
  h1 {
    margin-top: ${vw(10)};
    margin-bottom: ${vw(20)};
  }
  h6.subheader {
    color: ${props => props.featureColor};
  }
  p {
    margin-bottom: ${vw(20)};
  }
  span {
    font-size: ${vw(11)};
    font-weight: 500;
    line-height: 1.27;
    letter-spacing: ${vw(2)};
    color: #86878a;
    text-transform: uppercase;
    display: block;
  }
  a {
    display: block;
    position: relative;
  }
  .case-study-link {
    margin-top: ${vw(10)};
    margin-right: ${vw(20)};
    > p {
      display: inline !important;
    }
    /* &::after {
      content: '';
      position: absolute;
      bottom: ${vw(-5)};
      left: 0;
      background-color: ${props => props.featureColor};
      width: ${vw(20)};
      height: ${vw(2)};
    } */
  }
  @media (min-width: ${media.tablet}) {
    padding-bottom: ${vwTablet(160)};
    h1 {
      margin-top: ${vwTablet(20)};
      margin-bottom: ${vwTablet(40)};
      width: ${vwTablet(515)};
    }

    p {
      margin-bottom: ${vwTablet(40)};
    }
    span {
      font-size: ${vwTablet(14)};
      line-height: 1.43;
      letter-spacing: ${vwTablet(2)};
      color: ${({ theme }) => theme.color.grey2};
    }

    .case-study-link {
      margin-top: ${vwTablet(10)};
      margin-right: ${vwTablet(40)};

      &::after {
        bottom: ${vwTablet(-5)};
        width: ${vwTablet(20)};
        height: ${vwTablet(2)};
      }
    }
  }
  @media (min-width: ${media.desktop}) {
    padding-bottom: ${vwDesktop(160)};
    h1 {
      margin-top: ${vwDesktop(20)};
      margin-bottom: ${vwDesktop(40)};
      width: ${vwDesktop(625)};
    }
    p {
      margin-bottom: ${vwDesktop(40)};
      width: ${vwDesktop(734)};
    }
    span {
      font-size: ${vwDesktop(14)};
      letter-spacing: ${vwDesktop(2)};
    }
    a {
      display: block;
      > p {
        margin-bottom: unset;
        display: inline-block;
        width: auto;
      }
    }
    .case-study-link {
      margin-top: ${vwDesktop(10)};
      margin-right: ${vwDesktop(40)};

      &::after {
        bottom: ${vwDesktop(-5)};
        width: ${vwDesktop(20)};
        height: ${vwDesktop(2)};
        transition: width 0.5s ease;
      }
      &:hover ::after {
        width: ${vwDesktop(40)};
      }
    }
  }
`;

const FeatureImage = styled.img`
  height: ${props =>
    props.imageSize && props.imageSize.mobile && props.imageSize.mobile.height
      ? vw(props.imageSize.mobile.height)
      : '100%'};
  width: 100%;
  object-fit: cover;
  object-position: center;
  margin-top: ${vw(80)};
  @media (min-width: ${media.tablet}) {
    margin-top: ${vwTablet(160)};
    height: ${props =>
      props.imageSize && props.imageSize.tablet && props.imageSize.tablet.height
        ? vwTablet(props.imageSize.tablet.height)
        : '100%'};
  }
  @media (min-width: ${media.desktop}) {
    margin: 0 auto;
    display: block;
    margin-top: ${vwDesktop(167)};
    height: ${props =>
      props.imageSize &&
      props.imageSize.desktop &&
      props.imageSize.desktop.height
        ? vwDesktop(props.imageSize.desktop.height)
        : '100%'};
    width: ${props =>
      props.imageSize &&
      props.imageSize.desktop &&
      props.imageSize.desktop.width
        ? vwDesktop(props.imageSize.desktop.width)
        : '100%'};
  }
`;

const Studies = styled.div`
  display: flex;
  margin-bottom: ${vw(20)};
  @media (min-width: ${media.tablet}) {
    margin-bottom: ${vwTablet(40)};
  }
  @media (min-width: ${media.desktop}) {
    margin-bottom: ${vwDesktop(40)};
  }
`;
