import React from 'react';
import styled from 'styled-components';

import PageTemplate from 'components/PageTemplate/PageTemplate';

import theme from 'src/styles/theme';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

import webSolutions from 'src/data/digitalMarketing';

function DataIntelligence() {
  return (
    <Root>
      <PageTemplate
        featureColor={theme.color.green}
        pageTitle="Data Intelligence"
        hero={{
          title: `Expand your reach with fully integrated
          digital marketing solutions.`,
          body: `Data capabilities and analysis drive our performance marketing strategy to target your message and attract your customers to take action.`,
          backgroundImage: require('src/assets/images/digitalMarketing/digital-marketing-hero.jpg'),
          short: true,
        }}
        intro={{
          subheader: 'Our Expertise',
          title: <>Trust in the data insights.</>,
          body: (
            <>
              A digital strategy is more than just having a website. It’s about
              understanding your buyers and how best to reach them. Research and
              strategy are the core of our digital marketing efforts. We look at
              market intelligence and strategic data to validate our decisions
              and eliminate assumptions. Then we take these insights and craft a
              digital strategy that results in increased customer reach,
              conversions, and sales.
            </>
          ),
        }}
        data={true}
        webSolutions={webSolutions}
        tagline="Digital Strategy"
        caseStudies={{
          subheader: 'Get results',
          title: `Your trusted
          marketing partner.`,
          body: `We know your industry and we know how to talk to your clients. With a mixture of audience intelligence and market data, we test, optimize, and deliver digital marketing solutions that result in conversions. That’s why we’re trusted by so many industry-leading clients.`,
          buttonLink: true,
          buttonLabel: 'Book a Demo',
        }}
      ></PageTemplate>
    </Root>
  );
}

export default DataIntelligence;

const Root = styled.div`
  .hero-container {
    overflow: hidden;
  }
  .front-image {
    bottom: ${vw(-54)};
  }
  .back-image {
    bottom: ${vw(6)};
  }
  .case-study-image {
    margin-top: ${vw(40)};
  }
  @media (min-width: ${media.tablet}) {
    overflow: visible;
    .hero-container {
      padding-top: ${vwTablet(240)};
      overflow: visible;
    }
    .front-image {
      bottom: ${vwTablet(-16)};
    }
    .back-image {
      bottom: ${vwTablet(20)};
    }
    .case-study-image {
      margin-top: ${vwTablet(80)};
    }
  }
  @media (min-width: ${media.desktop}) {
    .hero-container {
      padding-top: ${vwDesktop(220)};
      h1 {
        width: ${vwDesktop(1147)};
      }
      p {
        width: ${vwDesktop(618)};
      }
      .line {
        height: ${vwDesktop(60)};
      }
    }
    .intro-container p {
      width: ${vwDesktop(734)};
    }
    .front-image {
      bottom: ${vwDesktop(-215)};
    }
    .back-image {
      bottom: ${vwDesktop(-228)};
    }
    .case-studies-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      img {
        margin-top: unset;
        margin: unset;
      }
      > div,
      h1 {
        width: ${vwDesktop(600)};
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      p {
        width: ${vwDesktop(734)};
      }
    }
  }
`;
