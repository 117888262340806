export default [
  {
    name: 'Anthem Properties',
    image: require('src/assets/images/logos/clients/anthem-properties.png'),
    path: 'https://anthemproperties.com/',
  },
  {
    name: 'Concord Pacific',
    image: require('src/assets/images/logos/clients/concord-pacific.png'),
    path: 'https://www.concordpacific.com/',
  },
  {
    name: 'Beedie Living',
    image: require('src/assets/images/logos/clients/beedie-living.png'),
    path: 'https://www.beedie.ca/',
  },
  {
    name: 'Colliers International',
    image: require('src/assets/images/logos/clients/colliers-international.png'),
    path: 'https://www2.colliers.com/en',
  },
  {
    name: 'Townline',
    image: require('src/assets/images/logos/clients/townline.png'),
    path: 'https://www.townline.ca/',
  },
  {
    name: 'Boffo',
    image: require('src/assets/images/logos/clients/boffo.png'),
    path: 'http://www.boffoproperties.com/',
  },
  {
    name: 'Onni Group',
    image: require('src/assets/images/logos/clients/onni-group.png'),
    path: 'https://www.onni.com/',
  },
  {
    name: 'Aoyuan',
    image: require('src/assets/images/logos/clients/aoyuan.png'),
    path: 'https://aoyuaninternational.com/',
  },
];
