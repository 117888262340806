import React, { Fragment } from 'react';
import styled from 'styled-components';

import FeaturedProject from 'components/FeaturedProject';
import Container from 'components/Container';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

import projects from 'src/data/projects';

const Projects = () => {
  return (
    <Root name="work" id="work">
      <Container>
        <h6>Our Work</h6>
        <h1>Our success stories.</h1>
        <Wrapper>
          {projects.map((project, index) => (
            <Fragment key={index}>
              <FeaturedProject
                project={project}
                small
                noMargin={
                  index === projects.length - 1 || index === projects.length - 2
                }
              />
            </Fragment>
          ))}
        </Wrapper>
      </Container>
    </Root>
  );
};

const Root = styled.div`
  background-color: white;
  margin-top: ${vw(40)};
  padding-top: ${vw(40)};
  h6 {
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.red};
  }
  h1 {
    margin: ${vw(10)} 0 ${vw(40)};
  }
  br {
    display: none;
  }
  @media (min-width: ${media.tablet}) {
    margin-top: ${vwTablet(80)};
    padding-top: ${vwTablet(80)};
    h1 {
      margin: ${vwTablet(20)} 0 ${vwTablet(80)};
    }
    br {
      display: block;
    }
  }
  @media (min-width: ${media.desktop}) {
    margin-top: ${vwDesktop(80)};
    padding-top: ${vwDesktop(80)};
    h1 {
      margin: ${vwDesktop(20)} 0 ${vwDesktop(80)};
    }
  }
`;

const Wrapper = styled.div`
  @media (min-width: ${media.tablet}) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;

export default Projects;
