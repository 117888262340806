import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import WaypointTrigger from 'components/Animation/WaypointTrigger';
import FadeUp from 'components/Animation/FadeUp';
import UnderlineText from 'components/Typography/UnderlineText';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';
import theme from 'src/styles/theme';

const ProjectDetails = ({
  title,
  description,
  location,
  client,
  product,
  scope,
  website,
}) => (
  <Root className="white-background">
    <WaypointTrigger topOffset="0%" bottomOffset="30%">
      {animate => (
        <>
          <FadeUp animate={animate}>
            <h2>{title}</h2>
          </FadeUp>
          <FadeUp animate={animate} delay={0.15} className="fadeup">
            <p className="description">{description}</p>
          </FadeUp>
        </>
      )}
    </WaypointTrigger>
    <WaypointTrigger topOffset="0%" bottomOffset="30%">
      {animate => (
        <FadeUp animate={animate}>
          <Container>
            {website ? (
              <StyledUnderlineText
                href={website}
                target="_blank"
                text="Visit Website"
                black
                color={theme.color.red}
              />
            ) : (
              <Placeholder />
            )}
            <Info product={product}>
              {location && (
                <div>
                  <h6>Location</h6>
                  <p>{location}</p>
                </div>
              )}
              {client && (
                <div>
                  <h6>Client</h6>
                  <p>{client}</p>
                </div>
              )}
              {product && (
                <div>
                  <h6>Product</h6>
                  <p>{product}</p>
                </div>
              )}
              <div className="scope">
                <h6>Scope</h6>
                {scope.map((item, index) => (
                  <p key={index}>{item}</p>
                ))}
              </div>
            </Info>
          </Container>
        </FadeUp>
      )}
    </WaypointTrigger>
  </Root>
);

ProjectDetails.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  location: PropTypes.string,
  client: PropTypes.string,
  product: PropTypes.string,
  scope: PropTypes.array,
  website: PropTypes.string,
};

export default ProjectDetails;

const Root = styled.div`
  padding: ${vw(40)} ${vw(16)} ${vw(80)};
  display: flex;
  flex-direction: column;
  background: url(${require('src/assets/images/white-background-large.jpg')});
  background-size: cover;
  background-position: center;
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(80)} ${vwTablet(30)};
  }
  @media (min-width: ${media.desktop}) {
    padding: ${vwDesktop(80)} ${vwDesktop(190)};
  }
  > div {
    display: flex;
    flex-direction: column;
  }
  .fadeup {
    width: 100%;
    margin: ${vw(20)} 0 ${vw(40)};
    @media (min-width: ${media.tablet}) {
      width: 80%;
      margin: ${vwTablet(40)} 0;
    }
    @media (min-width: ${media.desktop}) {
      align-self: flex-end;
      width: ${vwDesktop(625)};
      margin: ${vwDesktop(80)} 0;
    }
  }
  h2 {
    @media (min-width: ${media.desktop}) {
      max-width: ${vwDesktop(843)};
    }
  }
  h6 {
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.red};
    margin-bottom: ${vw(4)};
    @media (min-width: ${media.tablet}) {
      margin-bottom: ${vwTablet(10)};
    }
    @media (min-width: ${media.desktop}) {
      margin-bottom: ${vwDesktop(10)};
    }
  }
  p {
    font-size: ${vw(14)};
    font-weight: 300;
    line-height: ${vw(22)};
    letter-spacing: ${vw(0.5)};
    @media (min-width: ${media.tablet}) {
      font-size: ${vwTablet(16)};
      line-height: ${vwTablet(24)};
      letter-spacing: ${vwTablet(0.5)};
    }
    @media (min-width: ${media.desktop}) {
      font-size: ${vwDesktop(16)};
      line-height: ${vwDesktop(24)};
      letter-spacing: ${vwDesktop(0.5)};
    }
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media (min-width: ${media.tablet}) {
    padding-left: ${vwTablet(121)};
  }
  @media (min-width: ${media.desktop}) {
    flex-direction: row;
    padding-left: 0;
  }
`;

const StyledUnderlineText = styled(UnderlineText)`
  order: 2;
  @media (min-width: ${media.desktop}) {
    order: 1;
  }
`;

const Info = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  order: 1;
  @media (min-width: ${media.tablet}) {
    max-height: ${props => (props.product ? vwTablet(250) : vwTablet(180))};
  }
  @media (min-width: ${media.desktop}) {
    order: 2;
    width: ${vwDesktop(625)};
    max-height: ${props => (props.product ? vwDesktop(242) : vwDesktop(180))};
    div:nth-child(3) {
      margin-bottom: 0;
    }
  }
  > div {
    height: fit-content;
    margin-bottom: ${vw(40)};
    @media (min-width: ${media.tablet}) {
      margin-bottom: ${vwTablet(40)};
    }
    @media (min-width: ${media.desktop}) {
      margin-bottom: ${vwDesktop(40)};
    }
  }

  .scope p {
    /* margin-bottom: ${vw(7)}; */
    max-width: 100%;
    &::before {
      display: inline-block;
      content: '';
      width: 12px;
      height: 1px;
      background-color: #cccfd1;
      transform: translateY(-50%);
      margin-right: ${vw(10)};
      margin-bottom: ${vw(4)};
      @media (min-width: ${media.tablet}) {
        display: none;
      }
    }
    @media (min-width: ${media.tablet}) {
      margin-bottom: ${vwTablet(5)};
    }
  }
`;

const Placeholder = styled.div`
  width: 20%;
`;
