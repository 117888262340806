import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';

import DragScroll from 'components/DragScroll';
import WaypointTrigger from 'components/Animation/WaypointTrigger';
import FadeUp from 'components/Animation/FadeUp';
// import CustomCursor from 'components/CustomCursor';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

import imgiPhone from 'src/assets/images/projects/mobile.png';

class G extends Component {
  state = {
    hasClicked: false,
  };

  handleLeaveWaypoint = () => this.setState({ hasClicked: false });

  handleClick = () => this.setState({ hasClicked: true });

  render() {
    const {
      deviceImage,
      backgroundImage,
      rightImage,
      backgroundType,
      backgroundColor,
      topColor,
      bottomColor,
      mediaType,
    } = this.props;
    return (
      <Waypoint onLeave={this.handleLeaveWaypoint}>
        <div>
          <WaypointTrigger>
            {animate => (
              <FadeUp animate={animate}>
                <Root>
                  <Image image={backgroundImage} isRight={rightImage} />
                  <Device
                    isLeft={rightImage}
                    backgroundType={backgroundType}
                    backgroundColor={backgroundColor}
                    topColor={topColor}
                    bottomColor={bottomColor}
                    className="white-background"
                  >
                    <Container>
                      <img src={imgiPhone} alt="iPhone" className="device" />
                      <StyledDragScroll
                        as={mediaType === 'static' ? 'div' : DragScroll}
                        scroll={mediaType === 'scroll'}
                        onMouseDown={this.handleClick}
                        onTouchStart={this.handleClick}
                      >
                        {mediaType === 'scroll' && (
                          <DragOverlay active={!this.state.hasClicked}>
                            <img
                              src={require('src/assets/images/cursors/cursor_drag_vertical.svg')}
                              alt="Drag"
                              className="drag-overlay"
                            />
                            <p>Drag</p>
                          </DragOverlay>
                        )}
                        {/* <Cursor
                          as={mediaType === 'scroll' ? CustomCursor : 'div'}
                          dragVertical
                        > */}
                        <img
                          className="device-image"
                          id="scroll-image"
                          src={deviceImage}
                          alt=""
                        />
                        {/* </Cursor> */}
                      </StyledDragScroll>
                    </Container>
                  </Device>
                </Root>
              </FadeUp>
            )}
          </WaypointTrigger>
        </div>
      </Waypoint>
    );
  }
}

G.propTypes = {
  deviceImage: PropTypes.string,
  backgroundImage: PropTypes.string,
  rightImage: PropTypes.bool,
  backgroundType: PropTypes.string,
  backgroundColor: PropTypes.string,
  topColor: PropTypes.string,
  bottomColor: PropTypes.string,
  mediaType: PropTypes.string,
};

G.defaultProps = {
  rightImage: false,
  backgroundType: 'solid',
  backgroundColor: 'white',
  mediaType: 'scroll',
};

export default G;

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: ${media.tablet}) {
    height: ${vwTablet(512)};
    flex-direction: row;
  }
  @media (min-width: ${media.desktop}) {
    height: 100vh;
  }
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    height: 50vh;
  }
`;

const Image = styled.div`
  width: 100%;
  height: ${vw(245)};
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  order: ${props => (props.isRight ? 2 : 1)};
  @media (min-width: ${media.tablet}) {
    width: 65.2%;
    height: auto;
  }
`;

const Device = styled.div`
  width: 100%;
  padding: ${vw(70)} ${vw(100)} ${vw(80)};
  display: flex;
  align-items: center;
  justify-content: center;
  order: ${props => (props.isLeft ? 1 : 2)};
  background: ${props => {
    if (props.backgroundType === 'solid') {
      return `url(${require('src/assets/images/white-background-large.jpg')})`;
    } else {
      return `linear-gradient(0deg, ${props.bottomColor} 0%, ${props.topColor} 100%)`;
    }
  }};
  @media (min-width: ${media.tablet}) {
    width: 34.8%;
    padding: 0 ${vwDesktop(115)};
  }
  .device {
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 3;
    pointer-events: none;
    position: relative;
  }
  .device-image {
    height: auto;
    width: 100%;
    z-index: 1;
    object-fit: cover;
    position: relative;
    margin-bottom: -1%;
  }
`;

const Container = styled.div`
  position: relative;
`;

const StyledDragScroll = styled(DragScroll)`
  width: 90%;
  height: 90.5%;
  position: absolute;
  top: 6.3%;
  left: 5%;
  overflow: scroll;
  z-index: 1;
  cursor: grab;
  ::-webkit-scrollbar {
    display: none;
  }
`;

// const Cursor = styled.div``;

const DragOverlay = styled.div`
  height: 34.5%;
  width: 34%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  pointer-events: none;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: ${props => (props.active ? 1 : 0)};
  transition: 0.5s ease;
  @media (min-width: ${media.tablet}) {
    height: 51.5%;
    width: 16.8%;
  }
  @media (min-width: ${media.desktop}) {
    display: none;
  }
  img {
    height: ${vw(30)};
    width: ${vw(30)};
    margin-bottom: ${vw(15)};
    @media (min-width: ${media.tablet}) {
      height: ${vwTablet(40)};
      width: ${vwTablet(40)};
      margin-bottom: ${vwTablet(15)};
    }
  }
  p {
    text-transform: uppercase;
    font-size: ${vw(12)};
    letter-spacing: ${vw(1.5)};
    line-height: inherit;
    margin: 0;
    font-weight: bold;
    text-align: center;
    color: white;
    @media (min-width: ${media.tablet}) {
      font-size: ${vwTablet(15)};
      letter-spacing: ${vwTablet(1.5)};
    }
  }
`;
