import React from 'react';
import styled from 'styled-components';

import Explore from 'components/Explore';
import ImageFade from 'components/Animation/ImageFade';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Hero = () => {
  return (
    <Root>
      <StyledImageFade
        src={require('src/assets/images/home/vancouver-aerial-view.jpg')}
      />
      <div className="content">
        <h1 className="mobile-hero">
          We are a results-driven studio, creating digital solutions for the real
          estate industry.
        </h1>
      </div>
      <Explore color="#f04868" negativeBottom />
    </Root>
  );
};

export default Hero;

const Root = styled.div`
  width: 100%;
  height: 70vh;
  background-color: black;
  position: relative;
  padding: ${vw(180)} ${vw(16)} 0;
  .content {
    position: relative;
    z-index: 2;
  }
  h1 {
    color: white;
  }
  @media (min-width: ${media.tablet}) {
    height: 100vh;
    padding: ${vwTablet(320)} ${vwTablet(30)} 0;
  }
  @media (min-width: ${media.desktop}) {
    height: 100vh;
    padding: ${vwDesktop(260)} ${vwDesktop(80)} 0;
    h1 {
      width: ${vwDesktop(952)};
    }
  }
`;

const StyledImageFade = styled(ImageFade)`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
`;
