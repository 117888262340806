import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import WaypointTrigger from 'components/Animation/WaypointTrigger';
import FadeUp from 'components/Animation/FadeUp';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const J = ({ image, text, leftImage }) => (
  <WaypointTrigger>
    {animate => (
      <FadeUp animate={animate}>
        <Root>
          <Content isRight={leftImage}>
            <p>{text}</p>
          </Content>
          <Image image={image} src={image} isLeft={leftImage} />
        </Root>
      </FadeUp>
    )}
  </WaypointTrigger>
);

J.propTypes = {
  text: PropTypes.string,
  image: PropTypes.string,
  leftImage: PropTypes.bool,
};

J.defaultProps = {
  leftImage: false,
};

export default J;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${media.tablet}) {
    flex-direction: row;
  }
`;

const Content = styled.div`
  width: 100%;
  padding: ${vw(80)} 0;
  position: relative;
  order: ${props => (props.isRight ? 2 : 1)};
  @media (min-width: ${media.tablet}) {
    width: 50%;
    padding: ${vwTablet(162)} 0;
  }
  @media (min-width: ${media.desktop}) {
    padding: ${vwDesktop(350)} 0;
  }
  p {
    font-size: ${vw(14)};
    font-weight: 300;
    line-height: ${vw(22)};
    letter-spacing: ${vw(0.5)};
    width: 50%;
    margin: 0 auto;
    @media (min-width: ${media.tablet}) {
      font-size: ${vwTablet(15)};
      line-height: ${vwTablet(24)};
      letter-spacing: ${vwTablet(0.5)};
      position: sticky;
      width: 55.4%;
      top: ${vwTablet(162)};
    }
    @media (min-width: ${media.desktop}) {
      font-size: ${vwDesktop(18)};
      line-height: ${vwDesktop(32)};
      letter-spacing: ${vwDesktop(1)};
      width: 41.7%;
      top: ${vwDesktop(350)};
    }
  }
`;

const Image = styled.img`
  height: auto;
  width: 100%;
  object-position: center;
  order: ${props => (props.isLeft ? 1 : 2)};
  @media (min-width: ${media.tablet}) {
    width: 50%;
    height: 100%;
  }
`;
