// Constants
export const APP__ENABLE_SCROLL = 'APP::ENABLE_SCROLL';
export const APP__DISABLE_SCROLL = 'APP::DISABLE_SCROLL';

// Actions
export const enableScroll = () => {
  document.querySelector('body').classList.remove('disable-scroll');
  return {
    type: APP__ENABLE_SCROLL,
  };
};

export const disableScroll = () => {
  document.querySelector('body').classList.add('disable-scroll');
  return {
    type: APP__DISABLE_SCROLL,
  };
};

// Initial State
const initialState = {
  isScrollEnabled: true,
};

// Reducer
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case APP__ENABLE_SCROLL:
      return {
        ...state,
        isScrollEnabled: true,
      };
    case APP__DISABLE_SCROLL:
      return {
        ...state,
        isScrollEnabled: false,
      };
    default:
      return state;
  }
};
