import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Container from 'components/Container';

import data from 'src/data/clients';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Clients = () => {
  const [activeLogos, setActiveLogos] = useState(0);

  useEffect(() => {
    const interval = setInterval(handleLogos, 3000);
    return () => clearInterval(interval);
  }, [activeLogos]);

  const handleLogos = () => {
    if (activeLogos === 0) {
      setActiveLogos(1);
    } else if (activeLogos === 1) {
      setActiveLogos(0);
    }
  };

  return (
    <Root>
      <Container>
        <h6>Our Clients</h6>
        <Flex>
          <h1>Our network.</h1>
          <Wrapper>
            {data.map((item, index) => (
              <Logos key={index} active={index === activeLogos}>
                {item.map((logo, logoIndex) => (
                  <Logo key={logoIndex}>
                    <img src={logo} alt="" />
                  </Logo>
                ))}
              </Logos>
            ))}
          </Wrapper>
        </Flex>
      </Container>
    </Root>
  );
};

export default Clients;

const Root = styled.div`
  padding: ${vw(80)} 0;
  h6 {
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.red};
  }
  h1 {
    margin: ${vw(10)} 0 ${vw(20)};
  }
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(160)} 0;
    h1 {
      margin: ${vwTablet(20)} 0 ${vwTablet(40)};
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: ${vwDesktop(160)} 0;
    h1 {
      margin: ${vwDesktop(20)} 0 0;
      width: ${vwDesktop(456)};
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: ${vw(250)};
  position: relative;
  @media (min-width: ${media.tablet}) {
    height: ${vwTablet(220)};
  }
  @media (min-width: ${media.desktop}) {
    height: ${vwDesktop(220)};
    width: ${vwDesktop(736)};
  }
`;

const Flex = styled.div`
  @media (min-width: ${media.desktop}) {
    display: flex;
    justify-content: space-between;
  }
`;

const Logos = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  opacity: ${props => (props.active ? 1 : 0)};
  pointer-events: ${props => (props.active ? 'all' : 'none')};
  transition: 0.4s ease-in-out;
  transition-delay: ${props => (props.active ? '0.4s' : '0s')};
`;

const Logo = styled.div`
  width: ${vw(136)};
  height: ${vw(70)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${vw(20)};
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  @media (min-width: ${media.tablet}) {
    width: ${vwTablet(192)};
    height: ${vwTablet(100)};
    margin-bottom: ${vwTablet(20)};
  }
  @media (min-width: ${media.desktop}) {
    width: ${vwDesktop(192)};
    height: ${vwDesktop(100)};
    margin-bottom: ${vwDesktop(20)};
  }
`;
