import React from 'react';
import styled from 'styled-components';

import PageTemplate from 'components/PageTemplate/PageTemplate';

import theme from 'src/styles/theme';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

import webSolutions from 'src/data/dataIntelligence';

function DataIntelligence() {
  return (
    <Root>
      <PageTemplate
        featureColor={theme.color.purple}
        pageTitle="Data Intelligence"
        hero={{
          title: `Uncover the insights in your data that 
          drive competitive advantage.`,
          body: `Brands that use data intelligence maximize their marketing spend.`,
          backgroundImage: require('src/assets/images/dataIntelligence/data-hero.jpg'),
          short: true,
        }}
        intro={{
          subheader: 'Our expertise',
          title: (
            <>
              Capitalize on
              <br />
              your data.
            </>
          ),
          body: `Every organization produces more data than ever before. Get the competitive advantage that will set you apart from the rest. We help you collect and uncover the stories behind the data. With end-to-end data analytics, we’re able to provide key insights and answers that drive strategic decisions to achieve your goals.`,
          buttonLabel: 'Learn More',
        }}
        data={true}
        longerTagline={true}
        webSolutions={webSolutions}
        tagline="Power of Data"
        caseStudies={{
          subheader: 'Get results',
          title: `Get data-driven insights that inform strategy.`,
          body: `Big data is transforming the real estate industry. Making sense of this sea of data doesn’t have to be difficult—we make it easy to understand, by using data visualization that tells meaningful stories. Instantly view your KPIs, manipulate the data to uncover its strategic value, and get results.`,
          buttonLink: true,
          buttonLabel: 'Book a Demo',
        }}
      ></PageTemplate>
    </Root>
  );
}

export default DataIntelligence;

const Root = styled.div`
  .hero-container {
    overflow: hidden;
  }
  .front-image {
    bottom: ${vw(-54)};
  }
  .back-image {
    bottom: ${vw(6)};
  }
  .case-study-image {
    margin-top: ${vw(40)};
  }
  @media (min-width: ${media.tablet}) {
    overflow: visible;
    .hero-container {
      padding-top: ${vwTablet(240)};
      overflow: visible;
    }
    .front-image {
      bottom: ${vwTablet(-16)};
    }
    .back-image {
      bottom: ${vwTablet(20)};
    }
    .case-study-image {
      margin-top: ${vwTablet(80)};
    }
  }
  @media (min-width: ${media.desktop}) {
    .hero-container {
      padding-top: ${vwDesktop(220)};
      h1 {
        width: ${vwDesktop(1119)};
      }
      p {
        width: ${vwDesktop(625)};
      }
      .line {
        height: ${vwDesktop(60)};
      }
    }
    .front-image {
      bottom: ${vwDesktop(-215)};
    }
    .back-image {
      bottom: ${vwDesktop(-228)};
    }
    .intro-container p {
      width: ${vwDesktop(734)};
    }
    .case-studies-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      img {
        margin-top: unset;
        margin: unset;
      }
      > div,
      h1,
      p {
        width: ${vwDesktop(734)};
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
`;
