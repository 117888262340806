import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';

export default class WaypointTrigger extends Component {
  state = {
    isAnimateIn: false,
  };

  static propTypes = {
    children: PropTypes.func,
  };

  static defaultProps = {
    children: f => f,
  };

  handleEnter = () => {
    this.setState({ isAnimateIn: true });
  };

  handleLeave = () => {
    this.setState({ isAnimateIn: false });
  };

  render() {
    return (
      <Waypoint onEnter={this.handleEnter} {...this.props}>
        <div>{this.props.children(this.state.isAnimateIn)}</div>
      </Waypoint>
    );
  }
}
