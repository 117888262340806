import React from 'react';
import styled from 'styled-components';

import Hero from 'components/Panels/Hero';
import ProjectDetails from 'components/Panels/ProjectDetails';
import G from 'components/Panels/G';
import E from 'components/Panels/E';

import theme from 'src/styles/theme';

const VPAC = () => (
  <Root>
    <Hero
      image={require('src/assets/images/projects/vpac/vpac_herobanner.jpg')}
      title="VPAC Construction"
    />
    <ProjectDetails
      title="People, passion, progress, trust."
      description="VPAC Construction Group specializes in providing clients with design and build expertise to deliver a full spectrum of pre-construction and construction management, and general contracting services for commercial, multi-family, and seniors’ housing projects."
      client="VPAC Construction Group Ltd."
      scope={['Responsive Website']}
      website="http://www.vpacconstruction.com/"
    />
    <E
      laptop
      backgroundType="solid"
      backgroundImage="#ffffff"
      mediaType="scroll"
      media={require('src/assets/images/projects/vpac/vpac_E_laptop_mockup.jpg')}
      text="Showcasing a full portfolio of work, the new website positions VPAC Construction Group as an experienced industry leader."
    />
    <G
      deviceImage={require('src/assets/images/projects/vpac/vpac_G_mobile_mockup.jpg')}
      backgroundImage={require('src/assets/images/projects/vpac/vpac_G_sideimage.jpg')}
      backgroundType="gradient"
      bottomColor={theme.color.grey4}
      topColor={theme.color.grey4}
    />
  </Root>
);

export default VPAC;

const Root = styled.div``;
