import React from 'react';
import styled from 'styled-components';

import Container from 'components/Container';
import FeatureCard from './../elements/FeatureCard';
import WebSolutionCard from './../elements/WebSolutionCard';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Features = props => {
  const {
    features,
    featureColor,
    webSolutions,
    handleModal,
    data,
    tagline,
    longerTagline,
  } = props;

  return (
    <Root>
      <StyledContainer
        featureColor={featureColor}
        webSolutions={webSolutions}
        data={data}
        longerTagline={longerTagline}
      >
        <h1 className="display-outline">{tagline}</h1>
        <CardContainer webSolutions={webSolutions}>
          {webSolutions
            ? webSolutions.map((webSolution, index) => (
                <WebSolutionCard
                  webSolution={webSolution}
                  key={index}
                  featureColor={featureColor}
                  handleModal={handleModal}
                  index={index}
                />
              ))
            : features.map((feature, index) => (
                <FeatureCard
                  feature={feature}
                  key={index}
                  featureColor={featureColor}
                  handleModal={handleModal}
                  index={index}
                />
              ))}
        </CardContainer>
      </StyledContainer>
    </Root>
  );
};

export default Features;

const Root = styled.div``;

const StyledContainer = styled(Container)`
  padding: ${props => props.webSolutions && 'unset'};
  h1 {
    margin-bottom: ${vw(40)};
    padding-left: ${props => props.webSolutions && vw(16)};
    -webkit-text-stroke: 1px ${props => props.featureColor};
  }
  @media (min-width: ${media.tablet}) {
    position: relative;
    padding-right: ${vwTablet(30)};
    padding-left: ${vwTablet(152)};

    h1 {
      margin-bottom: unset;
      position: absolute;
      top: ${props => {
        if (props.webSolutions && props.data && !props.longerTagline) {
          return vwTablet(380);
        } else if (props.longerTagline) {
          return vwTablet(320);
        } else if (props.webSolutions) {
          return vwTablet(400);
        } else {
          return vwTablet(195);
        }
      }};
      right: ${props => {
        if (props.webSolutions && props.data && !props.longerTagline) {
          return vwTablet(300);
        } else if (props.longerTagline) {
          return vwTablet(350);
        } else if (props.webSolutions) {
          return vwTablet(272);
        } else {
          return vwTablet(484);
        }
      }};
      padding-left: unset;
      transform: rotate(-90deg);
      white-space: ${props => props.webSolutions && 'nowrap'};
    }
  }
  @media (min-width: ${media.desktop}) {
    position: relative;
    padding-left: ${vwDesktop(298)};
    padding-right: ${props =>
      props.webSolutions ? vwDesktop(190) : vwDesktop(256)};
    h1 {
      top: ${props => {
        if (props.webSolutions && props.data && !props.longerTagline) {
          return vwDesktop(380);
        } else if (props.longerTagline) {
          return vwDesktop(330);
        } else if (props.webSolutions) {
          return vwDesktop(400);
        } else {
          return vwDesktop(195);
        }
      }};
      right: ${props => {
        if (props.webSolutions && props.data && !props.longerTagline) {
          return vwDesktop(920);
        } else if (props.longerTagline) {
          return vwDesktop(960);
        } else if (props.webSolutions) {
          return vwDesktop(897);
        } else {
          return vwDesktop(1105);
        }
      }};
    }
  }
`;

const CardContainer = styled.div`
  padding-bottom: ${vw(80)};
  @media (min-width: ${media.tablet}) {
    padding-bottom: ${vwTablet(160)};
    /* display: ${props => (props.webSolutions ? 'block' : 'grid')}; */
    grid-template-columns: ${vwTablet(260)} ${vwTablet(260)};
    justify-content: space-between;
    grid-row-gap: ${vwTablet(160)};
  }
  @media (min-width: ${media.desktop}) {
    display: ${props => (props.webSolutions ? 'block' : 'grid')};
    padding-bottom: ${vwDesktop(160)};
    grid-template-columns: ${vwDesktop(340)} ${vwDesktop(340)};
    grid-row-gap: ${vwDesktop(80)};
  }
`;
