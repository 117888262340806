import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import Button from 'components/Button';

import data from 'src/data/homeServices';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Services = props => {
  const { history } = props;
  return (
    <Root name="services">
      <h6>Our Services</h6>
      <h1>What we do best.</h1>
      <Wrapper>
        {data.map((item, index) => (
          <Service
            key={index}
            color={item.color}
            index={index}
            onClick={() => history.push(item.path)}
          >
            <h3 className="display-outline">0{index + 1}</h3>
            <div className="content">
              <h2>{item.title}</h2>
              <p>{item.description}</p>
              <Button
                as="button"
                color={item.color}
                label="Learn More"
              ></Button>
            </div>
          </Service>
        ))}
      </Wrapper>
    </Root>
  );
};

export default withRouter(Services);

const Root = styled.div`
  margin-top: ${vw(208)};
  padding-top: ${vw(40)};
  h6 {
    color: ${({ theme }) => theme.color.red};
    text-transform: uppercase;
    padding: 0 ${vw(16)};
  }
  h1 {
    margin: ${vw(10)} 0 ${vw(40)};
    padding: 0 ${vw(16)};
  }
  p {
    margin: ${vw(10)} 0 ${vw(20)};
  }
  a > h6 {
    color: #ffffff;
  }
  @media (min-width: ${media.tablet}) {
    margin-top: ${vwTablet(452)};
    padding: ${vwTablet(80)} ${vwTablet(30)} 0;
    h6 {
      padding: 0;
    }
    h1 {
      margin: ${vwTablet(20)} 0 ${vwTablet(80)};
      padding: 0;
    }
    p {
      margin: ${vwTablet(20)} 0 ${vwTablet(40)};
    }
  }
  @media (min-width: ${media.desktop}) {
    margin-top: ${vwDesktop(320)};
    padding: ${vwDesktop(80)} ${vwDesktop(80)} 0;
    h1 {
      margin: ${vwDesktop(20)} 0 ${vwDesktop(80)};
    }
    p {
      margin: ${vwDesktop(20)} 0 ${vwDesktop(40)};
    }
  }
`;

const Service = styled.div`
  margin-bottom: ${vw(40)};
  padding: 0 ${vw(16)};
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.5s ease-out;
  }
  .image {
    width: 100%;
    height: ${vw(320)};
    position: relative;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: ${vw(60)};
      background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        white
      );
    }
  }
  .content {
    z-index: 2;
    position: relative;
    margin-top: ${vw(10)};
  }
  h2 {
    max-width: ${props => (props.index === 0 ? '65%' : '75%')};
  }
  .display-outline {
    -webkit-text-stroke-color: ${props => props.color};
  }
  @media (min-width: ${media.tablet}) {
    margin-bottom: ${vwTablet(80)};
    position: relative;
    padding: 0;
    .image {
      position: absolute;
      top: 0;
      left: 0;
      width: ${vwTablet(400)};
      height: ${vwTablet(400)};
      &::after {
        height: ${vwTablet(60)};
      }
    }
    .content {
      padding: 0;
      margin-top: ${vwTablet(20)};
    }
    h2 {
      max-width: none;
      br {
        display: none;
      }
    }
  }
  @media (min-width: ${media.desktop}) {
    display: block;
    width: ${vwDesktop(373)};
    margin-bottom: ${vwDesktop(160)};
    .image {
      position: relative;
      width: 100%;
      height: ${vwDesktop(373)};
      overflow: hidden;
      cursor: pointer;
      &::after {
        display: none;
      }
      &:hover img {
        transform: scale(1.05);
      }
    }

    .content {
      width: 100%;
      padding: 0;
      margin-top: ${vwDesktop(20)};
    }
    h2 {
      br {
        display: block;
      }
    }
  }
`;

const Wrapper = styled.div`
  @media (min-width: ${media.desktop}) {
    display: flex;
    justify-content: space-between;
  }
`;
