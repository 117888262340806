import React from 'react';
import theme from 'src/styles/theme';

export default [
  {
    title: 'Design & Engineering',
    image: require('src/assets/images/home/experience-design-websites.jpg'),
    description:
      'Our beautifully designed solutions put the user experience first, without sacrificing conversion goals.',
    color: theme.color.orange,
    path: '/digital-solutions',
  },
  {
    title: (
      <>
        Digital
        <br /> Marketing
      </>
    ),
    image: require('src/assets/images/home/presentation-centre-touchscreens.jpg'),
    description:
      'Next gen marketing strategy backed by full cycle prospect data and insights from first engagement to conversion.',
    color: theme.color.green,
    path: '/digital-marketing',
  },
  {
    title: (
      <>
        Data
        <br /> Intelligence
      </>
    ),
    image: require('src/assets/images/home/presentation-centre-touchscreens.jpg'),
    description:
      'Eliminate assumptions every step of the way with our in-house analytics software to maximize efficiency and results.',
    color: theme.color.purple,
    path: '/data-intelligence',
  },
];
