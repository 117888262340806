import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { vw } from 'src/styles/utils';
// import FeaturedProject from 'components/FeaturedProject';
// import Cursor from 'components/CustomCursor';

const NextProject = ({ project }) => (
  <Root>
    {/* <Cursor click> */}
    {/* <FeaturedProject project={project} nextProject /> */}
    {/* </Cursor> */}
  </Root>
);

NextProject.propTypes = {
  project: PropTypes.object,
};

export default NextProject;

const Root = styled.div`
  margin: 0 auto;
  padding-top: ${vw(80)};
  margin-bottom: -2%;
`;
