import React from 'react';
import styled from 'styled-components';

import Container from 'components/Container';

import data from 'src/data/approach';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Approach = () => {
  return (
    <Root name="approach">
      <Container>
        <h6>Our Approach</h6>
        <h1>Your trusted digital partner.</h1>
        <Wrapper>
          {data.map((item, index) => (
            <Item key={index}>
              <h3 className="display-outline">0{index + 1}</h3>
              <h2>{item.title}</h2>
              <p>{item.description}</p>
            </Item>
          ))}
        </Wrapper>
      </Container>
    </Root>
  );
};

export default Approach;

const Root = styled.div`
  background-color: black;
  padding: ${vw(80)} 0 ${vw(40)};
  color: white;
  h6 {
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.red};
  }
  h2 {
    margin: ${vw(10)} 0;
  }
  h1 {
    margin: ${vw(10)} 0 ${vw(40)};
  }
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(160)} 0 ${vwTablet(80)};
    h2 {
      margin: ${vwTablet(20)} 0;
    }
    h1 {
      margin: ${vwTablet(20)} 0 ${vwTablet(80)};
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: ${vwDesktop(160)} 0;
    h2 {
      margin: ${vwDesktop(20)} 0;
    }
    h1 {
      margin: ${vwDesktop(20)} 0 ${vwDesktop(80)};
    }
  }
`;

const Item = styled.div`
  margin-bottom: ${vw(40)};
  @media (min-width: ${media.tablet}) {
    margin-bottom: ${vwTablet(80)};
  }
  @media (min-width: ${media.desktop}) {
    margin-bottom: 0;
    width: ${vwDesktop(373)};
  }
`;
const Wrapper = styled.div`
  @media (min-width: ${media.desktop}) {
    display: flex;
    justify-content: space-between;
  }
`;
