import React from 'react';
import styled from 'styled-components';

import Hero from 'components/Panels/Hero';
import ProjectDetails from 'components/Panels/ProjectDetails';
// import A from 'components/Panels/A';
import J from 'components/Panels/J';
import E from 'components/Panels/E';
// import I from 'components/Panels/I';
// import C from 'components/Panels/C';

const Spire = () => (
  <Root>
    <Hero
      image={require('src/assets/images/projects/spire/spire_herobanner.jpg')}
      title="Spire"
    />
    <ProjectDetails
      title="Live inspired."
      description="Sculpted with clean, contemporary lines and a palette of rich materials, Spire reflects sophistication and urban comfort in every sense. A slender architectural masterpiece, this is Seattle’s latest landmark in the making."
      location="Seattle, WA"
      client="Laconia LLC &amp; Vanke Corporation"
      product="352 Unit, 43 Storey Condominium"
      scope={['Responsive Website', 'Sales Centre iPad App']}
      website="https://www.spireseattle.com/"
    />
    {/* <A
      mediaType="gallery"
      media={[
        require('src/assets/images/projects/spire/spire_gallery_A_01.jpg'),
        require('src/assets/images/projects/spire/spire_gallery_A_02.jpg'),
        require('src/assets/images/projects/spire/spire_gallery_A_03.jpg'),
      ]}
    /> */}
    <J
      text="A brilliant architectural prism at the intersection of downtown Seattle’s most preferred urban neighborhoods."
      image={require('src/assets/images/projects/spire/spire_J2.jpg')}
    />
    <E
      laptop
      backgroundType="image"
      backgroundImage={require('src/assets/images/projects/spire/spire_E3_background.jpg')}
      mediaType="scroll"
      media={require('src/assets/images/projects/spire/spire_E3_laptopmockup.jpg')}
    />
    {/* <C
      largeImage={require('src/assets/images/projects/spire/spire_C3.jpg')}
      text="A showcase on next-generation thinking anticipates far more than just your arrival."
    /> */}
    {/* <I
      src={require('src/assets/images/projects/spire/Spire-360-view-small.jpg')}
      room
      is360
    /> */}
    <E
      ipad
      media={require('src/assets/images/projects/spire/spire_E1_ipad_image.jpg')}
      leftText
      text="Digital sales tools that leverage the power of innovation to accommodate a seamless sales process."
      backgroundType="solid"
      backgroundColor="white"
    />
  </Root>
);

export default Spire;

const Root = styled.div``;
