import React from 'react';
import styled from 'styled-components';

import PageTemplate from 'components/PageTemplate/PageTemplate';

import theme from 'src/styles/theme';
import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';

import features from 'src/data/touchscreenFeatures';
import caseStudies from 'src/data/touchscreenCaseStudies';
import clients from 'src/data/touchscreenClients';

function Touchscreen() {
  return (
    <Root>
      <PageTemplate
        featureColor={theme.color.orange}
        pageTitle="Touchscreens"
        hero={{
          title: `An integrated sales app that delivers
          an immersive customer experience 
          and boosts sales.`,
          body: `Intuitive touchscreen, iPad designs, and technology that equip sales and marketing teams to connect and sell anytime, anywhere.`,
          imageFront: {
            mobile: require('src/assets/images/touchscreen/touchscreen-hero-desktop.png'),
            desktop: require('src/assets/images/touchscreen/touchscreen-hero-desktop.png'),
          },
          imageSize: {
            mobile: { height: 401, width: 240 },
            tablet: { height: 763.6, width: 450 },
            desktop: { height: 1049, width: 633 },
          },
          imagePosition: {
            mobile: { bottom: -173, right: 10 },
            tablet: { bottom: -329.3, right: 10 },
            desktop: { bottom: -579, right: 60 },
          },
        }}
        intro={{
          subheader: 'Our Expertise',
          title: (
            <>
              Experiential designs
              <br /> and technology.
            </>
          ),
          body: `Captivate your customers with an intuitive, interactive experience. We design unique touchscreen and iPad experiences to showcase all of your property’s details. Connect internationally and improve your ability to sell anytime, anywhere.`,
          buttonLabel: 'Learn How',
        }}
        features={features}
        tagline="Features"
        caseStudies={{
          subheader: 'Get Results',
          title: 'Create a powerful impact with customers.',
          body: `Touchscreens and iPad Apps have become powerful sales tools in today’s challenging real estate market. Stand out when showcasing your properties and put the power of this experience in your customers’ hands.`,
          caseStudies,
          buttonLink: true,
          buttonLabel: 'Book a Demo',
        }}
        clients={clients}
      ></PageTemplate>
    </Root>
  );
}

export default Touchscreen;

const Root = styled.div`
  .intro-container {
    padding-top: ${vw(195)};
  }
  @media (min-width: ${media.tablet}) {
    .hero-h1 {
      width: 94%;
    }
    .hero-container {
      padding-top: ${vwTablet(240)};
    }
    .intro-container {
      padding-top: ${vwTablet(300)};
    }
  }
  @media (min-width: ${media.desktop}) {
    .hero-h1 {
      width: ${vwDesktop(1080)};
    }
    .hero-container {
      padding-top: ${vwDesktop(220)};
      p {
        width: ${vwDesktop(580)};
      }
    }
    .intro-container {
      padding-top: ${vwDesktop(160)};
      p {
        width: ${vwDesktop(560)};
      }
      h1 {
        width: ${vwDesktop(593)};
      }
    }
    .case-studies-container {
      h1 {
        width: ${vwDesktop(689)};
      }
    }
  }
`;
