import React from 'react';
import styled from 'styled-components';

import Header from 'components/Header/Header';
import UnderlineText from 'components/Typography/UnderlineText';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';
import theme from 'src/styles/theme';

const NotFound = () => {
  return (
    <Root>
      <Header />
      <h1 className="mobile-hero">404</h1>
      <UnderlineText text="Back to Home" color={theme.color.red} to="/" />
    </Root>
  );
};

export default NotFound;

const Root = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: black;
  color: white;
  padding: ${vw(300)} ${vw(16)};
  h1 {
    margin-bottom: ${vw(20)};
  }
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(600)} ${vwTablet(30)};
    h1 {
      margin-bottom: ${vwTablet(40)};
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: ${vwDesktop(400)} ${vwDesktop(80)};
    h1 {
      margin-bottom: ${vwDesktop(40)};
    }
  }
`;
