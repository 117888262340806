export default [
  {
    title: 'Centra',
    path: '/centra',
  },
  {
    title: 'Spire',
    path: '/spire',
  },
  {
    title: 'The Holland',
    path: '/the-holland',
  },
];
