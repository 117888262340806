import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

import social from 'src/data/social';

const Social = () => {
  return (
    <Root>
      <div>
        {social.map((item, index) => (
          <a
            href={item.to}
            target="_blank"
            rel="noopener noreferrer"
            key={index}
          >
            <img src={item.src} alt="" />
          </a>
        ))}
      </div>
      <p>
        &copy; Bam Digital 2021.
        <br /> <Link to="/terms">Terms of Use and Privacy Policy</Link>
      </p>
    </Root>
  );
};

export default Social;

const Root = styled.div`
  margin-left: ${vw(24)};
  a {
    margin-right: ${vw(40)};
    color: ${({ theme }) => theme.color.grey2} !important;
  }
  p {
    font-size: ${vw(11)};
    font-weight: 500;
    line-height: 1.64;
    color: ${({ theme }) => theme.color.grey2};
    margin-top: ${vw(40)};
  }
  @media (min-width: ${media.tablet}) {
    margin-left: ${vwTablet(122)};
    a {
      margin-right: ${vwTablet(40)};
    }
    p {
      font-size: ${vwTablet(11)};
      margin-top: ${vwTablet(40)};
      br {
        display: none;
      }
    }
    img {
      width: ${vwTablet(22)};
      height: ${vwTablet(22)};
    }
  }
  @media (min-width: ${media.desktop}) {
    margin-left: 0;
    display: flex;
    a {
      margin-right: ${vwDesktop(40)};
    }
    p {
      font-size: ${vwDesktop(11)};
      margin-top: 0;
      margin-left: ${vwDesktop(359)};
    }
    img {
      width: ${vwDesktop(22)};
      height: ${vwDesktop(22)};
    }
  }
`;
