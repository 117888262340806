import React from 'react';
import styled from 'styled-components';

import Hero from 'components/Panels/Hero';
import ProjectDetails from 'components/Panels/ProjectDetails';
import A from 'components/Panels/A';
import G from 'components/Panels/G';
import E from 'components/Panels/E';
import J from 'components/Panels/J';

const Centra = () => (
  <Root>
    <Hero
      image={require('src/assets/images/projects/centra/centra_herobanner.jpg')}
      title="Centra"
    />
    <ProjectDetails
      title="Urban energy, suburban serenity."
      description="The vibrant energy of the city coupled with the peace and quiet of the suburbs, Centra offers a connected location, views in every direction, spacious interiors, and practical amenities that come together to give you homes with real value."
      location="Surrey, BC"
      client="Everest Group of Companies"
      product="167 Unit, 24 Storey Condominiums and Townhomes"
      scope={['Responsive Website', 'Sales Centre Touchscreen App']}
      website="http://centrasurrey.com/"
    />
    <A
      mediaType="image"
      media={[require('src/assets/images/projects/centra/centra_A_01.jpg')]}
    />
    <G
      deviceImage={require('src/assets/images/projects/centra/centra_G_mobile_mockup.jpg')}
      backgroundImage={require('src/assets/images/projects/centra/centra_G_sideimage.jpg')}
      rightImage
    />
    <E
      laptop
      backgroundColor="#f7f5f1"
      backgroundType="solid"
      mediaType="scroll"
      media={require('src/assets/images/projects/centra/centra_E_laptop_mockup.jpg')}
      text="Designed with the homebuyer’s journey in mind, the website helps educate and convert potential customers into registrants."
      leftText
    />
    <J
      image={require('src/assets/images/projects/centra/centra_J.jpg')}
      text="Leveraging the power of innovation, our real-time availability feature accomodates a seamless sales process."
      leftImage
    />
  </Root>
);

export default Centra;

const Root = styled.div``;
