import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Logo from './elements/Logo';
import Menu from './elements/Menu';
import CTA from './elements/CTA';
import DesktopMenu from './elements/DesktopMenu';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';
import theme from 'src/styles/theme';

const Header = props => {
  const { color, menu } = props;

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = e => {
    if (e.srcElement.scrollingElement.scrollTop > 50) {
      return setHasScrolled(true);
    } else {
      return setHasScrolled(false);
    }
  };

  return (
    <>
      <Root>
        <Link to="/">
          <Logo hasScrolled={hasScrolled} />
        </Link>
        <Wrapper>
          <DesktopMenu menu={menu} hasScrolled={hasScrolled} />
          <CTA color={color} menu={menu} fixed />
          <Icon menu={menu} onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <Hamburger
              src={require('src/assets/images/icons/hamburger-white.svg')}
              alt="Menu"
              active={!isMenuOpen}
            />
            <Close
              src={require('src/assets/images/icons/close-white.svg')}
              alt="menu"
              active={isMenuOpen}
            />
          </Icon>
        </Wrapper>
      </Root>
      <Menu active={isMenuOpen} closeMenu={() => setIsMenuOpen(false)} />
    </>
  );
};

Header.propTypes = {
  color: PropTypes.string,
  menu: PropTypes.bool,
};

Header.defaultProps = {
  menu: false,
  color: theme.color.red,
};

export default Header;

const Root = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 15;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${vw(16)};
  position: fixed;
  top: 0;
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(30)};
  }
  @media (min-width: ${media.desktop}) {
    padding: ${vwDesktop(30)} ${vwDesktop(80)};
  }
`;

const Wrapper = styled.div`
  @media (min-width: ${media.tablet}) {
    display: flex;
    align-items: center;
    height: fit-content;
  }
`;

const Hamburger = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${props => (props.active ? 1 : 0)};
  transform: ${props => (props.active ? 'scaleX(1)' : 'scaleX(-1)')};
  transition: 0.3s ease;
`;

const Close = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${props => (props.active ? 1 : 0)};
  transform: ${props => (props.active ? 'scaleX(1)' : 'scaleX(-1)')};
  transition: 0.3s ease;
`;

const Icon = styled.div`
  width: ${vw(20)};
  height: ${vw(20)};
  display: ${props => (props.menu ? 'block' : 'none')};
  position: fixed;
  top: ${vw(26)};
  right: ${vw(16)};
  @media (min-width: ${media.tablet}) {
    width: ${vwTablet(24)};
    height: ${vwTablet(24)};
    margin-left: ${vwTablet(40)};
    top: ${vwTablet(48)};
    right: ${vwTablet(30)};
  }
  @media (min-width: ${media.desktop}) {
    display: none;
  }
`;
