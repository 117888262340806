import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

class DragScroll extends Component {
  constructor(props) {
    super(props);
    this.$dragScroll = React.createRef();
  }

  componentDidMount() {
    this.handleDragScroll();
  }

  handleDragScroll = () => {
    // Comes from https://github.com/asvd/dragscroll but refactored to use a ref instead of classnames
    const _window = window;
    const _document = document;
    const mousemove = 'mousemove';
    const mouseup = 'mouseup';
    const mousedown = 'mousedown';
    const EventListener = 'EventListener';
    const addEventListener = 'add' + EventListener;
    const removeEventListener = 'remove' + EventListener;
    let newScrollX, newScrollY;
    let dragged = [];
    var reset = function(i, el) {
      for (i = 0; i < dragged.length; ) {
        el = dragged[i++];
        el = el.container || el;
        el[removeEventListener](mousedown, el.md, 0);
        _window[removeEventListener](mouseup, el.mu, 0);
        _window[removeEventListener](mousemove, el.mm, 0);
      }
      (function(el, lastClientX, lastClientY, pushed, scroller, cont) {
        ReactDOM.findDOMNode(this.$dragScroll.current).addEventListener(
          mousedown,
          function(e) {
            let image = document.getElementById('scroll-image');
            image.style.transform = 'translateY(0)';
            if (
              !el.hasAttribute('nochilddrag') ||
              _document.elementFromPoint(e.pageX, e.pageY) === cont
            ) {
              pushed = 1;
              lastClientX = e.clientX;
              lastClientY = e.clientY;
              e.preventDefault();
            }
          },
          0
        );
        _window[addEventListener](
          mouseup,
          function() {
            pushed = 0;
          },
          0
        );
        _window[addEventListener](
          mousemove,
          function(e) {
            if (pushed) {
              (scroller = el.scroller || el).scrollLeft -= newScrollX =
                -lastClientX + (lastClientX = e.clientX);
              scroller.scrollTop -= newScrollY =
                -lastClientY + (lastClientY = e.clientY);
              if (el === _document.body) {
                (scroller = _document.documentElement).scrollLeft -= newScrollX;
                scroller.scrollTop -= newScrollY;
              }
            }
          },
          0
        );
      }.bind(this)(ReactDOM.findDOMNode(this.$dragScroll.current)));
    }.bind(this);
    reset();
  };

  render() {
    return (
      <Root {...this.props} ref={this.$dragScroll}>
        {this.props.children}
      </Root>
    );
  }
}

export default DragScroll;

const Root = styled.div``;
