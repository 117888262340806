import React from 'react';
import styled from 'styled-components';

import Hero from 'components/Panels/Hero';
import ProjectDetails from 'components/Panels/ProjectDetails';
import A from 'components/Panels/A';
import G from 'components/Panels/G';
import E from 'components/Panels/E';
import C from 'components/Panels/C';
import Results from 'components/Panels/Results';

const GryphonDevelopment = () => (
  <Root>
    <Hero
      image={require('src/assets/images/projects/gryphon/gryphon_herobanner2.jpg')}
      title="Gryphon Development"
      veryRight
    />
    <ProjectDetails
      title="Art lives here."
      description="Gryphon Development values art is at the core of everything they do. Art challenges our expectations and reflects the way we see the world. For art to make a significant impact on the way we shape community, we must first re-imagine its role in the way we craft our homes."
      client="Gryphon Development"
      scope={['Responsive Website', 'Data Strategy']}
      website="https://gryphonliving.com/"
    />
    <A
      mediaType="image"
      media={[require('src/assets/images/projects/gryphon/gryphon_A.jpg')]}
    />
    <G
      deviceImage={require('src/assets/images/projects/gryphon/gryphon_G_mobile_mockup1.jpg')}
      backgroundImage={require('src/assets/images/projects/gryphon/gryphon_G_sideimage1.jpg')}
      rightImage
    />
    <E
      laptop
      backgroundType="image"
      backgroundImage={require('src/assets/images/projects/gryphon/gryphon_E_background.jpg')}
      mediaType="scroll"
      media={require('src/assets/images/projects/gryphon/gryphon_E_laptop_mockup.jpg')}
    />
    <G
      deviceImage={require('src/assets/images/projects/gryphon/gryphon_G_mobile_mockup2.jpg')}
      backgroundImage={require('src/assets/images/projects/gryphon/gryphon_G_sideimage2.jpg')}
    />
    <C
      largeImage={require('src/assets/images/projects/gryphon/gryphon_C.jpg')}
      text={`Gryphon creates “living art” by nurturing and supporting visionary artists committed to shaping the future of living.`}
      largeRightPanel
    />
    <Results
      text="Gryphon wanted to ensure their website reflected their unique approach and philosophy. Through bold design, branding storytelling, and intuitive navigation, we capture the connection between art and home development. Collecting data on different scenarios provided the information we needed to deliver a website that allows visitors to feel emotionally connected with the company values and grow social engagement in the community."
      results={[
        {
          title: 'average session duration',
          point: '1m 54s',
          subPoint: '(+409%)',
        },
        {
          title: 'bounce rate',
          point: '35%',
          subPoint: '(-61%)',
        },
        {
          title: 'pages per session',
          point: '3.25',
          subPoint: '(+177%)',
        },
      ]}
    />
  </Root>
);

export default GryphonDevelopment;

const Root = styled.div``;
