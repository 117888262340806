import React from 'react';
import styled from 'styled-components';

import Explore from 'components/Explore';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import UnderlineText from 'components/Typography/UnderlineText';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';
import theme from 'src/styles/theme';

const Terms = () => {
  return (
    <Root>
      <Header />
      <Hero>
        <h1 className="mobile-hero">
          Terms of Use &amp;
          <br />
          Privacy Policy
        </h1>
        <Explore negativeBottom />
      </Hero>
      <Wrapper>
        <h2>Terms of Use</h2>
        <p>
          The material, images, renderings and information on this website is
          provided on an ‘as is’ basis solely for the reader’s general
          knowledge. The information is not intended to be a comprehensive
          review of all matters and developments concerning Bam Digital, and
          therefore assumes no responsibility and provides no warranty, either
          express or implied, as to its accuracy, completeness or currency. In
          no event will Bam Digital be liable for any special, incidental,
          indirect or consequential damages of any kind, or any damages
          whatsoever arising out of or in connection with the use or performance
          of this information, including without limitation, those resulting
          from loss of use, data or profits, whether or not advised of the
          possibility of damage. This site may contain links to external
          organizations. We do not control the relevance, timeliness, or
          accuracy of materials produced by other organizations, nor do we
          endorse their views, products or services. Your access and use of this
          website constitutes your agreement to be governed by the contents of
          the disclaimer set forth above.
        </p>
        <h2>Privacy Policy</h2>
        <p>
          Bam Digital and each of our affiliates and partnerships is committed
          to maintaining the accuracy, confidentiality and security of your
          personal information. This Privacy Policy describes the personal
          information that Bam Digital collects from or about you, how we use
          that information, and to whom we disclose that information.
        </p>
        <h4>Collecting Personal Information</h4>
        <p>
          Bam Digital collects personal information to better serve your needs,
          whether purchasing products and services from us or obtaining service
          or warranty work. In order to provide you with our wide variety of
          services, products and promotions, we may collect the following
          information:
        </p>
        <ul>
          <li>
            Contact and mailing information such as name, address, telephone
            numbers, fax numbers and email addresses.
          </li>
          <li>
            The plans, specifications and other particulars about the home that
            is being built for you.
          </li>
          <li>Municipal and legal descriptions for your home.</li>
          <li>
            Any other relevant information necessary to provide you with our
            products and services.
          </li>
          <li>
            We may need to collect other personal information depending on the
            circumstance, but we will ask you directly and ensure that you
            agree.
          </li>
        </ul>
        <h4>Personal Information Security</h4>
        <p>
          Like you, Bam Digital values our personal information. Part of valuing
          your personal information is making sure that it is protected and kept
          confidential. We achieve this by:
        </p>
        <ul>
          <li>Having our employees sign a confidentiality agreement.</li>
          <li>
            Having up to date computer security such as passwords, encryption
            and firewalls.
          </li>
          <li>
            Where third parties are hired to assist us with providing products
            and services to you for your home, we have them sign a privacy and
            confidentiality agreement to ensure that your personal information
            is protected by them.
          </li>
        </ul>
        <h4>Personal Information Updating</h4>
        <p>
          Bam Digital wants to ensure that your personal information is always
          correct up to date. If your personal information changes or is
          incorrect, updates can be made by contacting your representative with
          the change and any other information you feel is necessary.
          <br />
          <br />
          We will keep your information as long as is necessary to continue to
          meet your customer needs and any legal requirements we may have.
          However, once your personal information no longer needed, we will
          destroy it.
        </p>
        <h4>Personal Data</h4>
        <p>
          We only collect Personal Data from you, such as first and last name,
          phone number, e-mail, when you register to receive information. We
          collect data about your activities that does not personally or
          directly identify you when you visit our website, or the websites and
          online services where we display advertisements. This information may
          include the content you view, the date and time that you view this
          content, the products you purchase, or your location information
          associated with your IP address. We use the information we collect to
          serve you more relevant advertisements (referred to as “Retargeting”).
          We collect information about where you saw the ads we serve you and
          what ads you clicked on.
          <br />
          <br />
          As you browse the website, online ad networks we work with may place
          anonymous cookies on your computer, and use similar technologies, in
          order to understand your interests based on your (anonymous) online
          activities, and thus to tailor more relevant ads to you. If you do not
          wish to receive such tailored advertising, you can visit this page to
          opt out of most companies that engage in such advertising. (This will
          not prevent you from seeing ads; the ads simply will not be delivered
          through these targeting methods.)
          <br />
          <br />
          We do not target ads to you based on sensitive personal data, such as
          information related to race or ethnic origins, political opinions,
          religious beliefs or other beliefs of a similar nature, trade union
          membership, physical or mental health or condition or sexual life. We
          may target ads based on data provided by Advertisers or partners alone
          or in combination with the data we collect ourselves. Any data used to
          serve targeted advertisements is de-identified and is not used to
          personally or directly identify you. In order to allow us to reach the
          best inventory online, we work with third-party advertising companies
          (our “Advertising Partners”) to help us recognize you and serve
          relevant advertisements to you when you visit a website or online
          service in their network. We also work with Advertising Partners who
          help us recognize you across different devices in order to show you
          relevant advertisements. Our Advertising Partners may collect
          information about your activities on our website, and other websites
          or online services in their networks. We also work with third party
          companies to assist us with website analytics such as evaluating the
          use and operation of our website so that we can continue to enhance
          the website and our services.
          <br />
          <br />
          We and our third-party partners use session cookies and persistent
          cookies to make it easier for you to navigate and enhance the
          experience of our site, and to monitor and evaluate our website’s
          operation and use.
          <br />
          <br />
          To modify or delete your personal information, or to opt out of future
          communications, please contact us at:
          <br />
          <br />
          Bam Digital
          <br />
          2060 Pine Street
          <br />
          Vancouver, British Columbia
          <br />
          V6J 4P8 Canada
          <br />
          <br />
          Email: <a href="mailto:info@bamdigital.com">info@bamdigital.com</a>
        </p>
        <UnderlineText
          text="Back to Home"
          black
          color={theme.color.red}
          to="/"
        />
      </Wrapper>
      <Footer />
    </Root>
  );
};

export default Terms;

const Root = styled.div``;

const Hero = styled.div`
  background-color: black;
  height: 100vh;
  width: 100vw;
  position: relative;
  color: white;
  padding: ${vw(220)} ${vw(16)};
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(400)} ${vwTablet(30)};
  }
  @media (min-width: ${media.desktop}) {
    padding: ${vwDesktop(260)} ${vwDesktop(80)};
  }
`;

const Wrapper = styled.div`
  padding: ${vw(80)} ${vw(16)};
  h2 {
    margin-bottom: ${vw(20)};
  }
  p {
    margin-bottom: ${vw(40)};
  }
  h4 {
    margin-bottom: ${vw(10)};
  }
  ul {
    margin-top: ${vw(-30)};
  }
  a {
    color: black;
  }
  li {
    font-family: ${({ theme }) => theme.font.main};
    font-size: ${vw(14)};
    font-weight: 300;
    line-height: 1.57;
    letter-spacing: ${vw(0.5)};
    list-style-type: none;
    display: flex;
    margin-bottom: ${vw(10)};
    &::before {
      content: '∙';
      margin-right: ${vw(10)};
    }
    &:last-of-type {
      margin-bottom: ${vw(40)};
    }
  }
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(160)} ${vwTablet(30)};
    h2 {
      margin-bottom: ${vwTablet(20)};
    }
    p {
      margin-bottom: ${vwTablet(80)};
    }
    h4 {
      margin-bottom: ${vwTablet(20)};
    }
    ul {
      margin-top: ${vwTablet(-60)};
    }
    li {
      font-size: ${vwTablet(18)};
      line-height: ${vwTablet(30)};
      letter-spacing: ${vwTablet(0.5)};
      margin-bottom: ${vwTablet(10)};
      &::before {
        margin-right: ${vwTablet(20)};
      }
      &:last-of-type {
        margin-bottom: ${vwTablet(80)};
      }
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: ${vwDesktop(160)} ${vwDesktop(298)};
    h2 {
      margin-bottom: ${vwDesktop(20)};
    }
    p {
      margin-bottom: ${vwDesktop(80)};
    }
    h4 {
      margin-bottom: ${vwDesktop(20)};
    }
    ul {
      margin-top: ${vwDesktop(-60)};
    }
    li {
      font-size: ${vwDesktop(18)};
      line-height: ${vwDesktop(30)};
      letter-spacing: ${vwDesktop(0.5)};
      margin-bottom: ${vwDesktop(10)};
      &::before {
        margin-right: ${vwDesktop(20)};
      }
      &:last-of-type {
        margin-bottom: ${vwDesktop(80)};
      }
    }
  }
`;
