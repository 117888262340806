// Constants
export const PROJECT__TRANSITION_IN = 'PROJECT::TRANSITION_IN';
export const PROJECT__TRANSITION_OUT = 'PROJECT::TRANSITION_OUT';

// Actions
export const transitionIn = background => ({
  type: PROJECT__TRANSITION_IN,
  background,
});

export const transitionOut = () => ({
  type: PROJECT__TRANSITION_OUT,
});

// Initial State
const initialState = {
  showFullBG: false,
  background: '',
};

// Reducer
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case PROJECT__TRANSITION_IN:
      return {
        ...state,
        showFullBG: true,
        background: action.background,
      };
    case PROJECT__TRANSITION_OUT:
      return {
        ...state,
        showFullBG: false,
      };
    default:
      return state;
  }
};
