import React from 'react';
import styled from 'styled-components';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Container = props => {
  const { children } = props;
  return <Root {...props}>{children}</Root>;
};

export default Container;

const Root = styled.div`
  padding: 0 ${vw(16)};
  @media (min-width: ${media.tablet}) {
    padding: 0 ${vwTablet(30)};
  }
  @media (min-width: ${media.desktop}) {
    padding: 0 ${vwDesktop(80)};
  }
`;
