export default [
  {
    src: require('src/assets/images/icons/instagram.svg'),
    to: 'https://www.instagram.com/bamdigitalagency/',
    title: 'Instagram',
  },
  {
    src: require('src/assets/images/icons/facebook.svg'),
    to: 'https://www.facebook.com/bamdigitalagency/',
    title: 'Facebook',
  },
  {
    src: require('src/assets/images/icons/linkedin.svg'),
    to: 'https://www.linkedin.com/company/bamdigitalagency/',
    title: 'Linked in',
  },
];
