import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { enableScroll, disableScroll } from 'src/redux/app';
import { transitionIn, transitionOut } from 'src/redux/project';

import MaskInUp from 'components/Animation/MaskInUp';
import WaypointTrigger from 'components/Animation/WaypointTrigger';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

class FeaturedProject extends Component {
  state = {
    isAnimated: false,
    isTransitionFull: false,
    isAllowTranstion: false,
  };

  static propTypes = {
    project: PropTypes.object,
    small: PropTypes.bool,
  };

  static defaultProps = {
    small: false,
  };

  constructor(props) {
    super(props);
    this.$node = React.createRef();
    this.$background = React.createRef();
  }

  componentDidMount() {
    let viewportOffset = this.$node.getBoundingClientRect();
    let position = {
      top: `${viewportOffset.top}px`,
      bottom: `${window.innerHeight - viewportOffset.bottom}px`,
      left: `${viewportOffset.left}px`,
      right: `${viewportOffset.left}px`,
    };
    this.setState({ position });
  }

  transitionBackground = () => {
    const viewportOffset = this.$node.getBoundingClientRect();
    const { project } = this.props;
    let position = {
      top: `${viewportOffset.top}px`,
      bottom: `${window.innerHeight - viewportOffset.bottom}px`,
      left: `${viewportOffset.left}px`,
      right: `${viewportOffset.right}px`,
      height: `${viewportOffset.height}px`,
      width: `${viewportOffset.width}px`,
    };

    this.$background.style.top = position.top;
    this.$background.style.bottom = position.bottom;
    this.$background.style.left = position.left;
    this.$background.style.right = position.right;
    this.$background.style.width = position.width;
    this.$background.style.height = position.height;

    setTimeout(() => {
      this.setState({
        isAllowTranstion: true,
      });
      this.$background.style.top = 0;
      this.$background.style.bottom = 0;
      this.$background.style.left = 0;
      this.$background.style.right = 0;
      this.$background.style.width = `100vw`;
      this.$background.style.height = '100vh';
      this.$background.style.transform = `scale(1.1)`;
    }, 500);

    setTimeout(() => {
      window.scrollTo({ top: 0 });
      this.props.disableScroll();
      this.props.transitionIn(project.img);
    }, 1000);

    setTimeout(() => {
      this.props.history.push(`/work/${project.id}`, {
        from: 'Home',
        path: '/',
      });
    }, 1500);
  };

  componentWillUnmount() {}

  handleEnter = () => {
    this.setState({ isAnimated: true });
  };

  handleLeave = () => {
    this.setState({ isAnimated: false });
  };

  handleClick = () => {
    this.setState({ isTransitionFull: true });
    this.transitionBackground();
  };

  render() {
    const { project, small, noMargin } = this.props;
    return (
      <>
        <WaypointTrigger topOffset="0%" bottomOffset="5%">
          {animate => (
            <>
              <BackgroundTransition
                ref={el => (this.$background = el)}
                active={this.state.isTransitionFull}
                position={this.state.position}
                startTransition={this.state.isAllowTranstion}
                background={project.img}
                right={project.right}
              />
              <MaskInUp animate={animate}>
                <Content
                  ref={el => (this.$node = el)}
                  onClick={this.handleClick}
                  background={project.img}
                  small={small}
                  noMargin={noMargin}
                >
                  <div className="background" />
                  {animate && (
                    <Caption>
                      <Title small={small}>{project.title}</Title>
                    </Caption>
                  )}
                </Content>
              </MaskInUp>
            </>
          )}
        </WaypointTrigger>
      </>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      enableScroll,
      disableScroll,
      transitionIn,
      transitionOut,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FeaturedProject));

const Content = styled.div`
  width: ${props => (props.small ? '100%' : '100%')};
  height: ${props => (props.small ? '100%' : '100%')};
  padding-top: ${vw(160)};
  top: 0;
  left: 0;
  background: url(${props => props.background});
  background-size: cover;
  background-position: 50% 50%;
  position: relative;
  margin-bottom: ${props => (props.nowMargin ? 0 : vw(20))};
  cursor: pointer;
  @media (min-width: ${media.tablet}) {
    padding-top: ${props => (props.small ? vwTablet(190) : vwTablet(400))};
    width: ${props => (props.small ? vwTablet(339) : '100%')};
    height: 100%;
    margin-bottom: ${props => (props.noMargin ? 0 : vwTablet(40))};
  }
  @media (min-width: ${media.desktop}) {
    width: ${props => (props.small ? vwDesktop(600) : vwDesktop(1000))};
    height: ${props => (props.small ? vwDesktop(325) : vwDesktop(375))};
    padding-top: ${props => (props.small ? vwDesktop(325) : vwDesktop(375))};
    margin: 0 auto;
    margin-bottom: ${props => (props.noMargin ? 0 : vwDesktop(80))};
  }
  &:hover {
    .background {
      opacity: 0;
    }
  }
  .background {
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.5;
    transition: 0.3s ease;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const Caption = styled.div`
  color: #fff;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: ${vw(20)};
  transform: translateY(-50%);
  height: auto !important;
  /* max-width: ${vw(230)}; */
  @media (min-width: ${media.tablet}) {
    left: ${vwTablet(20)};
    top: 50%;
  }
  @media (min-width: ${media.desktop}) {
    left: ${vwDesktop(40)};
    top: 50%;
  }
  p {
    font-size: ${vw(11)};
    color: white;
    @media (min-width: ${media.tablet}) {
      font-size: ${vwTablet(11)};
    }
    @media (min-width: ${media.desktop}) {
      font-size: ${vwDesktop(16)};
    }
  }
`;

const Title = styled.h3`
  font-size: ${vw(25)};
  letter-spacing: ${vw(0.5)};
  max-width: ${vw(180)};
  @media (min-width: ${media.tablet}) {
    font-size: ${props => (props.small ? vwTablet(28) : vwTablet(35))};
    letter-spacing: ${vwTablet(0.5)};
    max-width: ${props => (props.small ? vwTablet(200) : vwTablet(250))};
  }
  @media (min-width: ${media.desktop}) {
    font-size: ${vwDesktop(50)};
    letter-spacing: ${vwDesktop(0.5)};
    margin-bottom: ${vwDesktop(10)};
    max-width: ${vwDesktop(350)};
  }
`;

const BackgroundTransition = styled.div`
  position: fixed;
  opacity: ${props => (props.active ? 1 : 0)};
  pointer-events: none;
  background: #243d56;
  z-index: 999;
  transform-origin: 50% 50%;
  transition: all 0.5s;
  background: url(${props => props.background});
  background-size: cover;
  background-position: ${props => (props.right ? '75%' : '50% 50%')};
  @media (min-width: ${media.desktop}) {
    background-position: 50% 50%;
  }
`;
