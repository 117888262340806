import React from 'react';

export default [
  {
    title: 'Audience Intelligence',
    body: `We help you better understand and target your customers by considering multiple data points, including their social media and online behaviors, digital consumption, content preferences, opinions, and lifestyles.`,
    thumbnail: require('src/assets/images/digitalMarketing/browsing-phone.jpg'),
    image: require('src/assets/images/webSolutions/new-development-mock.jpg'),
    content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie. Mauris malesuada nisi sit amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie. Mauris malesuada nisi sit amet.`,
  },
  {
    title: (
      <>
        SEO &amp;
        <br /> Content Strategy
      </>
    ),
    body: `With an increasing sea of online content, you need to stand out. We help you cut through the noise with a digitally optimized content strategy designed to improve search engine ranking, deliver compelling content, and provide targeted audience messaging.`,
    thumbnail: require('src/assets/images/digitalMarketing/sparse-crowd.jpg'),
    image: require('src/assets/images/home/presentation-centre-touchscreens.jpg'),
    content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie. Mauris malesuada nisi sit amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie. Mauris malesuada nisi sit amet.`,
  },
  {
    title: 'Digital Ad Design',
    body: `Increase your brand awareness and customer reach with a dedicated digital ad campaign. We design creative and strategic digital ads across social, search, display, and in-app, that delivers ROI.`,
    thumbnail: require('src/assets/images/digitalMarketing/wireframes-sticky-notes.jpg'),
    image: require('src/assets/images/webSolutions/rental.jpg'),
    content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie. Mauris malesuada nisi sit amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie. Mauris malesuada nisi sit amet.`,
  },
];
