import React from 'react';
import styled from 'styled-components';

import Hero from 'components/Panels/Hero';
import ProjectDetails from 'components/Panels/ProjectDetails';

import E from 'components/Panels/E';
import G from 'components/Panels/G';
import J from 'components/Panels/J';
import Results from 'components/Panels/Results';

const WaltonLofts = () => (
  <Root>
    <Hero
      image={require('src/assets/images/projects/waltonLofts/walton_herobanner.jpg')}
      title="Walton Lofts"
      right={true}
    />
    <ProjectDetails
      title="Sophisticated living on the Seattle waterfront."
      description="Walton Lofts offers luxury living with stunning views, inspired design, and spacious floor plans with a coveted Belltown address. Seattle is known for its natural beauty and lively neighborhoods, and Walton Lofts’ apartments offer a front row seat to experience the best of it."
      location="Seattle, WA"
      client="Thrive Communities"
      product="12 Storey Rental Building"
      scope={['Responsive Website']}
      website="https://www.lifeatwalton.com/"
    />
    <J
      text="Walton Lofts’ spacious units feature high-end interiors and floor-to-ceiling windows — most with city or water views."
      image={require('src/assets/images/projects/waltonLofts/walton_C4.jpg')}
    />
    <E
      laptop
      backgroundType="image"
      backgroundImage={require('src/assets/images/projects/waltonLofts/walton_E_background.jpg')}
      mediaType="scroll"
      media={require('src/assets/images/projects/waltonLofts/walton_E_laptop_mockup.jpg')}
      text="A intuitive digital experience that simplifies a tenant’s online journey in finding their new home."
    />
    <G
      deviceImage={require('src/assets/images/projects/waltonLofts/walton_G_mobile_mockup.jpg')}
      backgroundImage={require('src/assets/images/projects/waltonLofts/walton_G_sideimage.jpg')}
    />
    <Results
      text="The new Walton Lofts website features bright colours, textures, and a positive use of white space making the site feel fresh, clean, and approachable. A crucial part of redesigning the website was improving the content structure and navigation. Focusing in the user flow we identified the pain-points in the home searching process and used the findings to create a new site architecture and navigation to enable easier browsing and a more frictionless experience."
      results={[
        {
          title: 'sessions',
          point: '+38%',
        },
        {
          title: 'bounce rate',
          point: '6.7%',
          subPoint: '(-81%)',
        },
        {
          title: 'phone inquiries',
          point: '+346%',
        },
      ]}
    />
  </Root>
);

export default WaltonLofts;

const Root = styled.div``;
