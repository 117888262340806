import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Logo = props => {
  const { hasScrolled } = props;
  return (
    <Root
      src={require('src/assets/images/logos/bam/bd.svg')}
      alt="Bam Digital"
      hasScrolled={hasScrolled}
    />
  );
};

Logo.propTypes = {
  hasScrolled: PropTypes.bool,
};

export default Logo;

const Root = styled.img`
  width: ${vw(102)};
  height: ${vw(40)};
  opacity: ${props => (props.hasScrolled ? 0 : 1)};
  transition: 0.3s ease;
  @media (min-width: ${media.tablet}) {
    width: ${vwTablet(148)};
    height: ${vwTablet(58)};
  }
  @media (min-width: ${media.desktop}) {
    width: ${vwDesktop(148)};
    height: ${vwDesktop(58)};
  }
`;
