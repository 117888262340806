import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import WaypointTrigger from 'components/Animation/WaypointTrigger';
import FadeUp from 'components/Animation/FadeUp';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const B = ({ text, image, textRight }) => (
  <WaypointTrigger>
    {animate => (
      <FadeUp animate={animate}>
        <Root>
          <Text textRight={textRight} className="white-background">
            <p>{text}</p>
          </Text>
          <Image image={image} textRight={textRight} />
        </Root>
      </FadeUp>
    )}
  </WaypointTrigger>
);

B.propTypes = {
  text: PropTypes.string,
  image: PropTypes.string,
  textRight: PropTypes.bool,
};

B.defaultProps = {
  textRight: false,
};

export default B;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${media.tablet}) {
    flex-direction: row;
  }
`;

const Text = styled.div`
  width: 100%;
  height: ${vw(220)};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${vw(14)};
  line-height: ${vw(22)};
  letter-spacing: ${vw(0.5)};
  font-weight: 300;
  text-align: center;
  order: ${props => (props.textRight ? 2 : 1)};
  background: url(${require('src/assets/images/white-background-large.jpg')});
  background-size: cover;
  background-position: center;
  @media (min-width: ${media.tablet}) {
    width: 50%;
    text-align: left;
    padding: ${vwDesktop(202)} 0;
    height: auto;
    font-size: ${vwTablet(15)};
    line-height: ${vwTablet(24)};
    letter-spacing: ${vwTablet(0.5)};
  }
  @media (min-width: ${media.desktop}) {
    font-size: ${vwDesktop(18)};
    line-height: ${vwDesktop(32)};
    letter-spacing: ${vwDesktop(1)};
  }
  p {
    width: 60.9%;
    @media (min-width: ${media.tablet}) {
      width: 58.3%;
    }
    @media (min-width: ${media.desktop}) {
      width: 41.7%;
    }
  }
`;

const Image = styled.div`
  width: 100%;
  height: ${vw(220)};
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  order: ${props => (props.textRight ? 1 : 2)};
  @media (min-width: ${media.tablet}) {
    width: 50%;
    height: auto;
  }
`;
