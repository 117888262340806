import React from 'react';
import styled from 'styled-components';

import { vw, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

// TITLE IN MOBILE IS ANCHORED TO THE BOTTOM OF THE IMAGE

const Image = props => {
  const { image, title, featureColor } = props;
  return (
    <Root title={title}>
      <img src={image} alt={title} />
      <Title featureColor={featureColor} className="mobile-tablet-only">
        <h2>{title}</h2>
      </Title>
    </Root>
  );
};

export default Image;

Image.defaultProps = {
  title: 'Missing Title',
};

const Root = styled.div`
  height: ${vw(320)};
  width: 100%;
  position: relative;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    display: block;
  }
  @media (min-width: ${media.tablet}) {
    height: auto;
    img {
      height: ${vwTablet(560)};
      width: ${vwTablet(560)};
    }
  }
  @media (min-width: ${media.desktop}) {
    width: 50%;
    img {
      height: 100vh;
      width: 50vw;
      object-position: center;
    }
  }
`;

const Title = styled.div`
  height: fit-content;
  width: 100%;
  padding: ${vw(24)} ${vw(16)} ${vw(20)};
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
  background-size: cover;
  position: absolute;
  bottom: 0;
  left: 0;
  h2::after {
    content: '';
    width: ${vw(40)};
    height: ${vw(2)};
    background-color: ${props => props.featureColor};
    position: absolute;
    top: ${vw(10)};
    left: ${vw(16)};
  }
  @media (min-width: ${media.tablet}) {
    position: relative;
    padding: ${vwTablet(62)} ${vwTablet(30)} ${vwTablet(40)} ${vwTablet(152)};
    background-image: unset;
    h2 {
      position: relative;
    }
    br {
      display: none;
    }
    h2::after {
      width: ${vwTablet(40)};
      height: ${vwTablet(2)};
      top: ${vwTablet(-20)};
      left: ${vwTablet(0)};
    }
  }
`;
