import React from 'react';
import styled from 'styled-components';

import PageTemplate from 'components/PageTemplate/PageTemplate';
import theme from 'src/styles/theme';

import features from 'src/data/rentalFeatures';
import caseStudies from 'src/data/rentalCaseStudies';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

function Rentals() {
  return (
    <Root>
      <PageTemplate
        featureColor={theme.color.orange}
        pageTitle="Rental Websites"
        hero={{
          title: 'Stand out in the crowded rental market.',
          body: `Elevate your digital presence with a customized website that delivers results.`,
          imageBack: {
            mobile: require('src/assets/images/rentals/rentals-hero-3-mobile.png'),
            desktop: require('src/assets/images/rentals/rentals-hero-1-desktop.png'),
          },
          imageFront: {
            mobile: require('src/assets/images/rentals/rentals-hero-scout-mobile.png'),
            desktop: require('src/assets/images/rentals/rentals-hero-scout-mobile.png'),
          },
        }}
        intro={{
          subheader: 'Our Expertise',
          title: (
            <>
              Optimizing the
              <br /> home-finding experience.
            </>
          ),
          body: `We specialize in creating easily-navigated rental property websites. Optimized for mobile use, we let your visitors quickly find, review, and compare available options. Our fully integrated, custom-designed websites support the rental inquiry process and leasing applications—delivering a smooth and impactful experience.`,
          buttonLabel: 'Learn How',
          largeText: true,
        }}
        features={features}
        tagline="Features"
        caseStudies={{
          subheader: 'Get results',
          title: `Add value and see the impact.`,
          body: `Bam Digital is data-driven and focused on results. Our custom designs and dedicated technology can better showcase your property and deliver results. We measure the value transformation and impact that the website has on your property, because we want you to see and feel the improvement in lead-conversion.`,
          caseStudies,
          buttonLink: true,
          buttonLabel: 'Book a Demo',
        }}
      ></PageTemplate>
    </Root>
  );
}

export default Rentals;

const Root = styled.div`
  .hero-container {
    overflow: hidden;
  }
  .case-study-image {
    margin-top: ${vw(40)};
  }
  @media (min-width: ${media.tablet}) {
    .case-study-image {
      margin-top: ${vwTablet(80)};
    }
  }

  @media (min-width: ${media.desktop}) {
    .hero-container {
      overflow: visible;
    }
    .case-studies-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      img {
        margin-top: unset;
        margin: unset;
      }
      > div,
      h1 {
        width: ${vwDesktop(515)};
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      > p {
        width: ${vwDesktop(734)};
      }
    }
  }
`;
