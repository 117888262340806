import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const UnderlineText = props => {
  const { color, text, black, href, target, to, handleClick } = props;

  const handleType = (href, handleClick) => {
    if (href) {
      return 'a';
    } else if (handleClick) {
      return 'div';
    } else {
      return Link;
    }
  };

  return (
    <Root color={color} {...props}>
      <Text
        as={handleType(href, handleClick)}
        black={black && black.toString()}
        href={href}
        target={target}
        to={to}
        onClick={handleClick ? handleClick : f => f}
      >
        {text}
      </Text>
      <span />
      <span />
    </Root>
  );
};

UnderlineText.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string,
  href: PropTypes.string,
  to: PropTypes.string,
  black: PropTypes.bool,
  handleClick: PropTypes.func,
};

export default UnderlineText;

const Root = styled.div`
  span:first-of-type {
    display: block;
    width: ${vw(20)};
    height: ${vw(2)};
    background-color: ${props => props.color};
    margin-top: ${vw(5)};
  }
  span:last-of-type {
    display: none;
  }
  @media (min-width: ${media.tablet}) {
    span:first-of-type {
      width: ${vwTablet(20)};
      height: ${vwTablet(2)};
      margin-top: ${vwTablet(5)};
    }
  }
  @media (min-width: ${media.desktop}) {
    cursor: pointer;
    position: relative;
    display: block;
    overflow-x: hidden;
    height: ${vwDesktop(31)};
    width: fit-content;
    span:first-of-type {
      width: ${`calc(100% + ${vwDesktop(40)})`};
      height: ${vwDesktop(2)};
      margin-top: ${vwDesktop(5)};
      position: absolute;
      bottom: 0;
      left: ${`calc(-100% - ${vwDesktop(20)})`};
      transition: 0.5s ease-in-out;
    }
    &:hover span:first-of-type {
      left: 100%;
    }
    span:last-of-type {
      display: block;
      background-color: ${props => props.color};
      width: ${vwDesktop(20)};
      height: ${vwDesktop(2)};
      position: absolute;
      bottom: 0;
      left: -100%;
      transition: 0.3s ease-in-out;
    }
    &:hover span:last-of-type {
      left: 0;
      transition-delay: 0.2s;
    }
  }
`;

const Text = styled(Link)`
  color: ${props => (props.black === 'true' ? 'black' : 'white')};
  font-size: ${vw(14)};
  font-weight: 500;
  line-height: 1.29;
  letter-spacing: ${vw(0.5)};
  @media (min-width: ${media.tablet}) {
    font-size: ${vwTablet(16)};
    line-height: 1.5;
    letter-spacing: ${vwTablet(1)};
  }
  @media (min-width: ${media.desktop}) {
    font-size: ${vwDesktop(16)};
    letter-spacing: ${vwDesktop(1)};
  }
`;
