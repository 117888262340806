import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from 'src/styles/theme';
import Button from 'components/Button';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Popup = (props) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsPopupOpen(true);
      document.getElementById('app').style.overflowY = 'hidden';
    }, 300);

   
  }, []);

  
  
  const handleClick = () => {
   
    document.getElementById('app').style.overflowY = 'visible';
    setIsPopupOpen(!isPopupOpen);
    setIsActive(!isActive)
    
  };

  return (
    <Root isPopupOpen={isPopupOpen} isActive={isActive}>
      <PopupContainer>
 
      <h2>Wait...What?!<br />Let us make it loud and clear!</h2>
       <p>It was brought to our attention that there have been misrepresentations in the industry. We’ve been encouraged by friends, partners and industry leaders to clear the air. </p>
       <p>Bam Digital Inc (formerly, Digital Division of Bam Communications) has been working hard in the background to continue innovating in the real estate industry after the dissolution of Bam Communication Inc. as of December 31, 2019. </p>
       <p>We are here helping our partners to embrace digital transformation and deliver greater customer experiences through technology. Our digital solutions and products are rooted in experience and powered by deep meaningful data and insights.</p>
       <p>We fundamentally value and operate in honest, transparent and ego-free collaborations that deliver results.</p>
       
       <Button color={theme.color.red} label="Have Questions? Let's Talk!" mailLink   />
       <Button color={theme.color.red} label="Continue >" onClick={handleClick} style={{float:"right"}} />
       <Button color={theme.color.red} className="closeMobile" label="X" onClick={handleClick} style={{ width:"auto",padding:"4px 10px 1px"}} />
       
      </PopupContainer>
    </Root>
  );
};

export default Popup;

const Root = styled.div`
  visibility: ${(props) =>
    props.isPopupOpen && props.isActive ? 'visible' : 'hidden'};
  opacity: ${(props) => (props.isPopupOpen ? '1' : '0')};
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
  transition: all 0.3s ease;
  z-index: 999;
  overflow: hidden;

`;



const PopupContainer = styled.div`
  width: 100vw;
  height: 100vh;
  padding: ${vw(20)} ${vw(20)} ${vw(130)};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.color.black};

  overflow-y:auto;

  h2 {
   
    font-size: ${vw(30)};
    font-weight: bold;
    line-height: ${vw(33)};
    letter-spacing: 0;
    color: rgba(255, 255, 255, 1);
    margin-top: ${vw(10)};
    padding-right:${vw(10)}
  }

  p {
    font-size: ${vw(14)};
    color: white;
    margin: ${vw(20)} 0;
    line-height: ${vw(22)};
  }
  a{ 
    display:block; 
    float: left;
    text-decoration:none;
    font-size: ${vw(14)};
    color:white;
    width:100%;
    text-align:center;
    margin:${vw(10)} 0 ${vw(10)};
    }
  
  @media (min-width: ${media.tablet}) {
    width: 60vw;
    height: auto;
    padding: ${vwTablet(40)};
    border:solid 1px #f04868;
    h2 {
      font-size: ${vwTablet(25)};
      line-height: ${vwTablet(28)};
      letter-spacing: ${vwTablet(0)};
      margin-top: ${vwTablet(20)};
      margin-bottom: ${vwTablet(30)};
    }

    p {
      font-size: ${vwTablet(14)};
      line-height: ${vwTablet(22)};
      letter-spacing: ${vwTablet(0.88)};
      margin: ${vwTablet(10)} 0 ${vwTablet(24)};
    }
    a{
      width:100%;
      font-size: ${vwTablet(14)};
      margin:${vwTablet(10)} 0 ${vwTablet(10)};
    }
  }

  @media (min-width: ${media.desktop}) {
    width: 75vw;
    height: auto;
    padding: ${vwDesktop(30)} ${vwDesktop(80)};
    border:solid 1px #f04868;
    h2 {
      font-size: ${vwDesktop(45)};
      font-weight: bold;
      line-height: 1.21;
      letter-spacing: ${vw(0.35)};
      margin:${vwDesktop(40)} 0 ${vwDesktop(30)};
    }

    p {
      font-size: ${vwDesktop(18)};
      letter-spacing: ${vwDesktop(0.5)};
      margin: ${vwDesktop(16)} 0 ${vwDesktop(24)};
      line-height: ${vw(6)};
    }
    a{
      width:auto;
      font-size: ${vwDesktop(16)};
      margin:${vwDesktop(30)} 0 ${vwDesktop(40)};
    }
  }
`;


const Close = styled.div`

 font-size: ${vw(12)};

  cursor: pointer;

  color:white;
  font-weight:bold;
  padding:10px 10px;
  border:solid 1px #FFF;
  width:${vw(100)};
  margin:${vwDesktop(60)} 0;
  
  @media (min-width: ${media.tablet}) {
    font-size: ${vwTablet(15)};
    width:${vwTablet(120)};
    margin:${vwTablet(60)} 0;
  }

  @media (min-width: ${media.desktop}) {
    font-size: ${vwDesktop(16)};

    cursor: pointer;

    color:white;
    font-weight:bold;
    padding:10px 10px;
    border:solid 1px #FFF;
    width:${vwDesktop(130)};
    margin:${vwDesktop(60)} 0;
    
  }
`;




