import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { vwDesktop } from 'src/styles/utils';

const FadeUp = props => {
  return <Root {...props} />;
};

FadeUp.propTypes = {
  delay: PropTypes.number,
  duration: PropTypes.number,
  animate: PropTypes.bool,
};

FadeUp.defaultProps = {
  delay: 0,
  duration: 0.5,
  animate: true,
};

const Root = styled.div`
  transform: ${props =>
    props.animate ? 'translateY(0)' : `translateY(${vwDesktop(20)})`};
  opacity: ${props => (props.animate ? 1 : 0)};
  transition: transform ${props => props.duration}s,
    opacity ${props => props.duration}s;
  transition-delay: ${props => (props.animate ? props.delay : 0)}s;
`;

export default FadeUp;
