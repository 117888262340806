import React from 'react';
import styled from 'styled-components';

import Container from 'components/Container';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Mission = () => {
  return (
    <Root>
      <Container>
        <h6>our mission</h6>
        <h1>
          Innovation driven by
          <br /> data and experience.
        </h1>
        <Wrapper>
          <p>
            At Bam Digital, we collect and leverage real-time data to drive our
            creative and strategic decision-making.
            <br />
            <br />
            We use data insights and market intelligence to inform our website
            designs, interactive touchscreen experiences, digital marketing
            campaigns, and brand consulting. We believe in partnering with
            you—collaborating through iterations, testing, and transparency—to
            provide full value.
          </p>
          <img
            src={require('src/assets/images/home/bam-office.jpg')}
            alt="Bam office"
          />
        </Wrapper>
      </Container>
    </Root>
  );
};

export default Mission;

const Root = styled.div`
  background-color: black;
  padding-top: ${vw(102)};
  h6 {
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.red};
    margin-bottom: ${vw(10)};
  }
  h1 {
    color: white;
  }
  p {
    color: white;
    padding: ${vw(20)} 0 ${vw(40)};
  }
  img {
    width: 100%;
    height: auto;
    margin-bottom: ${vw(-168)};
  }
  @media (min-width: ${media.tablet}) {
    padding-top: ${vwTablet(222)};
    h6 {
      margin-bottom: ${vwTablet(20)};
    }
    p {
      padding: ${vwTablet(40)} 0 ${vwTablet(80)};
    }
    img {
      margin-bottom: ${vwTablet(-372)};
    }
  }
  @media (min-width: ${media.desktop}) {
    padding-top: ${vwDesktop(212)};
    h6 {
      margin-bottom: ${vwDesktop(20)};
    }
    p {
      width: ${vwDesktop(516)};
      padding: 0 0 ${vwDesktop(80)};
    }
    img {
      width: ${vwDesktop(625)};
      height: ${vwDesktop(540)};
      margin: ${vwDesktop(120)} 0 ${vwDesktop(-240)};
    }
  }
`;

const Wrapper = styled.div`
  @media (min-width: ${media.desktop}) {
    display: flex;
    justify-content: space-between;
    img {
      order: 1;
    }
    p {
      order: 2;
    }
  }
`;
