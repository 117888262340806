import React from 'react';

export default [
  {
    title: 'Custom Floor Plan Search',
    thumbnail: require('src/assets/images/rentals/thumb-custom-floorplan-search.jpg'),
    image: require('src/assets/images/rentals/custom-floorplan-search.jpg'),
    text:
      'Make it simple to filter, compare, and share floorplans, based on criteria and availability of the properties.',
    content: (
      <p>
        When finding a new home, the first step customers take is selecting the
        right floor plan. Make it simple to filter what they’re looking for
        based on their needs and the real-time availability of your properties.
      </p>
    ),
  },
  {
    title: 'Real Time Availability',
    thumbnail: require('src/assets/images/rentals/thumb-availability-list.jpg'),
    image: require('src/assets/images/rentals/integrated-availability.jpg'),
    text:
      'See available units and floorplans, or filter various list-view options based on real time inventory.',
    content: (
      <p>
        Keep your inventory updated and visible in real time with our fully
        integrated API software. Let customers see available units and floor
        plans, or filter various list-view options. Save time and increase
        convenience for both the customer and your management team.
      </p>
    ),
  },
  {
    title: (
      <>
        True Unit &amp;
        <br />
        360° Views
      </>
    ),
    thumbnail: require('src/assets/images/rentals/thumb-true-view.jpg'),
    image: require('src/assets/images/rentals/true-view.jpg'),
    text:
      'Showcase the fully immersive true 360° views from the selected floorplan or building floors.',
    content: (
      <p>
        Showcase the true view from the selected floorplan on the site and pan
        360° to get a fully immersive experience. Customers can explore views
        from various levels and vantage points to get a true perspective.
      </p>
    ),
  },
  {
    title: 'Updates & Promotions',
    thumbnail: require('src/assets/images/rentals/thumb-updates.jpg'),
    image: require('src/assets/images/rentals/updates-and-promotions.jpg'),
    text:
      'Capture visitors’ attention with brand-designed pop-up promotions and information.',
    content: (
      <p>
        We can help you craft and design your important promotions and messages
        and place them up front to capture the attention of potential customers.
        We offer many functional and display options for this essential
        promotional content.
      </p>
    ),
  },
  {
    title: (
      <>
        Custom Design
        <br /> &amp; Engineering
      </>
    ),
    thumbnail: require('src/assets/images/rentals/thumb-bespoke-design.jpg'),
    image: require('src/assets/images/rentals/bespoke-design.jpg'),
    text:
      'Let your brand stand out in the market and come to life through stunning designs on your website.',
    content: (
      <p>
        Our experienced interactive designers concept, prototype, and deliver
        stunning designs through a collaborative creative process that ensures
        everyone’s voice is heard. You do the talking—we’ll design and deliver
        the solutions.{' '}
      </p>
    ),
  },
  {
    title: (
      <>
        Data <br />
        Intelligence
      </>
    ),
    thumbnail: require('src/assets/images/rentals/thumb-data-intelligence.jpg'),
    image: require('src/assets/images/rentals/data-intelligence.jpg'),
    text:
      'Get the data insights you need to understand your customers, track your progress, and improve your results.',
    content: (
      <p>
        Get the information you need to understand your customers. Visual
        customer insights reporting from Google Analytics, behavioral-tracking
        tools, survey-based data, and AI interaction stats that provide the
        market intelligence you need to get the edge. Set your targets, track
        your progress, and improve your results.
      </p>
    ),
  },
];
