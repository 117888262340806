import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';

import DragScroll from 'components/DragScroll';
import WaypointTrigger from 'components/Animation/WaypointTrigger';
import FadeUp from 'components/Animation/FadeUp';
// import CustomCursor from 'components/CustomCursor';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

import imgIpad from 'src/assets/images/projects/tablet.png';
import imgLaptop from 'src/assets/images/projects/laptop.png';

class F extends Component {
  state = {
    hasClicked: false,
  };

  handleLeaveWaypoint = () => this.setState({ hasClicked: false });

  handleClick = () => this.setState({ hasClicked: true });

  render() {
    const {
      media,
      mediaType,
      image,
      ipad,
      laptop,
      leftImage,
      backgroundType,
      backgroundColor,
      topColor,
      bottomColor,
    } = this.props;
    return (
      <Waypoint onLeave={this.handleLeaveWaypoint}>
        <div>
          <WaypointTrigger>
            {animate => (
              <FadeUp animate={animate}>
                <Root>
                  <Device
                    isRight={leftImage}
                    ipad={ipad}
                    laptop={laptop}
                    background={backgroundType}
                    backgroundColor={backgroundColor}
                    topColor={topColor}
                    bottomColor={bottomColor}
                  >
                    <Container>
                      {ipad && <img src={imgIpad} alt="Tablet" />}
                      {laptop && <img src={imgLaptop} alt="Laptop" />}
                      <Media
                        ipad={ipad}
                        laptop={laptop}
                        scroll={mediaType === 'scroll'}
                        as={mediaType === 'scroll' ? DragScroll : 'div'}
                        onMouseDown={this.handleClick}
                        onTouchStart={this.handleClick}
                      >
                        {mediaType === 'scroll' && (
                          <DragOverlay
                            active={!this.state.hasClicked}
                            laptop={laptop}
                          >
                            <img
                              src={require('src/assets/images/cursors/cursor_drag_vertical.svg')}
                              alt="Drag"
                            />
                            <p>Drag</p>
                          </DragOverlay>
                        )}
                        {(mediaType === 'image' || mediaType === 'scroll') && (
                          // <Cursor
                          //   as={mediaType === 'scroll' ? CustomCursor : 'div'}
                          //   dragVertical
                          // >
                          <img src={media} alt="" />
                          // </Cursor>
                        )}
                        {mediaType === 'video' && (
                          <>
                            <video
                              src={media}
                              autoPlay
                              muted
                              loop
                              className="desktop-video"
                            />
                            <video
                              src={media}
                              controls
                              muted
                              loop
                              className="mobile-video"
                              playsInline
                            />
                          </>
                        )}
                      </Media>
                    </Container>
                  </Device>
                  <Image image={image} isLeft={leftImage} />
                </Root>
              </FadeUp>
            )}
          </WaypointTrigger>
        </div>
      </Waypoint>
    );
  }
}

F.propTypes = {
  media: PropTypes.string,
  image: PropTypes.string,
  ipad: PropTypes.bool,
  laptop: PropTypes.bool,
  leftImage: PropTypes.bool,
  mediaType: PropTypes.string,
};

F.defaultProps = {
  ipad: false,
  leftImage: false,
  mediaType: 'image',
  backgroundType: 'solid',
};

export default F;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${media.tablet}) {
    flex-direction: row;
    height: ${vwTablet(512)};
  }
  @media (min-width: ${media.desktop}) {
    height: 100vh;
  }
`;

const Device = styled.div`
  width: 100%;
  padding: ${vw(80)} ${vw(37)};
  background: ${props => {
    if (props.background === 'gradient') {
      return `linear-gradient(0deg, ${props.bottomColor} 0%, ${props.topColor} 100%)`;
    } else if (props.background === 'solid') {
      return props.backgroundColor;
    }
  }};
  display: flex;
  align-items: center;
  justify-content: center;
  order: ${props => (props.isRight ? 2 : 1)};
  @media (min-width: ${media.tablet}) {
    width: 65.2%;
    padding: ${props =>
      props.ipad ? `0 ${vwDesktop(196)}` : `0 ${vwDesktop(100)}`};
  }
`;

const Container = styled.div`
  position: relative;
  > img {
    height: 100%;
    width: 100%;
    z-index: 2;
    position: relative;
    pointer-events: none;
  }
`;

const Image = styled.div`
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: ${vw(461)};
  order: ${props => (props.isLeft ? 1 : 2)};
  @media (min-width: ${media.tablet}) {
    width: 34.8%;
    height: auto;
  }
`;

const Media = styled.div`
  position: absolute;
  left: ${props => (props.ipad ? '4%' : '15%')};
  top: ${props => (props.ipad ? '6%' : '6%')};
  width: ${props => (props.ipad ? '91%' : '70%')};
  height: ${props => (props.ipad ? '86.5%' : '80%')};
  overflow: ${props => (props.scroll ? 'scroll' : 'hidden')};
  cursor: ${props => (props.scroll ? 'grab' : '')};
  ::-webkit-scrollbar {
    display: none;
  }
  img {
    width: 100%;
    height: ${props => (props.scroll ? 'auto' : '100%')};
    object-fit: cover;
    object-position: center;
    z-index: 1;
    margin-bottom: ${props => (props.scroll ? '-1%' : 0)};
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  .mobile-video {
    background-color: black;
    @media (min-width: ${media.desktop}) {
      display: none;
    }
  }
  .desktop-video {
    display: none;
    @media (min-width: ${media.desktop}) {
      display: block;
    }
  }
`;

const DragOverlay = styled.div`
  height: ${props => (props.laptop ? '14.5%' : '19.5%')};
  width: ${props => (props.laptop ? '54%' : '70%')};
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  pointer-events: none;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: ${props => (props.active ? 1 : 0)};
  transition: 0.5s ease;
  @media (min-width: ${media.tablet}) {
    height: ${props => (props.laptop ? '34%' : '36%')};
    width: ${props => (props.laptop ? '35.5%' : '34.5%')};
  }
  @media (min-width: ${media.desktop}) {
    display: none;
  }
  img {
    height: ${vw(30)};
    width: ${vw(30)};
    margin-bottom: ${vw(15)};
    @media (min-width: ${media.tablet}) {
      height: ${vwTablet(40)};
      width: ${vwTablet(40)};
      margin-bottom: ${vwTablet(15)};
    }
  }
  p {
    text-transform: uppercase;
    font-size: ${vwTablet(15)};
    letter-spacing: ${vw(1.5)};
    font-weight: bold;
    text-align: center;
    color: white;
    @media (min-width: ${media.tablet}) {
      font-size: ${vwTablet(15)};
      letter-spacing: ${vwTablet(1.5)};
    }
  }
`;

// const Cursor = styled.div``;
