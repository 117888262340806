import React, { useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import PropTypes from 'prop-types';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Form = props => {
  const { color } = props;

  const [hasSent, setHasSent] = useState(false);

  const handleSubmit = e => {
    setHasSent(true);
  };

  return (
    <Wrapper>
      <iframe name="mailerIframe" src="" title="form" id="frame_submission" />
      <Root
        color={color}
        hasSent={hasSent}
        action="http://scripts.bamdigital.com/bam-mailer.php"
        method="post"
        target="mailerIframe"
        onSubmit={handleSubmit}
      >
        <div>
          <input type="email" name="email" placeholder="Email address" />
          <span />
        </div>
        <button type="submit" className="newsletter-submit">
          <img
            src={require('src/assets/images/icons/arrow.svg')}
            alt="Submit"
          />
          <p className="body2 bold">Subscribe</p>
        </button>
      </Root>
      {hasSent && (
        <ThankYou className="icon icon--order-success svg">
          <svg xmlns="http://www.w3.org/2000/svg" width="39px" height="39px">
            <g
              fill="none"
              transform="translate(-565.000000, -791.000000)"
              stroke="#FFFFFF"
              strokeWidth="2"
            >
              <g id="checkmark" transform="translate(567.000000, 793.000000)">
                <path
                  d="M17.5,35 C27.1649831,35 35,27.1649831 35,17.5 C35,7.83501688 27.1649831,0 17.5,0 C7.83501688,0 0,7.83501688 0,17.5 C0,27.1649831 7.83501688,35 17.5,35 Z"
                  id="Oval"
                  strokeWidth="3"
                  strokeDasharray="240,240"
                  className="circle"
                />
                <path
                  d="M10.2085,18.389 L15.1735,23.3435 L25.5004883,12.8588867"
                  id="Path"
                  strokeWidth="2"
                  strokeDasharray="50,50"
                  className="line"
                />
              </g>
            </g>
          </svg>
          <p>
            Thank you! Please check your email to confirm your subscription.
          </p>
        </ThankYou>
      )}
    </Wrapper>
  );
};

Form.propTypes = {
  color: PropTypes.string,
};

export default Form;

const checkmark = keyframes`
  0% {
    stroke-dashoffset: 50px;
  }
  100% {
    stroke-dashoffset: 0;
  }
`;

const checkmarkCircle = keyframes`
  0% {
    stroke-dashoffset: 240px;
  }
  100% {
    stroke-dashoffset: 480px;
  }
`;

const fadeOut = keyframes`
  0% {
    display: block;
    opacity: 1;
  }
  100% {
   opacity:0;
   pointer-events: 'none';
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
    pointer-events: 'none';
  }
  100% {
    opacity: 1;
    pointer-events: 'all';
  }
`;

const fadeOutCss = css`
  animation: ${fadeOut} 0.4s ease-out forwards;
`;

const fadeInCss = css`
  animation: ${fadeIn} 0.4s ease-out forwards;
  animation-delay: 0.4s;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: ${vw(119)};
  iframe {
    display: none;
  }
  @media (min-width: ${media.tablet}) {
    height: ${vwTablet(122)};
  }
  @media (min-width: ${media.desktop}) {
    height: ${vwDesktop(122)};
  }
`;

const Root = styled.form`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  margin-bottom: ${vw(80)};
  ${props => props.hasSent && fadeOutCss}
  input {
    width: ${vw(219)};
    padding: ${vw(8)} 0;
    border: none;
    border-bottom: ${vw(2)} solid ${({ theme }) => theme.color.grey2};
    margin-right: ${vw(5)};
    background-color: transparent;
    font-family: ${({ theme }) => theme.font.main};
    font-size: ${vw(14)};
    font-weight: 300;
    line-height: 1.57;
    letter-spacing: ${vw(0.5)};
    color: white;
    outline: none;
    &:focus ~ span {
      max-width: 100%;
    }
  }
  > div {
    position: relative;
  }
  span {
    width: ${`calc(100% - ${vw(5)})`};
    height: ${vw(2)};
    background-color: white;
    max-width: 0;
    transition: 0.4s ease-out;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  button {
    width: ${vw(40)};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${vw(10)} 0;
    border-bottom: ${vw(2)} solid ${props => props.color};
    cursor: pointer;
    .bold {
      margin-right: 0;
    }
  }
  img {
    width: ${vw(16)};
    height: ${vw(17)};
  }
  p {
    display: none;
  }
  @media (min-width: ${media.tablet}) {
    margin-bottom: ${vwTablet(80)};
    input {
      width: ${vwTablet(462)};
      padding: ${vwTablet(8)} 0;
      border-bottom: ${vwTablet(2)} solid ${({ theme }) => theme.color.grey2};
      margin-right: ${vwTablet(16)};
      font-size: ${vwTablet(16)};
      line-height: 1.5;
      letter-spacing: ${vwTablet(0.5)};
    }
    button {
      width: ${vwTablet(108)};
      padding: ${vwTablet(8)} 0;
      border-bottom: ${vwTablet(2)} solid ${props => props.color};
    }
    img {
      display: none;
    }
    p {
      display: block;
      color: white;
      margin: 0;
    }
    span {
      width: ${`calc(100% - ${vwTablet(16)})`};
      height: ${vwTablet(2)};
    }
  }
  @media (min-width: ${media.desktop}) {
    margin-bottom: ${vwDesktop(80)};
    input {
      width: ${vwDesktop(500)};
      padding: ${vwDesktop(8)} 0;
      border-bottom: ${vwDesktop(2)} solid ${({ theme }) => theme.color.grey2};
      margin-right: ${vwDesktop(16)};
      font-size: ${vwDesktop(16)};
      letter-spacing: ${vwDesktop(0.5)};
    }
    button {
      width: ${vwDesktop(108)};
      padding: ${vwDesktop(8)} 0;
      border-bottom: ${vwDesktop(2)} solid ${props => props.color};
    }
    span {
      width: ${`calc(100% - ${vwDesktop(16)})`};
      height: ${vwDesktop(2)};
    }
  }
`;

const ThankYou = styled.div`
  display: flex;
  align-items: center;
  opacity: 0;
  pointer-events: 'none';
  ${fadeInCss}
  p {
    max-width: ${vw(200)};
    padding-left: ${vw(15)};
    @media (min-width: ${media.tablet}) {
      padding-left: ${vwTablet(15)};
      max-width: none;
    }
    @media (min-width: ${media.desktop}) {
      padding-left: ${vwDesktop(15)};
      max-width: none;
    }
  }
  svg .line {
    animation: ${checkmark} 0.25s ease-in-out 0.7s backwards;
    animation-delay: 1.5s;
  }
  svg .circle {
    animation: ${checkmarkCircle} 0.6s ease-in-out backwards;
    animation-delay: 0.5s;
  }
`;
