import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ImageFade = props => {
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = e => {
    setScrollTop(e.target.scrollingElement.scrollTop);
  };

  const { src } = props;

  return (
    <Root
      src={src}
      style={
        scrollTop > 400 ? { opacity: 0.18 * (1 - (scrollTop - 400) / 200) } : {}
      }
      {...props}
    />
  );
};

ImageFade.propTypes = {
  src: PropTypes.string,
};

export default ImageFade;

const Root = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  opacity: 0.18;
`;
