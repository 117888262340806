export default [
  {
    title: 'Services',
    to: 'services',
  },
  {
    title: 'Approach',
    to: 'approach',
  },
  {
    title: 'Work',
    to: 'work',
  },
];
