export default [
  {
    title: 'Industry Experts',
    description:
      'We have over 20 years’ experience providing award-winning digital solutions to the real estate industry. Our success is supported by our agile project management method, allowing us to react quickly to challenges and take advantage of opportunities.',
  },
  {
    title: 'Results-Driven',
    description:
      'Facts and figures are the heart of our strategic solutions and we continue to successfully drive conversions for our clients. We have established ourselves as a leader in the real estate industry by building our own custom databases and tools.',
  },
  {
    title: 'Holistic Approach',
    description:
      'Three pillars define our partnerships: trust, transparency, and collaboration. We focus on our relationship with you, what your needs and goals are, and we use our data-centric approach to provide optimal solutions that meet your goals.',
  },
];
