export default [
  {
    name: 'Concord Pacific',
    image: require('src/assets/images/logos/clients/concord-pacific.png'),
    path: 'https://www.concordpacific.com/',
  },
  {
    name: 'Ledingham McAllister',
    image: require('src/assets/images/logos/clients/ledingham-mcallister.png'),
    path: 'https://ledmac.com/',
  },
  {
    name: 'Aquilini Development',
    image: require('src/assets/images/logos/clients/aquilini-development.png'),
    path: 'https://aquilinidevelopment.com/',
  },
  {
    name: 'Polygon',
    image: require('src/assets/images/logos/clients/polygon.png'),
    path: 'https://www.polyhomes.com/',
  },
  {
    name: 'Onni Group',
    image: require('src/assets/images/logos/clients/onni-group.png'),
    path: 'https://www.onni.com/',
  },
  {
    name: 'Townline',
    image: require('src/assets/images/logos/clients/townline.png'),
    path: 'https://www.townline.ca/',
  },
  {
    name: 'Thind',
    image: require('src/assets/images/logos/clients/thind.png'),
    path: 'http://thind.ca/',
  },
  {
    name: 'Aoyuan',
    image: require('src/assets/images/logos/clients/aoyuan.png'),
    path: 'https://aoyuaninternational.com/',
  },
];
