import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';

import DragScroll from 'components/DragScroll';
import WaypointTrigger from 'components/Animation/WaypointTrigger';
import FadeUp from 'components/Animation/FadeUp';
// import CustomCursor from 'components/CustomCursor';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

import imgIpad from 'src/assets/images/projects/tablet.png';
import imgLaptop from 'src/assets/images/projects/laptop.png';

class E extends Component {
  state = {
    hasClicked: false,
  };

  handleLeaveWaypoint = () => this.setState({ hasClicked: false });

  handleClick = () => this.setState({ hasClicked: true });

  render() {
    const {
      ipad,
      laptop,
      text,
      leftText,
      backgroundType,
      backgroundColor,
      backgroundImage,
      topColor,
      bottomColor,
      whiteText,
      mediaType,
      media,
    } = this.props;
    return (
      <Waypoint onLeave={this.handleLeaveWaypoint}>
        <div>
          <WaypointTrigger>
            {animate => (
              <FadeUp animate={animate}>
                <Root
                  leftText={leftText}
                  text={text}
                  background={backgroundType}
                  topColor={topColor}
                  bottomColor={bottomColor}
                  backgroundColor={backgroundColor}
                  backgroundImage={backgroundImage}
                  whiteText={whiteText}
                  className="white-background"
                >
                  <Content leftText={leftText}>
                    {ipad && <img src={imgIpad} alt="Tablet" />}
                    {laptop && <img src={imgLaptop} alt="Laptop" />}
                    <Media
                      ipad={ipad}
                      laptop={laptop}
                      scroll={mediaType === 'scroll'}
                      as={mediaType === 'scroll' ? DragScroll : 'div'}
                      onMouseDown={this.handleClick}
                      onTouchStart={this.handleClick}
                    >
                      {mediaType === 'scroll' && (
                        <DragOverlay
                          active={!this.state.hasClicked}
                          laptop={laptop}
                          text={text}
                        >
                          <img
                            src={require('src/assets/images/cursors/cursor_drag_vertical.svg')}
                            alt="Drag"
                            className="drag-overlay"
                          />
                          <p>Drag</p>
                        </DragOverlay>
                      )}
                      {(mediaType === 'image' || mediaType === 'scroll') && (
                        // <Cursor
                        //   dragVertical
                        //   as={mediaType === 'scroll' ? CustomCursor : 'div'}
                        // >
                        <img
                          src={media}
                          alt=""
                          id="scroll-image"
                          className="scroll-image"
                        />
                        // </Cursor>
                      )}
                      {mediaType === 'video' && (
                        <>
                          <video
                            src={media}
                            autoPlay
                            muted
                            loop
                            className="desktop-video"
                          />
                          <video
                            src={media}
                            controls
                            muted
                            loop
                            className="mobile-video"
                            playsInline
                          />
                        </>
                      )}
                    </Media>
                  </Content>
                  {text && <p>{text}</p>}
                </Root>
              </FadeUp>
            )}
          </WaypointTrigger>
        </div>
      </Waypoint>
    );
  }
}

E.propTypes = {
  ipad: PropTypes.bool,
  laptop: PropTypes.bool,
  text: PropTypes.string,
  leftText: PropTypes.bool,
  backgroundType: PropTypes.string,
  backgroundImage: PropTypes.string,
  topColor: PropTypes.string,
  bottomColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  whiteText: PropTypes.bool,
};

E.defaultProps = {
  backgroundType: 'gradient',
  mediaType: 'image',
};

export default E;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${vw(80)} 0;
  /* background-image: linear-gradient(0deg, #746d5f 0%, #a9a08d 100%); */
  background: ${props => {
    if (props.background === 'gradient') {
      return `linear-gradient(0deg, ${props.bottomColor} 0%, ${props.topColor} 100%)`;
    } else if (props.background === 'solid') {
      return props.backgroundColor;
    } else if (props.background === 'image') {
      return `url(${props.backgroundImage})`;
    }
  }};
  background-size: cover;
  @media (min-width: ${media.tablet}) {
    flex-direction: row;
    justify-content: ${props => (props.text ? 'space-between' : 'center')};
    height: ${vwTablet(512)};
    padding: 0 ${vwDesktop(80)};
  }
  @media (min-width: ${media.desktop}) {
    height: 100vh;
  }
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    height: 50vh;
  }
  p {
    width: 76.5%;
    font-size: ${vw(14)};
    font-weight: 300;
    line-height: ${vw(22)};
    letter-spacing: ${vw(0.5)};
    text-align: center;
    margin: ${vw(20)} auto;
    order: ${props => (props.leftText ? 1 : 2)};
    color: ${props => (props.whiteText ? 'white' : 'black')};
    /* cursor: url(/assets/images/glowing-cursor.png) 2 2, pointer; */
    @media (min-width: ${media.tablet}) {
      font-size: ${vwTablet(15)};
      line-height: ${vwTablet(24)};
      letter-spacing: ${vwTablet(0.5)};
      max-width: ${vwTablet(224)};
      width: 40%;
      text-align: left;
      margin: 0 auto;
    }
    @media (min-width: ${media.desktop}) {
      max-width: ${vwDesktop(300)};
      font-size: ${vwDesktop(18)};
      line-height: ${vwDesktop(32)};
      letter-spacing: ${vwDesktop(1)};
    }
  }
`;

const Content = styled.div`
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  order: ${props => (props.leftText ? 2 : 1)};
  position: relative;
  @media (min-width: ${media.tablet}) {
    width: 60%;
  }
  > img {
    width: 100%;
    height: 100%;
    z-index: 2;
    pointer-events: none;
  }
`;

const Media = styled.div`
  position: absolute;
  left: ${props => (props.ipad ? '4%' : '15%')};
  top: ${props => (props.ipad ? '6%' : '6%')};
  width: ${props => (props.ipad ? '91%' : '70%')};
  height: ${props => (props.ipad ? '87%' : '81%')};
  z-index: 1;
  overflow: ${props => (props.scroll ? 'scroll' : 'hidden')};
  cursor: ${props => (props.scroll ? 'grab' : '')};
  ::-webkit-scrollbar {
    display: none;
  }
  .scroll-image {
    object-fit: cover;
    object-position: center;
    height: ${props => (props.scroll ? 'auto' : '100%')};
    width: 100%;
    margin-bottom: ${props => (props.scroll ? '-1%' : 0)};
    transition: 0.5s ease;
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  .mobile-video {
    background-color: black;
    @media (min-width: ${media.desktop}) {
      display: none;
    }
  }
  .desktop-video {
    display: none;
    @media (min-width: ${media.desktop}) {
      display: block;
    }
  }
`;

const DragOverlay = styled.div`
  /* height: ${props => (props.laptop ? '26.5%' : '51%')}; */
  height: ${props => {
    if (props.laptop && !props.text) {
      return '39%';
    } else if (props.laptop) {
      return '29%';
    } else if (props.text) {
      return '34%';
    } else {
      return '48%';
    }
  }};
  width: ${props => (props.laptop ? '59.7%' : '77%')};
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  pointer-events: none;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: ${props => (props.active ? 1 : 0)};
  transition: 0.5s ease;
  @media (min-width: ${media.tablet}) {
    height: ${props => (props.laptop ? '35%' : '51%')};
    width: ${props => (props.laptop ? '37.5%' : '48.5%')};
  }
  @media (min-width: ${media.desktop}) {
    display: none;
  }
  img {
    height: ${vw(30)};
    width: ${vw(30)};
    margin-bottom: ${vw(15)};
    @media (min-width: ${media.tablet}) {
      height: ${vwTablet(40)};
      width: ${vwTablet(40)};
      margin-bottom: ${vwTablet(15)};
    }
  }
  p {
    text-transform: uppercase;
    font-size: ${vw(12)};
    letter-spacing: ${vw(1.5)};
    line-height: inherit;
    margin: 0;
    font-weight: bold;
    text-align: center;
    color: white;
    @media (min-width: ${media.tablet}) {
      font-size: ${vwTablet(15)};
      letter-spacing: ${vwTablet(1.5)};
    }
  }
`;

// const Cursor = styled.div``;
