import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';

import CloseButton from './elements/CloseButton';
import Image from './elements/Image';
import TextContainer from './elements/TextContainer';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Modal = props => {
  const {
    isOpen,
    handleModal,
    carousel,
    content,
    featureColor,
    activeIndex,
    carouselRef,
    pageTitle,
  } = props;

  const settings = {
    infinite: true,
    dots: false,
    fade: true,
    speed: 400,
    easing: 'ease-in',
    nextArrow: <NextArrow to="next" />,
    prevArrow: <PrevArrow to="prev" />,
  };

  function NextArrow(props) {
    const { onClick } = props;
    return (
      <img
        src={require('src/assets/images/icons/next-arrow.png')}
        alt="Next Arrow"
        className="next-arrow"
        onClick={onClick}
      />
    );
  }

  function PrevArrow(props) {
    const { onClick } = props;

    return (
      <img
        src={require('src/assets/images/icons/prev-arrow.png')}
        alt="Prev Arrow"
        className="prev-arrow"
        onClick={onClick}
      />
    );
  }

  return (
    <Root isOpen={isOpen}>
      <CloseButton handleModal={handleModal} />
      {carousel ? (
        <Slider ref={carouselRef} {...settings}>
          {content.map((slide, index) => (
            <Slide key={index}>
              <Image
                image={slide.image}
                title={slide.title}
                featureColor={featureColor}
              />
              <TextContainer
                body={slide.content}
                title={slide.title}
                navSpace
                featureColor={featureColor}
              ></TextContainer>
            </Slide>
          ))}
        </Slider>
      ) : (
        <Solution>
          <Image
            image={content[activeIndex].image}
            title={content[activeIndex].title}
            featureColor={featureColor}
          />
          <TextContainer
            title={content[activeIndex].title}
            body={content[activeIndex].content}
            caseStudies={content[activeIndex].caseStudies}
            featureColor={featureColor}
            caseStudyMobile={content[activeIndex].caseStudyMobile}
            pageTitle={pageTitle}
          ></TextContainer>
        </Solution>
      )}
    </Root>
  );
};

export default Modal;

const Root = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: #ffffff;
  z-index: 99;
  height: 100vh;
  height: -webkit-fill-available;
  min-height: -webkit-fill-available;
  width: 100%;
  transform: ${props =>
    props.isOpen
      ? ` translate(-50%,-50%) scale(1)`
      : ` translate(-50%,-50%)  scale(0.7) `};
  pointer-events: ${props => (props.isOpen ? 'all' : 'none')};
  opacity: ${props => (props.isOpen ? 1 : 0)};
  transition: transform 0.3s ease, opacity 0.3s ease;

  .next-arrow,
  .prev-arrow {
    position: absolute;
    height: ${vw(60)};
    width: ${vw(60)};
    bottom: ${vw(20)};
    z-index: 999;
  }
  .next-arrow {
    left: ${vw(76)};
  }
  .prev-arrow {
    left: ${vw(16)};
  }
  .slick-active {
    z-index: 2;
  }
  @media (min-width: ${media.tablet}) {
    height: 100vh;
    .next-arrow,
    .prev-arrow {
      height: ${vwTablet(60)};
      width: ${vwTablet(60)};
      bottom: ${vwTablet(80)};
    }
    .next-arrow {
      left: ${vwTablet(212)};
    }
    .prev-arrow {
      left: ${vwTablet(152)};
    }
  }
  @media (min-width: ${media.desktop}) {
    .next-arrow,
    .prev-arrow {
      position: absolute;
      height: ${vwDesktop(60)};
      width: ${vwDesktop(60)};
      bottom: ${vwDesktop(80)};
      cursor: pointer;
      opacity: 0.4;
      transition: opacity 0.3s ease;
      &:hover {
        opacity: 1;
      }
    }
    .next-arrow {
      left: ${vwDesktop(904)};
    }
    .prev-arrow {
      left: ${vwDesktop(844)};
    }
    .slick-slider {
      height: 100vh;
    }
    .slick-slide {
      pointer-events: none;
    }
    .slick-slide .text-container {
      transform: translateY(30px);
      transition: transform 0.2s ease-in;
    }
    .slick-active {
      pointer-events: ${props => (props.isOpen ? 'all' : 'none')} !important;
    }
    .slick-active.slick-current .text-container {
      transform: translateY(0);
      transition-timing-function: ease-out !important;
      transition-duration: 0.4s !important;
    }
  }
`;

const Slide = styled.div`
  @media (min-width: ${media.desktop}) {
    display: flex !important;
    flex-direction: row;
  }
`;

const Solution = styled.div`
  @media (min-width: ${media.desktop}) {
    display: flex;
    flex-direction: row;
  }
`;
