import React from 'react';
import styled from 'styled-components';

// import Button from 'components/Button';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Clients = props => {
  const { clients, featureColor } = props;
  return (
    <Root featureColor={featureColor}>
      <h6 className="subheader">Our Clients</h6>
      <h1 className="clients-h1">Our network. </h1>
      <LogosContainer>
        {clients.map((client, index) => (
          <a
            href={client.path}
            target="_blank"
            rel="noopener noreferrer"
            key={index}
          >
            <img src={client.image} alt={`${client.name} logo`} />
          </a>
        ))}
      </LogosContainer>
      {/* <Button label="Let's Work Together" color={featureColor} mailLink />{' '} */}
    </Root>
  );
};

export default Clients;

const Root = styled.div`
  margin-top: ${vw(80)};
  h1 {
    margin-top: ${vw(10)};
    margin-bottom: ${vw(20)};
  }
  h6.subheader {
    color: ${props => props.featureColor};
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  @media (min-width: ${media.tablet}) {
    margin-top: ${vwTablet(160)};
    h1.clients-h1 {
      width: auto;
    }
    h1 {
      margin-top: ${vwTablet(20)};
      margin-bottom: ${vwTablet(40)};
    }
  }
  @media (min-width: ${media.desktop}) {
    margin-top: ${vwDesktop(160)};
    width: ${vwDesktop(824)};
    margin-left: auto;
    h1.clients-h1 {
      width: ${vwDesktop(625)};
    }
    h1 {
      margin-top: ${vwDesktop(20)};
      margin-bottom: ${vwDesktop(20)};
    }
  }
`;

const LogosContainer = styled.div`
  display: grid;
  grid-template-columns: ${vw(136)} ${vw(136)};
  grid-row-gap: ${vw(20)};
  justify-content: space-between;
  @media (min-width: ${media.tablet}) {
    grid-template-columns: ${vwTablet(192)} ${vwTablet(192)} ${vwTablet(192)};
    grid-row-gap: ${vwTablet(40)};
  }
  @media (min-width: ${media.desktop}) {
    grid-template-columns: ${vwDesktop(192)} ${vwDesktop(192)} ${vwDesktop(192)} ${vwDesktop(
        192
      )};
    grid-row-gap: ${vwDesktop(40)};
  }
`;
