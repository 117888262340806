import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link as ScrollLink } from 'react-scroll';

import CTA from './CTA';

import data from 'src/data/header';
import social from 'src/data/social';

import { vw, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';
import theme from 'src/styles/theme';

const Menu = props => {
  const { active, closeMenu } = props;
  return (
    <Root active={active}>
      <Wrapper>
        {data.map((item, index) => (
          <Section
            key={index}
            smooth
            onClick={closeMenu}
            to={item.to}
            last={(index === data.length - 1).toString()}
          >
            <h2>{item.title}</h2>
          </Section>
        ))}
        <CTA color={theme.color.red} menu={false} />
        <Social>
          {social.map((item, index) => (
            <a
              href={item.to}
              target="_blank"
              rel="noopener noreferrer"
              key={index}
            >
              <img src={item.src} alt={item.title} />
            </a>
          ))}
        </Social>
        <p className="body2">
          2060 Pine Street
          <br />
          Vancouver, British Columbia
          <br />
          V6J 4P8 Canada
        </p>
      </Wrapper>
      <Button href="tel:+1-604-729-5787">
        <p className="bold">+1 (604) 729-5787</p>
      </Button>
      <Button href="mailto:info@bamdigital.com">
        <p className="bold">info@bamdigital.com</p>
      </Button>
    </Root>
  );
};

Menu.propTypes = {
  active: PropTypes.bool,
  closeMenu: PropTypes.func,
};

export default Menu;

const Root = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  transform: ${props => (props.active ? 'translateX(0)' : 'translateX(-100%)')};
  background-color: black;
  padding: ${vw(16)};
  color: white;
  transition: 0.33s;
  z-index: 10;
  text-align: center;
  .body2 {
    margin-bottom: ${vw(20)};
  }
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(40)} ${vwTablet(30)};
    .body2 {
      margin-bottom: ${vwTablet(40)};
    }
  }
  @media (min-width: ${media.desktop}) {
    display: none;
  }
`;

const Section = styled(ScrollLink)`
  margin-bottom: ${props => (props.last === 'true' ? vw(40) : vw(20))};
  text-align: center;
  color: white;
  @media (min-width: ${media.tablet}) {
    margin-bottom: ${props =>
      props.last === 'true' ? vwTablet(80) : vwTablet(40)};
  }
`;

const Social = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: ${vw(40)} ${vw(77)};
  @media (min-width: ${media.tablet}) {
    padding: 0 ${vwTablet(281)};
    margin: ${vwTablet(80)} 0;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${vw(84)};
  @media (min-width: ${media.tablet}) {
    margin-top: ${vwTablet(160)};
  }
`;

const Button = styled.a`
  position: relative;
  color: white;
  display: block;
  width: fit-content;
  margin: 0 auto ${vw(17)};
  &::after {
    content: '';
    position: absolute;
    bottom: ${vw(-5)};
    left: 0;
    width: 100%;
    height: ${vw(2)};
    background-color: ${({ theme }) => theme.color.grey2};
  }
  @media (min-width: ${media.tablet}) {
    margin: 0 auto ${vwTablet(27)};
    &::after {
      bottom: ${vwTablet(-5)};
      height: ${vwTablet(2)};
    }
  }
`;
