import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import UnderlineText from 'components/Typography/UnderlineText';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';
import theme from 'src/styles/theme';

// For this component to work properly, it must be passed an object to history state when you route to the project page with the name and path such as { from: 'Home', path: '/' }

const Navigation = props => {
  const { history } = props;
  return (
    <Root>
      <div>
        <UnderlineText
          text={`Back to ${history.from}`}
          color={theme.color.red}
          black
          to={history.path}
        />
      </div>
      <div>
        <UnderlineText
          text="See More Work"
          color={theme.color.red}
          black
          href="/#work"
        />
      </div>
    </Root>
  );
};

Navigation.propTypes = {
  history: PropTypes.object,
};

Navigation.defaultProps = {
  history: {
    from: 'Home',
    path: '/',
  },
};

export default Navigation;

const Root = styled.div`
  padding: ${vw(40)} ${vw(16)};
  > div {
    margin-bottom: ${vw(40)};
  }
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(80)} ${vwTablet(30)};
    display: flex;
    justify-content: space-between;
    > div {
      margin-bottom: 0;
      width: ${vwTablet(339)};
      display: flex;
      justify-content: center;
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: ${vwDesktop(80)} ${vwDesktop(298)};
    > div {
      width: ${vwDesktop(407)};
    }
  }
`;
