import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import theme from 'src/styles/theme';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Explore = props => {
  const { color, negativeBottom } = props;
  return (
    <Root color={color} bottom={negativeBottom}>
      <h6>Explore</h6>
      <span />
    </Root>
  );
};

Explore.defaultProps = {
  color: theme.color.red,
  negativeBottom: false,
};

Explore.propTypes = {
  color: PropTypes.string,
  negativeBottom: PropTypes.bool,
};

export default Explore;

const Root = styled.div`
  display: none;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  bottom: ${props => (props.bottom ? vw(-22) : vw(40))};
  left: ${vw(23)};
  span {
    height: ${vw(60)};
    width: ${vw(1)};
    min-width: 1px;
    background-color: ${props => props.color};
    margin-top: ${vw(10)};
  }
  h6 {
    text-transform: uppercase;
    color: ${props => props.color};
    transform: rotate(-90deg) translateY(50%);
    transform-origin: bottom left;
  }
  @media (min-width: ${media.tablet}) {
    display: flex;
    left: ${vwTablet(40)};
    bottom: ${props => (props.bottom ? vwTablet(-62) : vwTablet(40))};
    span {
      height: ${vwTablet(120)};
      width: ${vwTablet(2)};
      margin-top: ${vwTablet(20)};
    }
  }
  @media (min-width: ${media.desktop}) {
    display: flex;
    left: ${vwDesktop(90)};
    bottom: ${props => (props.bottom ? vwDesktop(-52) : vwDesktop(40))};
    span {
      height: ${vwDesktop(120)};
      width: ${vwDesktop(2)};
      margin-top: ${vwDesktop(20)};
    }
  }
`;
