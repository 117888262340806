import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export default class MaskInUp extends Component {
  render() {
    const { animate, duration, delay, children } = this.props;
    return (
      <Root>
        <Mask animate={animate} duration={duration} delay={delay}>
          <Content animate={animate} duration={duration} delay={delay}>
            {children}
          </Content>
        </Mask>
      </Root>
    );
  }
}

MaskInUp.propTypes = {
  animate: PropTypes.bool,
  delay: PropTypes.number,
  duration: PropTypes.number,
};

MaskInUp.defaultProps = {
  animate: true,
  delay: 0,
  duration: 1,
};

const Root = styled.div``;

const Mask = styled.div`
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
  transform: ${props => (props.animate ? 'translateY(0)' : `translateY(100%)`)};
  transition: transform ${props => props.duration}s;
  transition-delay: ${props => (props.animate ? props.delay : 0)}s;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  transform: ${props =>
    props.animate ? 'translateY(0)' : `translateY(-100%)`};
  transition: transform ${props => props.duration}s;
  transition-delay: ${props => (props.animate ? props.delay : 0)}s;
`;
