import interact from 'interactjs';
import PropTypes from 'prop-types';
import React, { createRef, PureComponent } from 'react';
import styled, { keyframes } from 'styled-components';
import ReactPannellum from 'react-pannellum';

// import CustomCursor from 'components/CustomCursor';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

export default class Photosphere extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    defaultAngle: PropTypes.number.isRequired,
    onAngleChange: PropTypes.func,
    onDragEnd: PropTypes.func,
    onDragStart: PropTypes.func,
    onImageLoad: PropTypes.func,
    onPositionChange: PropTypes.func,
    speed: PropTypes.number.isRequired,
    src: PropTypes.string.isRequired,
    style: PropTypes.object,
  };

  static defaultProps = {
    defaultAngle: 0,
    speed: 2,
    style: {},
  };

  nodeRefs = {
    root: createRef(),
  };

  constructor(props) {
    super(props);

    this.state = {
      angle: props.defaultAngle,
      position: 0,
      isDragging: false,
      imageWidth: 0,
      imageHeight: 0,
      imageSrc: '',
      hasDragged: false,
    };
  }

  image = null;

  componentDidMount() {
    this.image = new Image();
    this.image.src = this.props.src;
    this.image.addEventListener('load', this.onImageLoad);

    const rootRef = this.nodeRefs.root.current;

    if (rootRef) {
      interact(rootRef).draggable({
        inertia: true,
        onstart: this.onDragStart,
        onmove: this.onDragMove,
        onend: this.onDragEnd,
      });
    }

    this.componentDidUpdate({}, {});
  }

  componentWillUnmount() {
    this.image.removeEventListener('load', this.onImageLoad);
    this.image = null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { angle, position, isDragging } = this.state;

    if (prevProps.src !== this.props.src) {
      if (this.image) this.image.removeEventListener('load', this.onImageLoad);
      this.image = new Image();
      this.image.src = this.props.src;
      this.image.addEventListener('load', this.onImageLoad);
    }

    if (prevState.angle !== angle) {
      if (this.props.onAngleChange) this.props.onAngleChange(angle);
    }

    if (prevState.position !== position) {
      if (this.props.onPositionChange) this.props.onPositionChange(position);
    }

    if (prevState.isDragging !== isDragging) {
      if (isDragging) {
        if (this.props.onDragStart) this.props.onDragStart();
      } else if (this.props.onDragEnd) this.props.onDragEnd();
    }
  }

  setAngle = angle => {
    this.setState({
      angle,
      position: this.getPositionFromAngle(angle),
    });
  };

  onImageLoad = event => {
    const image = event.currentTarget;
    const { onImageLoad } = this.props;

    this.setState({
      imageWidth: image.width,
      imageHeight: image.height,
      imageSrc: image.src,
    });

    this.resetPosition();

    if (onImageLoad) onImageLoad(image);
  };

  onDragStart = () => {
    this.setState({
      isDragging: true,
      hasDragged: true,
    });
  };

  onDragMove = event => {
    const { speed } = this.props;
    const { position } = this.state;

    const newPosition = position - event.dx * speed;
    const newAngle = this.getAngleFromPosition(newPosition);

    this.setState({
      angle: newAngle,
      position: newPosition,
    });
  };

  onDragEnd = () => {
    this.setState({
      isDragging: false,
    });
  };

  getRect = () => {
    const rootRef = this.nodeRefs.root.current;
    if (!rootRef)
      return { top: 0, right: 0, bottom: 0, left: 0, width: 0, height: 0 };
    return rootRef.getBoundingClientRect();
  };

  getImageSize = () => {
    const { imageWidth, imageHeight } = this.state;
    const rect = this.getRect();
    const aspectRatio = rect.height / imageHeight;
    const w = aspectRatio * imageWidth;

    return {
      width: w,
      height: rect.height,
    };
  };

  getPositionFromAngle = angle => {
    const { width: imageWidth } = this.getImageSize();
    const { width } = this.getRect();

    return (angle / 360) * imageWidth - width / 2;
  };

  getAngleFromPosition = position => {
    const { width: imageWidth } = this.getImageSize();
    const { width } = this.getRect();

    let angle = (((position + width / 2) % imageWidth) / imageWidth) * 360;
    while (angle < 0) angle += 360;

    return angle;
  };

  resetPosition = () => {
    this.setState({
      position: this.getPositionFromAngle(this.state.angle),
    });
  };

  render() {
    const { className, src, style, is360, room } = this.props;
    const { position } = this.state;
    const config = {
      autoLoad: true,
      showZoomCtrl: false,
      showFullscreenCtrl: false,
      mouseZoom: false,
      keyboardZoom: false,
    };
    return (
      // <CustomCursor dragHorizontal={!is360} is360={is360}>
      <StyledRoot className={className} style={style} ref={this.nodeRefs.root}>
        {is360 ? (
          <PannelumContainer>
            <ReactPannellum
              id="firstScene"
              sceneId="firstScene"
              imageSource={src}
              config={config}
              style={{ width: '100%', height: '100%' }}
            />
          </PannelumContainer>
        ) : (
          <StyledImage
            key={this.state.imageSrc}
            style={{
              backgroundImage: `url(${src})`,
              backgroundPositionX: `${-position}px`,
            }}
          />
        )}
        <Container hasDragged={this.state.hasDragged}>
          <h2>Explore the {room ? 'Room' : 'View'}</h2>
          <h3>{room ? '3D Tour' : 'Pano'}</h3>
          <img
            src={
              is360
                ? require('src/assets/images/cursors/cursor_3dtour.svg')
                : require('src/assets/images/cursors/cursor_drag_horizontal.svg')
            }
            alt="drag"
            className="drag-icon"
          />
        </Container>
      </StyledRoot>
      // </CustomCursor>
    );
  }
}

const animateImage = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const StyledImage = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  background-size: cover;
  background-repeat: repeat;
  animation: ${animateImage} 1s forwards;
  height: ${vwTablet(512)};
  @media (min-width: ${media.desktop}) {
    height: 100vh;
  }
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    height: 50vh;
  }
`;

const StyledRoot = styled.div`
  padding: 0;
  position: relative;
  /* align-items: center; */
  box-sizing: border-box;
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
  touch-action: none;
  height: ${vwTablet(512)};
  @media (min-width: ${media.desktop}) {
    height: 100vh;
  }
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    height: 50vh;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: ${props => (props.hasDragged ? 0 : 1)};
  transition: 0.4s ease;
  background-color: rgba(0, 0, 0, 0.3);
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  h2 {
    font-size: ${vw(22)};
    line-height: ${vw(28)};
    letter-spacing: ${vw(0)};
    color: white;
    @media (min-width: ${media.tablet}) {
      font-size: ${vwTablet(30)};
      line-height: ${vwTablet(40)};
      letter-spacing: ${vwTablet(0.3)};
    }
    @media (min-width: ${media.desktop}) {
      font-size: ${vwDesktop(30)};
      line-height: ${vwDesktop(40)};
      letter-spacing: ${vwDesktop(0.3)};
    }
  }
  h3 {
    text-transform: uppercase;
    font-size: ${vw(11)};
    font-weight: 500;
    line-height: ${vw(20)};
    letter-spacing: ${vw(1.5)};
    color: ${({ theme }) => theme.color.primary};
    @media (min-width: ${media.tablet}) {
      font-size: ${vwTablet(14)};
      line-height: ${vwTablet(20)};
      letter-spacing: ${vwTablet(2)};
    }
    @media (min-width: ${media.desktop}) {
      font-size: ${vwDesktop(14)};
      line-height: ${vwDesktop(20)};
      letter-spacing: ${vwDesktop(2)};
    }
  }
  .drag-icon {
    position: absolute;
    bottom: ${vw(19)};
    @media (min-width: ${media.tablet}) {
      bottom: ${vwTablet(49)};
    }
    @media (min-width: ${media.desktop}) {
      display: none;
    }
  }
`;

const PannelumContainer = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  background-size: cover;
  background-repeat: repeat;
  animation: ${animateImage} 1s forwards;
  height: ${vwTablet(512)};
  @media (min-width: ${media.desktop}) {
    height: 100vh;
  }
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    height: 50vh;
  }
`;
