import React from 'react';
import styled from 'styled-components';

import Hero from 'components/Panels/Hero';
import ProjectDetails from 'components/Panels/ProjectDetails';
import A from 'components/Panels/A';
import G from 'components/Panels/G';
import E from 'components/Panels/E';
import C from 'components/Panels/C';

const Holland = () => (
  <Root>
    <Hero
      image={require('src/assets/images/projects/holland/holland_herobanner.jpg')}
      title="The Holland"
    />
    <ProjectDetails
      title="Parkside living in Surrey City Centre."
      description="Developed and built by Townline, The Holland rises 25 storeys above Holland Park. Its contemporary form strikes a dynamic chord on the Surrey skyline, while perfectly complementing the park with beautiful landscaping at street level. The Holland’s collection of one and two-bedroom condominiums and three-bedroom townhomes is designed to offer a home for every lifestyle."
      location="Surrey, BC"
      client="Townline"
      product="250 Unit, 25 Storey Condominiums and Townhomes"
      scope={['Responsive Website', 'Sales Centre Touchscreen App']}
      website="http://theholland.townline.ca/"
    />
    <A
      mediaType="image"
      media={[require('src/assets/images/projects/holland/holland_A.jpg')]}
    />
    <E
      laptop
      backgroundType="image"
      backgroundImage={require('src/assets/images/projects/holland/holland_E_background.jpg')}
      mediaType="scroll"
      media={require('src/assets/images/projects/holland/holland_E_laptop_mockup.jpg')}
    />
    <G
      deviceImage={require('src/assets/images/projects/holland/holland_G_mobile_mockup1.jpg')}
      backgroundImage={require('src/assets/images/projects/holland/holland_G_sideimage1.jpg')}
      rightImage
    />
    <G
      deviceImage={require('src/assets/images/projects/holland/holland_G_mobile_mockup2.jpg')}
      backgroundImage={require('src/assets/images/projects/holland/holland_G_sideimage2.jpg')}
    />

    <C
      largeImage={require('src/assets/images/projects/holland/holland_C.jpg')}
      text="An interactive touchscreen app in sales centres help buyers find the information they’re looking for effortlessly."
      largeRightPanel
    />
  </Root>
);

export default Holland;

const Root = styled.div``;
