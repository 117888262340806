import React from 'react';
import styled from 'styled-components';

import Button from 'components/Button';
import Container from 'components/Container';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Intro = props => {
  const {
    subheader,
    title,
    body,
    featureColor,
    buttonLabel,
    largeText,
  } = props;
  return (
    <Root>
      <StyledContainer
        featureColor={featureColor}
        className="intro-container"
        largeText={largeText}
      >
        <h6 className="subheader">{subheader}</h6>
        <h1>{title}</h1>
        <p>{body}</p>
        <Button color={featureColor} label={buttonLabel} mailLink />
      </StyledContainer>
    </Root>
  );
};

export default Intro;

const Root = styled.div``;

const StyledContainer = styled(Container)`
  padding-top: ${vw(80)};
  padding-bottom: ${vw(80)};
  h1 {
    margin-top: ${vw(10)};
    margin-bottom: ${vw(20)};
  }
  p {
    margin-bottom: ${vw(20)};
  }
  h6.subheader {
    color: ${props => props.featureColor};
  }
  @media (min-width: ${media.tablet}) {
    padding-top: ${vwTablet(160)};
    padding-bottom: ${vwTablet(160)};
    h1 {
      margin-top: ${vwTablet(20)};
      margin-bottom: ${vwTablet(40)};
    }
    p {
      margin-bottom: ${vwTablet(40)};
    }
  }
  @media (min-width: ${media.desktop}) {
    padding-top: ${vwDesktop(160)};
    padding-bottom: ${vwDesktop(160)};
    h1 {
      margin-top: ${vwDesktop(20)};
      margin-bottom: ${vwDesktop(40)};
    }
    p {
      margin-bottom: ${vwDesktop(40)};
      width: ${props => (props.largeText ? vwDesktop(625) : vwDesktop(560))};
    }
  }
`;
