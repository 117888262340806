import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import WaypointTrigger from 'components/Animation/WaypointTrigger';
import FadeUp from 'components/Animation/FadeUp';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Testimonial = ({ content, name, subTitle }) => (
  <WaypointTrigger topOffset="0%" bottomOffset="30%">
    {animate => (
      <FadeUp animate={animate}>
        <Root>
          <p>{content}</p>
          <div>
            <h4>{name}</h4>
            <h6>{subTitle}</h6>
          </div>
        </Root>
      </FadeUp>
    )}
  </WaypointTrigger>
);

Testimonial.propTypes = {
  content: PropTypes.string,
  name: PropTypes.string,
  subTitle: PropTypes.string,
};

Testimonial.defaultProps = {
  subTitle: 'Happy Client',
};

export default Testimonial;

const Root = styled.div`
  padding: ${vw(40)} ${vw(12)};
  display: flex;
  flex-direction: column;
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(60)} ${vwTablet(90)};
  }
  @media (min-width: ${media.desktop}) {
    padding: ${vwDesktop(160)} ${vwDesktop(252)};
    flex-direction: row;
    justify-content: space-between;
  }
  p {
    font-family: 'escrow', serif;
    font-weight: 400;
    font-style: italic;
    font-size: ${vw(16)};
    line-height: ${vw(28)};
    text-align: left;
    color: ${({ theme }) => theme.color.grey1};
    @media (min-width: ${media.tablet}) {
      font-size: ${vwTablet(28)};
      line-height: 1.5;
    }
    @media (min-width: ${media.desktop}) {
      text-align: right;
      width: ${vwDesktop(624)};
      font-size: ${vwDesktop(28)};
      line-height: ${vwDesktop(42)};
    }
  }
  div {
    text-align: left;
    margin-top: ${vwTablet(30)};
    @media (min-width: ${media.desktop}) {
      align-self: flex-end;
      width: 20%;
      text-align: left;
      margin-top: 0;
    }
  }
  h4 {
    margin-bottom: ${vw(4)};
    @media (min-width: ${media.tablet}) {
      margin-bottom: ${vwTablet(10)};
    }
    @media (min-width: ${media.desktop}) {
      margin-bottom: ${vwDesktop(10)};
    }
  }
  h6 {
    color: ${({ theme }) => theme.color.red};
    white-space: nowrap;
  }
`;
