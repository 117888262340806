import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';

import Gallery from './elements/ClickGallery';
import WaypointTrigger from 'components/Animation/WaypointTrigger';
import FadeUp from 'components/Animation/FadeUp';
// import CustomCursor from 'components/CustomCursor';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

class C extends Component {
  constructor(props) {
    super(props);
    this.$largeGallery = React.createRef();
    this.$smallGallery = React.createRef();
  }

  state = {
    hasClicked: false,
  };

  handleNext = () => {
    this.setState({ hasClicked: true });
    if (this.$largeGallery.current) {
      this.$largeGallery.current.slickNext();
    }
    if (this.$smallGallery.current) {
      this.$smallGallery.current.slickNext();
    }
  };

  handleLeaveWaypoint = () => this.setState({ hasClicked: false });

  render() {
    const {
      largeRightPanel,
      largeImage,
      smallImage,
      text,
      mediaType,
    } = this.props;
    return (
      <Waypoint onLeave={this.handleLeaveWaypoint}>
        <div>
          <WaypointTrigger>
            {animate => (
              <FadeUp animate={animate}>
                {/* <Cursor
                  as={mediaType === 'gallery' ? CustomCursor : 'div'}
                  gallery
                > */}
                <Root onClick={this.handleNext} text={text}>
                  <LargePanel
                    image={largeImage}
                    isRight={largeRightPanel}
                    text={text}
                  >
                    {mediaType === 'gallery' && (
                      <Gallery
                        media={largeImage}
                        number={1}
                        galleryRef={this.$largeGallery}
                      />
                    )}
                  </LargePanel>
                  <SmallPanel
                    image={smallImage}
                    isLeft={largeRightPanel}
                    text={text}
                    className="white-background"
                  >
                    {text && <p>{text}</p>}
                    {mediaType === 'gallery' && !text && (
                      <Gallery
                        media={smallImage}
                        number={2}
                        galleryRef={this.$smallGallery}
                      />
                    )}
                  </SmallPanel>
                  {mediaType === 'gallery' && (
                    <Icon
                      src={require('src/assets/images/cursors/cursor_gallery.svg')}
                      active={true}
                      text={text}
                      largeRightPanel={largeRightPanel}
                    />
                  )}
                </Root>
                {/* </Cursor> */}
              </FadeUp>
            )}
          </WaypointTrigger>
        </div>
      </Waypoint>
    );
  }
}

C.propTypes = {
  largeRightPanel: PropTypes.bool,
  largeImage: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  smallImage: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  text: PropTypes.string,
  mediaType: PropTypes.string,
};

C.defaultProps = {
  largeRightPanel: false,
  mediaType: 'image',
};

export default C;

const Root = styled.div`
  display: flex;
  flex-direction: ${props => (props.text ? 'column' : 'row')};
  width: 100%;
  height: ${props => (props.text ? 'auto' : vwTablet(512))};
  @media (min-width: ${media.tablet}) {
    height: ${vwTablet(512)};
    flex-direction: row;
  }
  @media (min-width: ${media.desktop}) {
    height: 100vh;
  }
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    height: 50vh;
  }
`;

const LargePanel = styled.div`
  order: ${props => (props.isRight ? 2 : 1)};
  width: ${props => (props.text ? '100%' : '65.2%')};
  height: ${props => (props.text ? vw(245) : 'auto')};
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  @media (min-width: ${media.tablet}) {
    width: 65.2%;
    height: auto;
  }
`;

// background-image is throwing an error but its working on the site so leaving it.

const SmallPanel = styled.div`
  order: ${props => (props.isLeft ? 1 : 2)};
  width: ${props => (props.text ? '100%' : '34.8%')};
  height: ${props => (props.text ? vw(245) : 'auto')};
  background-image: url(${props =>
    props.image
      ? props.image
      : require('src/assets/images/white-background-large.jpg')});
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: ${media.tablet}) {
    width: 34.8%;
    height: auto;
  }
  p {
    font-size: ${vw(14)};
    font-weight: 300;
    line-height: ${vw(22)};
    letter-spacing: ${vw(0.5)};
    max-width: 50%;
    @media (min-width: ${media.tablet}) {
      font-size: ${vwDesktop(18)};
      line-height: ${vwDesktop(32)};
      letter-spacing: ${vwDesktop(1)};
      max-width: ${vwDesktop(305)};
    }
  }
`;

const Icon = styled.img`
  position: absolute;
  transform: translate(-50%, -50%);
  top: ${props => {
    if (props.text && props.largeRightPanel) {
      return '95%';
    } else if (props.text) {
      return '45%';
    } else {
      return '50%';
    }
  }};
  /* top: ${props => (props.text ? '45%' : '50%')}; */
  left: ${props => {
    if (props.text) {
      return '50%';
    } else if (props.largeRightPanel) {
      return '34.5%';
    } else {
      return '65%';
    }
  }};
  opacity: ${props => (props.active ? 1 : 0)};
  transition: 0.5s ease;
  width: ${vw(30)};
  height: ${vw(30)};
  @media (min-width: ${media.tablet}) {
    transform: translate(0, -50%);
    width: ${vwTablet(40)};
    height: ${vwTablet(40)};
    top: ${props => (props.text ? '90%' : '50%')};
    left: ${props => {
      if (props.text && props.largeRightPanel) {
        return '68.75%';
      } else if (props.text) {
        return '31.25%';
      } else if (props.largeRightPanel) {
        return '32%';
      } else {
        return '62.5%';
      }
    }};
  }
  @media (min-width: ${media.desktop}) {
    display: none;
  }
`;

// const Cursor = styled.div``;
