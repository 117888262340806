import React from 'react';
import styled from 'styled-components';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const FeatureCard = props => {
  const { title, thumbnail, text } = props.feature;
  const { featureColor, handleModal, index } = props;
  return (
    <Root featureColor={featureColor} onClick={() => handleModal(index)}>
      <ImageWrapper>
        <img src={thumbnail} alt={title} />
      </ImageWrapper>
      <div>
        <h2 className="mobile-only">{title}</h2>
        <h3 className="tablet-only">{title}</h3>
        <h2 className="desktop-only">{title}</h2>
        <p>{text}</p>
      </div>
    </Root>
  );
};

export default FeatureCard;

const Root = styled.div`
  width: 100%;
  position: relative;
  &:not(:first-of-type) {
    margin-top: ${vw(40)};
  }

  img {
    height: 100%;
    width: ${vw(160)};
    object-fit: cover;
    object-position: center;
  }
  h2 {
    z-index: 2;
    position: relative;
    &::after {
      content: '';
      width: ${vw(40)};
      height: ${vw(2)};
      background-color: ${props => props.featureColor};
      position: absolute;
      left: 0;
      bottom: ${vw(-10)};
    }
    br {
      display: none;
    }
  }

  p {
    margin-top: ${vw(22)};
  }

  @media (min-width: ${media.tablet}) {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: ${vwTablet(80)};
    &:not(:first-of-type) {
      margin-top: unset;
    }
    &:last-of-type {
      margin-bottom: 0;
    }

    img {
      height: 100%;
      width: ${vwTablet(260)};
    }
    h3 {
      width: 100%;
      z-index: 2;
      position: relative;
      &::after {
        content: '';
        background-color: ${props => props.featureColor};
        position: absolute;
        left: 0;
        width: ${vwTablet(40)};
        height: ${vwTablet(2)};
        bottom: ${vwTablet(-20)};
      }
      br {
        display: block;
      }
    }
    p {
      margin-top: ${vwTablet(42)};
    }
  }
  @media (min-width: ${media.desktop}) {
    cursor: pointer;
    display: block;
    margin-bottom: 0;
    h2 {
      width: ${vwDesktop(406)};
      z-index: 2;
      &::after {
        content: '';
        background-color: ${props => props.featureColor};
        position: absolute;
        left: 0;
        width: ${vwDesktop(40)};
        height: ${vwDesktop(2)};
        bottom: ${vwDesktop(-20)};
        transition: width 0.5s ease;
      }
      br {
        display: block;
      }
    }

    &:hover img {
      transform: scale(1.05);
    }

    &:hover h2::after {
      width: ${vwDesktop(60)};
    }
    p {
      margin-top: ${vwDesktop(42)};
      width: ${vwDesktop(406)};
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  height: ${vw(160)};
  margin-bottom: ${vw(10)};
  @media (min-width: ${media.tablet}) {
    height: ${vwTablet(260)};
    margin-right: ${vwTablet(40)};
    margin-bottom: 0;
  }
  @media (min-width: ${media.desktop}) {
    overflow: hidden;
    height: ${vwDesktop(200)};
    width: ${vwDesktop(200)};
    margin-right: 0;
    margin-bottom: ${vwDesktop(20)};
    img {
      transition: 0.8s ease;
    }
  }
`;
