import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link as ScrollLink } from 'react-scroll';

import data from 'src/data/header';

import { vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const DesktopMenu = props => {
  const { menu, hasScrolled } = props;
  return (
    <Root menu={menu} hasScrolled={hasScrolled}>
      {data.map((item, index) => (
        <ScrollLink key={index} to={item.to} smooth>
          <p className="body2 bold">{item.title}</p>
        </ScrollLink>
      ))}
    </Root>
  );
};

DesktopMenu.propTypes = {
  menu: PropTypes.bool,
  hasScrolled: PropTypes.bool,
};

export default DesktopMenu;

const Root = styled.div`
  display: none;
  @media (min-width: ${media.desktop}) {
    display: ${props => (props.menu ? 'flex' : 'none')};
    align-items: center;
    height: ${vwDesktop(40)};
    margin-right: ${vwDesktop(143)};
    opacity: ${props => (props.hasScrolled ? 0 : 1)};
    pointer-events: ${props => (props.hasScrolled ? 'none' : 'all')};
    transition: 0.3s ease;
    p {
      color: white;
      margin-right: ${vwDesktop(50)};
      cursor: pointer;
    }
  }
`;
