import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Container from 'components/Container';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Results = props => {
  const { text, results } = props;
  return (
    <Root>
      <Container>
        <h2>Results</h2>
        <div>
          <p className="body1">{text}</p>
          <Data>
            {results.map((item, index) => (
              <Item key={index}>
                <h6>{item.title}</h6>
                <div>
                  <h2>{item.point}</h2>
                  {item.subPoint && <h4>{item.subPoint}</h4>}
                </div>
              </Item>
            ))}
          </Data>
        </div>
      </Container>
    </Root>
  );
};

Results.propTypes = {
  text: PropTypes.string,
  results: PropTypes.array,
};

export default Results;

const Root = styled.section`
  background-color: ${({ theme }) => theme.color.offWhite};
  padding: ${vw(40)} 0;
  p {
    margin: ${vw(20)} 0 ${vw(40)};
  }
  h6 {
    color: ${({ theme }) => theme.color.red};
    margin-bottom: ${vw(4)};
  }
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(80)} 0;
    p {
      margin: ${vwTablet(40)} 0;
      font-size: ${vwTablet(16)};
      line-height: 1.5;
    }
    h6 {
      margin-bottom: ${vwTablet(10)};
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: ${vwDesktop(80)} ${vwDesktop(110)};
    > div {
      display: flex;
      justify-content: space-between;
      > div {
        width: ${vwDesktop(625)};
      }
    }
    p {
      margin: 0 0 ${vwDesktop(80)};
      font-size: ${vwDesktop(16)};
    }
    h6 {
      margin-bottom: ${vwDesktop(10)};
    }
  }
`;

const Data = styled.div`
  @media (min-width: ${media.tablet}) {
    padding-left: ${vwTablet(122)};
    display: flex;
    flex-wrap: wrap;
  }
  @media (min-width: ${media.desktop}) {
    padding-left: 0;
    width: ${vwDesktop(508)};
    justify-content: space-between;
  }
`;

const Item = styled.div`
  margin-bottom: ${vw(40)};
  &:last-of-type {
    margin-bottom: 0;
  }
  > div {
    display: flex;
    align-items: flex-end;
  }
  h4 {
    margin-left: ${vw(4)};
  }
  @media (min-width: ${media.tablet}) {
    margin-bottom: ${vwTablet(40)};
    min-width: ${vwTablet(170)};
    h4 {
      margin-left: ${vwTablet(8)};
      margin-bottom: ${vwTablet(8)};
    }
    &:nth-of-type(1) {
      margin-right: ${vwTablet(90)};
    }
  }
  @media (min-width: ${media.desktop}) {
    margin-bottom: ${vwDesktop(40)};
    min-width: ${vwDesktop(170)};
    h4 {
      margin-left: ${vwDesktop(8)};
      margin-bottom: ${vwDesktop(8)};
    }
    &:nth-of-type(1) {
      margin-right: 0;
    }
  }
`;
