import React from 'react';

export default [
  {
    title: 'Search & Compare Floor Plans',
    thumbnail: require('src/assets/images/touchscreen/thumb-floorplan-search.jpg'),
    image: require('src/assets/images/touchscreen/floor-plan-search.jpg'),
    text:
      'Make it simple to filter, compare, and share floorplans, based on criteria and availability of the properties.',
    content: (
      <p>
        When finding a new home, the first step customers take is selecting the
        right floor plan. Make it simple to filter, compare and share what
        they’re looking for based on their needs.
      </p>
    ),
  },
  {
    title: 'Real Time Availability & Pricing',
    thumbnail: require('src/assets/images/touchscreen/real-time-availability.jpg'),
    image: require('src/assets/images/touchscreen/real-time-availability.jpg'),
    text:
      'See available units and floorplans, or filter various list-view options based on real time inventory.',
    content: (
      <p>
        Keep your inventory updated and visible in real time with our fully
        integrated API software. Let customers see available units and floor
        plans, or filter various list-view options. Save time and increase
        convenience for both the customer and your management team.
      </p>
    ),
  },
  {
    title: (
      <>
        True Unit &amp;
        <br /> 360° Views
      </>
    ),
    thumbnail: require('src/assets/images/touchscreen/true-view.jpg'),
    image: require('src/assets/images/touchscreen/true-view.jpg'),
    text:
      'Showcase the fully immersive true 360° views from the selected floorplan or building floors.',
    content: (
      <p>
        Let your customers immerse and explore in your properties views and
        vantage points. Set the true view point from particular units and floor
        plans for an optimized user experience.
      </p>
    ),
  },
  {
    title: 'Custom Neighborhood Maps',
    thumbnail: require('src/assets/images/touchscreen/custom-neighbourhood-map.jpg'),
    image: require('src/assets/images/touchscreen/custom-neighbourhood-map.jpg'),
    text:
      'Explore the best that the neighbourhood has to offer through a custom-designed and interactive map experience.',
    content: (
      <p>
        Interactive and custom designed maps that allow you to highlight all of
        the best neighbourhood amenities. Allow your customers to easily see and
        explore all of the key categories, such as restaurants, transit, parks,
        and other benefits the location has to offer.
      </p>
    ),
  },
  {
    title: 'Image & Video Gallery',
    thumbnail: require('src/assets/images/touchscreen/thumb-gallery.jpg'),
    image: require('src/assets/images/touchscreen/gallery.jpg'),
    text:
      'Enhanced visuals matter in attracting customer engagement and selling homes faster.',
    content: (
      <p>
        Showcase all of your properties photographs, renderings, and video
        assets in a single, multi-faceted gallery based feature. Visual impacts
        matter in attracting customer engagement and selling homes faster.
      </p>
    ),
  },
  {
    title: (
      <>
        Immersive
        <br /> Virtual Tours
      </>
    ),
    thumbnail: require('src/assets/images/touchscreen/virtual-tour.jpg'),
    image: require('src/assets/images/touchscreen/virtual-tour.jpg'),
    text:
      'Reach more potential buyers with an extended reach through virtual tours and property flythroughs.',
    content: (
      <p>
        Set yourself apart and reach more buyers with 360°, 3D home tours that
        let customers fall in love with the property. Provide a walkthrough
        anytime, anywhere.
      </p>
    ),
  },
];
