import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

const settings = {
  dots: false,
  arrows: false,
  infinite: true,
  slidesToShow: 1,
  draggable: false,
  fade: true,
};

const Gallery = ({ media, number, galleryRef }) => (
  <Root {...settings} ref={galleryRef} {...{ speed: number === 1 ? 700 : 400 }}>
    {media.map((item, index) => (
      <Image image={item} key={index} />
    ))}
  </Root>
);

Gallery.propTypes = {
  media: PropTypes.array,
};

export default Gallery;

const Root = styled(Slider)`
  width: 100%;
  height: 100%;
  .slick-list,
  .slick-track,
  div {
    height: 100%;
  }
  .slick-dots {
    bottom: 0;
  }
`;

const Image = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
`;
