import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import WaypointTrigger from 'components/Animation/WaypointTrigger';
import FadeUp from 'components/Animation/FadeUp';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const D = ({ largeImage, smallImage, text, largeLeft }) => (
  <WaypointTrigger>
    {animate => (
      <FadeUp animate={animate}>
        <Root>
          <Panel isRight={largeLeft}>
            <SmallImage image={smallImage} isBottom={largeLeft} />
            <p>{text}</p>
          </Panel>
          <LargeImage image={largeImage} isLeft={largeLeft} />
        </Root>
      </FadeUp>
    )}
  </WaypointTrigger>
);

D.propTypes = {
  largeImage: PropTypes.string,
  smallImage: PropTypes.string,
  text: PropTypes.string,
  largeLeft: PropTypes.bool,
};

D.defaultProps = {
  largeLeft: false,
};

export default D;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${media.tablet}) {
    flex-direction: row;
    height: ${vwDesktop(1200)};
  }
`;

const LargeImage = styled.div`
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: ${vw(533)};
  order: ${props => (props.isLeft ? 1 : 2)};
  @media (min-width: ${media.tablet}) {
    width: 50%;
    height: 100%;
  }
`;

const Panel = styled.div`
  width: 100%;
  height: 100%;
  order: ${props => (props.isRight ? 2 : 1)};
  display: flex;
  flex-direction: column;
  @media (min-width: ${media.tablet}) {
    width: 50%;
  }
  p {
    height: ${vw(266)};
    width: ${vw(160)};
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    font-size: ${vw(14)};
    line-height: ${vw(22)};
    letter-spacing: ${vw(0.5)};
    margin: 0 auto;
    order: ${props => (props.isRight ? 1 : 2)};
    @media (min-width: ${media.tablet}) {
      height: 50%;
      width: ${vwTablet(224)};
      font-size: ${vwTablet(15)};
      line-height: ${vwTablet(24)};
      letter-spacing: ${vwTablet(0.5)};
      order: 2;
    }
    @media (min-width: ${media.desktop}) {
      font-size: ${vwDesktop(18)};
      line-height: ${vwDesktop(32)};
      letter-spacing: ${vwDesktop(1)};
      width: ${vwDesktop(300)};
    }
  }
`;

const SmallImage = styled.div`
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  height: ${vw(266)};
  order: ${props => (props.isBottom ? 2 : 1)};
  @media (min-width: ${media.tablet}) {
    height: 50%;
    order: 1;
  }
`;
