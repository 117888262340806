export default [
  {
    title: 'Powerful Data Collection',
    body: `Having your end-to-end customer journey data and analytics all in one location enables you to gather valuable insights through each marketing stage of your campaign. Our in-house powered AI integration tools tap into a wide reach of data streams to focus on target customer profiles and better predict purchase behavior.`,
    thumbnail: require('src/assets/images/dataIntelligence/people-on-crosswalk.jpg'),
    image: require('src/assets/images/webSolutions/new-development-mock.jpg'),
    content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie. Mauris malesuada nisi sit amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie. Mauris malesuada nisi sit amet.`,
  },
  {
    title: 'Data Education & Onboarding',
    body: `Creating a high-conversion campaign requires optimizing the customer journey experience. Understanding the story in your data allows you to strategically improve your lead generation capabilities and overall conversion rates.`,
    thumbnail: require('src/assets/images/dataIntelligence/aerial-city-view.jpg'),
    image: require('src/assets/images/home/presentation-centre-touchscreens.jpg'),
    content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie. Mauris malesuada nisi sit amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie. Mauris malesuada nisi sit amet.`,
  },
  {
    title: 'Strategy & Reporting',
    body: `A disciplined data strategy and campaign tracking can lead to the most cost-efficient ways in getting your content to your ideal customer targets. Social targeting, geo-targeting, and web retargeting strategies are only as good as the data insights that feed these methods. Regular reporting and optimizations are a must for any successful long-term campaign.`,
    thumbnail: require('src/assets/images/dataIntelligence/sticky-notes.jpg'),
    image: require('src/assets/images/webSolutions/rental.jpg'),
    content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie. Mauris malesuada nisi sit amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie. Mauris malesuada nisi sit amet.`,
  },
];
